/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class ControlViolationsPerSeverityViolatedControlsInner {
  /**
  * The id of the violated control
  */
  'id': string;
  /**
  * The name of the violated control
  */
  'name': string;
  /**
  * The number of resources violated this control
  */
  'affectedResources': number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "affectedResources",
      "baseName": "affectedResources",
      "type": "number",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ControlViolationsPerSeverityViolatedControlsInner.attributeTypeMap;
  }

  public constructor() {
  }
}

