/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SchemaRecord } from '../models/SchemaRecord';

export class DatabaseRecord {
  /**
  * name of the db
  */
  'name': string;
  /**
  * schemas under this db
  */
  'schemas'?: Array<SchemaRecord>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "schemas",
      "baseName": "schemas",
      "type": "Array<SchemaRecord>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return DatabaseRecord.attributeTypeMap;
  }

  public constructor() {
  }
}

