/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class UserIdFilters {
  /**
  * Matches if this string equals `initiatorId`.
  */
  'equals'?: string;
  /**
  * Matches if any string in this list equals `initiatorId`.
  */
  '_in'?: Array<string>;
  /**
  * Matches if no string in this list equals `initiatorId`.
  */
  'notIn'?: Array<string>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "equals",
      "baseName": "Equals",
      "type": "string",
      "format": ""
    },
    {
      "name": "_in",
      "baseName": "In",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "notIn",
      "baseName": "NotIn",
      "type": "Array<string>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return UserIdFilters.attributeTypeMap;
  }

  public constructor() {
  }
}

