/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class UpdateAccountInput {
  /**
  * The name of the account
  */
  'name'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return UpdateAccountInput.attributeTypeMap;
  }

  public constructor() {
  }
}

