/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { JobType } from '../models/JobType';
import { JobsFilterConditions } from '../models/JobsFilterConditions';
import { SortJobBy } from '../models/SortJobBy';

export class ListJobsRequest {
  'filters'?: JobsFilterConditions;
  'jobType': JobType;
  'sorts'?: Array<SortJobBy>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "filters",
      "baseName": "filters",
      "type": "JobsFilterConditions",
      "format": ""
    },
    {
      "name": "jobType",
      "baseName": "jobType",
      "type": "JobType",
      "format": ""
    },
    {
      "name": "sorts",
      "baseName": "sorts",
      "type": "Array<SortJobBy>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListJobsRequest.attributeTypeMap;
  }

  public constructor() {
  }
}



