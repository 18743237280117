/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { UnifiedDBServerRecord } from '../models/UnifiedDBServerRecord';
import { UnifiedDatabaseRecord } from '../models/UnifiedDatabaseRecord';
import { UnifiedSchemaRecord } from '../models/UnifiedSchemaRecord';
import { UnifiedTableRecord } from '../models/UnifiedTableRecord';

export class UnifiedDBRecord {
  /**
  * type of the record
  */
  'type': string;
  'table'?: UnifiedTableRecord;
  'schema'?: UnifiedSchemaRecord;
  'db'?: UnifiedDatabaseRecord;
  'server'?: UnifiedDBServerRecord;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "type",
      "baseName": "type",
      "type": "string",
      "format": ""
    },
    {
      "name": "table",
      "baseName": "table",
      "type": "UnifiedTableRecord",
      "format": ""
    },
    {
      "name": "schema",
      "baseName": "schema",
      "type": "UnifiedSchemaRecord",
      "format": ""
    },
    {
      "name": "db",
      "baseName": "db",
      "type": "UnifiedDatabaseRecord",
      "format": ""
    },
    {
      "name": "server",
      "baseName": "server",
      "type": "UnifiedDBServerRecord",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return UnifiedDBRecord.attributeTypeMap;
  }

  public constructor() {
  }
}

