import { ResponseContext, RequestContext, HttpInfo } from '../http/http';
import type { HttpFile } from '../http/http';
import { retry } from '../util';
import type { Configuration} from '../configuration'

import { Account } from '../models/Account';
import { AccountIdFilters } from '../models/AccountIdFilters';
import { AccountUpdateInput } from '../models/AccountUpdateInput';
import { AccountsResponse } from '../models/AccountsResponse';
import { ActionFilters } from '../models/ActionFilters';
import { App } from '../models/App';
import { AppFilters } from '../models/AppFilters';
import { ApplicationOverride } from '../models/ApplicationOverride';
import { AppsDetails } from '../models/AppsDetails';
import { AtlasProject } from '../models/AtlasProject';
import { AtlasProjects } from '../models/AtlasProjects';
import { AuditLog } from '../models/AuditLog';
import { AuditLogAction } from '../models/AuditLogAction';
import { AuditLogEntitiesInner } from '../models/AuditLogEntitiesInner';
import { AuditLogFilterConditions } from '../models/AuditLogFilterConditions';
import { AuthResponse } from '../models/AuthResponse';
import { AzureConsentUrlResponse } from '../models/AzureConsentUrlResponse';
import { AzureMarketplaceWebhookInput } from '../models/AzureMarketplaceWebhookInput';
import { AzureMarketplaceWebhookInputSubscription } from '../models/AzureMarketplaceWebhookInputSubscription';
import { AzureMarketplaceWebhookInputSubscriptionBeneficiary } from '../models/AzureMarketplaceWebhookInputSubscriptionBeneficiary';
import { AzureMarketplaceWebhookInputSubscriptionPurchaser } from '../models/AzureMarketplaceWebhookInputSubscriptionPurchaser';
import { AzureMarketplaceWebhookInputSubscriptionTerm } from '../models/AzureMarketplaceWebhookInputSubscriptionTerm';
import { AzureOnboardingRequest } from '../models/AzureOnboardingRequest';
import { AzureOnboardingStatus } from '../models/AzureOnboardingStatus';
import { AzureOnboardingStatusResponse } from '../models/AzureOnboardingStatusResponse';
import { AzureSubscription } from '../models/AzureSubscription';
import { AzureSubscriptions } from '../models/AzureSubscriptions';
import { BackedUpByResourceType } from '../models/BackedUpByResourceType';
import { BackedUpByResourceTypeDataInner } from '../models/BackedUpByResourceTypeDataInner';
import { BackedUpStorageDateHistogram } from '../models/BackedUpStorageDateHistogram';
import { BackupControlViolations } from '../models/BackupControlViolations';
import { BackupFromDatesInput } from '../models/BackupFromDatesInput';
import { BackupFromDatesResponse } from '../models/BackupFromDatesResponse';
import { BackupFromSnapshotsInput } from '../models/BackupFromSnapshotsInput';
import { BackupJobResponse } from '../models/BackupJobResponse';
import { BackupPoliciesFilters } from '../models/BackupPoliciesFilters';
import { BackupPolicy } from '../models/BackupPolicy';
import { BackupRegionsFilters } from '../models/BackupRegionsFilters';
import { BackupSchedule } from '../models/BackupSchedule';
import { BackupStatus } from '../models/BackupStatus';
import { BackupStatusFilters } from '../models/BackupStatusFilters';
import { BackupVault } from '../models/BackupVault';
import { BatchApplicationOverrides } from '../models/BatchApplicationOverrides';
import { BatchOperationResponse } from '../models/BatchOperationResponse';
import { BooleanFilters } from '../models/BooleanFilters';
import { CancelExclusionFromBackupResponse } from '../models/CancelExclusionFromBackupResponse';
import { Classifications } from '../models/Classifications';
import { ColumnMetadata } from '../models/ColumnMetadata';
import { Condition } from '../models/Condition';
import { Control } from '../models/Control';
import { ControlRules } from '../models/ControlRules';
import { ControlViolation } from '../models/ControlViolation';
import { ControlViolations } from '../models/ControlViolations';
import { ControlViolationsPerSeverity } from '../models/ControlViolationsPerSeverity';
import { ControlViolationsPerSeverityViolatedControlsInner } from '../models/ControlViolationsPerSeverityViolatedControlsInner';
import { ControlsFilters } from '../models/ControlsFilters';
import { CreateAccountInput } from '../models/CreateAccountInput';
import { CreateBackupJobInput } from '../models/CreateBackupJobInput';
import { CreateBackupVaultInput } from '../models/CreateBackupVaultInput';
import { CreateIntegrationRequest } from '../models/CreateIntegrationRequest';
import { CreateIntegrationResponse } from '../models/CreateIntegrationResponse';
import { CreatePolicyInput } from '../models/CreatePolicyInput';
import { CreatePolicyScheduleInput } from '../models/CreatePolicyScheduleInput';
import { CreateProjectInput } from '../models/CreateProjectInput';
import { CreateRoleInput } from '../models/CreateRoleInput';
import { CreateSamlIdentityProviderConfigInput } from '../models/CreateSamlIdentityProviderConfigInput';
import { CreateScanJobInput } from '../models/CreateScanJobInput';
import { CreateUserInput } from '../models/CreateUserInput';
import { DBResourceMetadata } from '../models/DBResourceMetadata';
import { DBServerRecord } from '../models/DBServerRecord';
import { DBSnapshotMetadata } from '../models/DBSnapshotMetadata';
import { DailyStorageSummaries } from '../models/DailyStorageSummaries';
import { DailyStorageSummary } from '../models/DailyStorageSummary';
import { DataAccessRule } from '../models/DataAccessRule';
import { DataClass } from '../models/DataClass';
import { DataClassesDetails } from '../models/DataClassesDetails';
import { DataClassesFilters } from '../models/DataClassesFilters';
import { DataClassificationEntity } from '../models/DataClassificationEntity';
import { DatabaseProperties } from '../models/DatabaseProperties';
import { DatabaseRecord } from '../models/DatabaseRecord';
import { DiscoveryInvokeParams } from '../models/DiscoveryInvokeParams';
import { DiscoveryStatusResponse } from '../models/DiscoveryStatusResponse';
import { DriftProtectionPolicy } from '../models/DriftProtectionPolicy';
import { DriftProtectionSummary } from '../models/DriftProtectionSummary';
import { DriftProtectionTimePoint } from '../models/DriftProtectionTimePoint';
import { EncryptionKey } from '../models/EncryptionKey';
import { EncryptionKeys } from '../models/EncryptionKeys';
import { Environment } from '../models/Environment';
import { EnvironmentDetails } from '../models/EnvironmentDetails';
import { EnvironmentFilters } from '../models/EnvironmentFilters';
import { EonAccount } from '../models/EonAccount';
import { ExcludeFromBackupResponse } from '../models/ExcludeFromBackupResponse';
import { FileExplorerResponse } from '../models/FileExplorerResponse';
import { FileExplorerResult } from '../models/FileExplorerResult';
import { FilePath } from '../models/FilePath';
import { FileSearchRecord } from '../models/FileSearchRecord';
import { FileSnapshot } from '../models/FileSnapshot';
import { GenericSnapshotData } from '../models/GenericSnapshotData';
import { GenericSnapshotsPerVolume } from '../models/GenericSnapshotsPerVolume';
import { GetJobResponse } from '../models/GetJobResponse';
import { GetResourceResponse } from '../models/GetResourceResponse';
import { GetSnapshotResponse } from '../models/GetSnapshotResponse';
import { GroupToRoleMapping } from '../models/GroupToRoleMapping';
import { IDs } from '../models/IDs';
import { IdFilters } from '../models/IdFilters';
import { Identifier } from '../models/Identifier';
import { InitAuthInput } from '../models/InitAuthInput';
import { Integration } from '../models/Integration';
import { IntegrationCredentials } from '../models/IntegrationCredentials';
import { InventoryFilterConditions } from '../models/InventoryFilterConditions';
import { InventoryResource } from '../models/InventoryResource';
import { InventoryResourceMetadata } from '../models/InventoryResourceMetadata';
import { InventoryVault } from '../models/InventoryVault';
import { InventoryVolume } from '../models/InventoryVolume';
import { Job } from '../models/Job';
import { JobType } from '../models/JobType';
import { JobsFilterConditions } from '../models/JobsFilterConditions';
import { JobsFilterConditionsUserId } from '../models/JobsFilterConditionsUserId';
import { ListAccountsResponse } from '../models/ListAccountsResponse';
import { ListApplicationOverrideResponse } from '../models/ListApplicationOverrideResponse';
import { ListAuditLogRequest } from '../models/ListAuditLogRequest';
import { ListAuditLogResponse } from '../models/ListAuditLogResponse';
import { ListAuditLogsResponse } from '../models/ListAuditLogsResponse';
import { ListBackupPolicyResponse } from '../models/ListBackupPolicyResponse';
import { ListBackupVaultResponse } from '../models/ListBackupVaultResponse';
import { ListControlViolationsResponse } from '../models/ListControlViolationsResponse';
import { ListControlsResponse } from '../models/ListControlsResponse';
import { ListDataClassificationEntitiesResponse } from '../models/ListDataClassificationEntitiesResponse';
import { ListDatabaseSnapshotsResponse } from '../models/ListDatabaseSnapshotsResponse';
import { ListGenericSnapshotsResponse } from '../models/ListGenericSnapshotsResponse';
import { ListInstanceProfilesResponse } from '../models/ListInstanceProfilesResponse';
import { ListInstanceTypesResponse } from '../models/ListInstanceTypesResponse';
import { ListIntegrationsResponse } from '../models/ListIntegrationsResponse';
import { ListInventoryAppsResponse } from '../models/ListInventoryAppsResponse';
import { ListInventoryBackupRegionsResponse } from '../models/ListInventoryBackupRegionsResponse';
import { ListInventoryEnvironmentsResponse } from '../models/ListInventoryEnvironmentsResponse';
import { ListInventoryItemSnapshotsResponse } from '../models/ListInventoryItemSnapshotsResponse';
import { ListInventoryNetworksResponse } from '../models/ListInventoryNetworksResponse';
import { ListInventoryRequest } from '../models/ListInventoryRequest';
import { ListInventoryResourceTypesResponse } from '../models/ListInventoryResourceTypesResponse';
import { ListInventoryResourcesResponse } from '../models/ListInventoryResourcesResponse';
import { ListInventorySnapshotsResponse } from '../models/ListInventorySnapshotsResponse';
import { ListInventorySourceRegionsResponse } from '../models/ListInventorySourceRegionsResponse';
import { ListInventorySubnetsResponse } from '../models/ListInventorySubnetsResponse';
import { ListJobs200Response } from '../models/ListJobs200Response';
import { ListJobsRequest } from '../models/ListJobsRequest';
import { ListJobsResponse } from '../models/ListJobsResponse';
import { ListNotificationPoliciesResponse } from '../models/ListNotificationPoliciesResponse';
import { ListNotificationsRequest } from '../models/ListNotificationsRequest';
import { ListNotificationsResponse } from '../models/ListNotificationsResponse';
import { ListProjectsResponse } from '../models/ListProjectsResponse';
import { ListResourcesResponse } from '../models/ListResourcesResponse';
import { ListRestoreAccountsResponse } from '../models/ListRestoreAccountsResponse';
import { ListSubnetsResponse } from '../models/ListSubnetsResponse';
import { ListUsersResponse } from '../models/ListUsersResponse';
import { MaxRetentionRule } from '../models/MaxRetentionRule';
import { Message } from '../models/Message';
import { MinRetentionRule } from '../models/MinRetentionRule';
import { ModelError } from '../models/ModelError';
import { Notification } from '../models/Notification';
import { NotificationArguments } from '../models/NotificationArguments';
import { NotificationArgumentsBackupJobFailed } from '../models/NotificationArgumentsBackupJobFailed';
import { NotificationArgumentsRestoreJobSucceeded } from '../models/NotificationArgumentsRestoreJobSucceeded';
import { NotificationFilterConditions } from '../models/NotificationFilterConditions';
import { NotificationPolicy } from '../models/NotificationPolicy';
import { NotificationPolicyConfig } from '../models/NotificationPolicyConfig';
import { NotificationPolicyConfigEmail } from '../models/NotificationPolicyConfigEmail';
import { NotificationPolicyConfigSlack } from '../models/NotificationPolicyConfigSlack';
import { NotificationPolicyConfigSns } from '../models/NotificationPolicyConfigSns';
import { NotificationPolicyIdFilters } from '../models/NotificationPolicyIdFilters';
import { NotificationStatus } from '../models/NotificationStatus';
import { NotificationStatusFilters } from '../models/NotificationStatusFilters';
import { NotificationType } from '../models/NotificationType';
import { NotificationTypeFilters } from '../models/NotificationTypeFilters';
import { NumberOfCopiesRule } from '../models/NumberOfCopiesRule';
import { OperationIdFilters } from '../models/OperationIdFilters';
import { OverrideDataClassificationsRequest } from '../models/OverrideDataClassificationsRequest';
import { OverrideDataClassificationsResponse } from '../models/OverrideDataClassificationsResponse';
import { OverrideEnvironmentRequest } from '../models/OverrideEnvironmentRequest';
import { OverrideEnvironmentResponse } from '../models/OverrideEnvironmentResponse';
import { Pagination } from '../models/Pagination';
import { PathSnapshotsRequest } from '../models/PathSnapshotsRequest';
import { PathSnapshotsResponse } from '../models/PathSnapshotsResponse';
import { Preferences } from '../models/Preferences';
import { Project } from '../models/Project';
import { Provider } from '../models/Provider';
import { QueryDBResponse } from '../models/QueryDBResponse';
import { QueryDBResultResponse } from '../models/QueryDBResultResponse';
import { QueryDBStatusResponse } from '../models/QueryDBStatusResponse';
import { RegisterInput } from '../models/RegisterInput';
import { RegisterInputBillingToken } from '../models/RegisterInputBillingToken';
import { RegisterResponse } from '../models/RegisterResponse';
import { RequestDetails } from '../models/RequestDetails';
import { ResourceIdFilters } from '../models/ResourceIdFilters';
import { ResourceNameFilters } from '../models/ResourceNameFilters';
import { ResourceProperties } from '../models/ResourceProperties';
import { ResourceSortField } from '../models/ResourceSortField';
import { ResourceType } from '../models/ResourceType';
import { ResourceTypeFilters } from '../models/ResourceTypeFilters';
import { RestoreAccount } from '../models/RestoreAccount';
import { RestoreAccountRegions } from '../models/RestoreAccountRegions';
import { RestoreAtlasClusterInput } from '../models/RestoreAtlasClusterInput';
import { RestoreAvailabilityZones } from '../models/RestoreAvailabilityZones';
import { RestoreBucketRequest } from '../models/RestoreBucketRequest';
import { RestoreDbToRdsInstanceRequest } from '../models/RestoreDbToRdsInstanceRequest';
import { RestoreDbToRdsSnapshotRequest } from '../models/RestoreDbToRdsSnapshotRequest';
import { RestoreFilesRequest } from '../models/RestoreFilesRequest';
import { RestoreInstanceInput } from '../models/RestoreInstanceInput';
import { RestoreInstanceVolumeInput } from '../models/RestoreInstanceVolumeInput';
import { RestoreJobInitiationResponse } from '../models/RestoreJobInitiationResponse';
import { RestoreRdsSubnetGroups } from '../models/RestoreRdsSubnetGroups';
import { RestoreRdsSubnetGroupsRdsSubnetGroupsInner } from '../models/RestoreRdsSubnetGroupsRdsSubnetGroupsInner';
import { RestoreSecurityGroups } from '../models/RestoreSecurityGroups';
import { RestoreSecurityGroupsSecurityGroupsInner } from '../models/RestoreSecurityGroupsSecurityGroupsInner';
import { RestoreVolumeToEbsRequest } from '../models/RestoreVolumeToEbsRequest';
import { RestoreVolumeToEbsSnapshotRequest } from '../models/RestoreVolumeToEbsSnapshotRequest';
import { Role } from '../models/Role';
import { RolesResponse } from '../models/RolesResponse';
import { S3Bucket } from '../models/S3Bucket';
import { S3Buckets } from '../models/S3Buckets';
import { SamlIdentityProvider } from '../models/SamlIdentityProvider';
import { SamlIdentityProviderConfigsResponse } from '../models/SamlIdentityProviderConfigsResponse';
import { SchemaRecord } from '../models/SchemaRecord';
import { SearchDBResponse } from '../models/SearchDBResponse';
import { SearchFilesResponse } from '../models/SearchFilesResponse';
import { SearchInput } from '../models/SearchInput';
import { ServiceProviderDetails } from '../models/ServiceProviderDetails';
import { Severity } from '../models/Severity';
import { SeverityFilters } from '../models/SeverityFilters';
import { Snapshot } from '../models/Snapshot';
import { SnapshotProperties } from '../models/SnapshotProperties';
import { SnapshotPropertiesVolumePropertiesInner } from '../models/SnapshotPropertiesVolumePropertiesInner';
import { SnapshotStatus } from '../models/SnapshotStatus';
import { SnapshotStorage } from '../models/SnapshotStorage';
import { SortJobBy } from '../models/SortJobBy';
import { SortLogsBy } from '../models/SortLogsBy';
import { SortNotificationBy } from '../models/SortNotificationBy';
import { SortOrder } from '../models/SortOrder';
import { SortResourceBy } from '../models/SortResourceBy';
import { SourceRegionFilters } from '../models/SourceRegionFilters';
import { SourceStorage } from '../models/SourceStorage';
import { SourceStorageBackupStatus } from '../models/SourceStorageBackupStatus';
import { SsoIntegrationSettings } from '../models/SsoIntegrationSettings';
import { StatusCodeFilters } from '../models/StatusCodeFilters';
import { StatusJobFilters } from '../models/StatusJobFilters';
import { SubnetFilters } from '../models/SubnetFilters';
import { TableRecord } from '../models/TableRecord';
import { TokenResponse } from '../models/TokenResponse';
import { UnifiedDBRecord } from '../models/UnifiedDBRecord';
import { UnifiedDBServerRecord } from '../models/UnifiedDBServerRecord';
import { UnifiedDatabaseRecord } from '../models/UnifiedDatabaseRecord';
import { UnifiedSchemaRecord } from '../models/UnifiedSchemaRecord';
import { UnifiedTableRecord } from '../models/UnifiedTableRecord';
import { UpdateAccountInput } from '../models/UpdateAccountInput';
import { UpdateBackupVaultNameInput } from '../models/UpdateBackupVaultNameInput';
import { UpdateControlViolationInput } from '../models/UpdateControlViolationInput';
import { UpdateIntegrationRequest } from '../models/UpdateIntegrationRequest';
import { UpdateRoleInput } from '../models/UpdateRoleInput';
import { UpdateSamlIdentityProviderInput } from '../models/UpdateSamlIdentityProviderInput';
import { UpdateUserInput } from '../models/UpdateUserInput';
import { UpdateViewerRoleRequest } from '../models/UpdateViewerRoleRequest';
import { User } from '../models/User';
import { UserIdFilters } from '../models/UserIdFilters';
import { VaultFilters } from '../models/VaultFilters';
import { Viewer } from '../models/Viewer';
import { VisualizationHistogramSeries } from '../models/VisualizationHistogramSeries';
import { VisualizationHistogramTimePoint } from '../models/VisualizationHistogramTimePoint';
import { VisualizationLabel } from '../models/VisualizationLabel';
import { VolumeSettings } from '../models/VolumeSettings';
import { VpcPropertyFilters } from '../models/VpcPropertyFilters';


async function requestWithRetries(requestContext: RequestContext, configuration: Configuration): Promise<ResponseContext> {
  if (requestContext.isIdempotent()) {
    return retry(() => requestWithMiddleware(requestContext, configuration), configuration.retries);
  }
  return requestWithMiddleware(requestContext, configuration);
}

async function requestWithMiddleware(requestContext: RequestContext, configuration: Configuration): Promise<ResponseContext> {
  for (let middleware of configuration.middleware!) {
    requestContext = await middleware.pre(requestContext);
  }
  let signal = requestContext.signal;
  if (configuration.signal && 'any' in AbortSignal) {
    signal = AbortSignal.any([configuration.signal, signal!]);
  }
  const resp = await fetch(requestContext.getUrl(), {
    method: requestContext.getHttpMethod(),
    body: requestContext.getBody(),
    headers: requestContext.getHeaders(),
    credentials: "same-origin",
    signal: signal
  });
  
  let headers: { [key: string]: string } = {};
  resp.headers.forEach((value, key) => {
    headers[key] = value;
  });

  let responseContext = new ResponseContext(resp.status, headers, {
    text: () => resp.text(), // TODO benji remove this indirection 
    binary: () => resp.blob(),
    json: () => resp.json(),
  });

  for (let middleware of configuration.middleware!) {
    responseContext = await middleware.post(responseContext);
  }
  return responseContext;
}
import { AmazonRegisterApiRequestFactory, AmazonRegisterApiResponseProcessor} from "../apis/AmazonRegisterApi";

export class PromiseAmazonRegisterApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: AmazonRegisterApiRequestFactory = new AmazonRegisterApiRequestFactory(configuration),
    private responseProcessor: AmazonRegisterApiResponseProcessor = new AmazonRegisterApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint allows users to register a new account by providing payment token and initial login information.
   * Callback endpoint for Amazon marketplace
   * @param xAmznMarketplaceToken Amazon marketplace token
   */
  public async amazonRegisterWithHttpInfo(xAmznMarketplaceToken?: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.amazonRegister(xAmznMarketplaceToken, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.amazonRegisterWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint allows users to register a new account by providing payment token and initial login information.
   * Callback endpoint for Amazon marketplace
   * @param xAmznMarketplaceToken Amazon marketplace token
   */
  public async amazonRegister(xAmznMarketplaceToken?: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.amazonRegisterWithHttpInfo(xAmznMarketplaceToken, _options);
    return responseWithInfo.data;
  }


}


import { AuditLogsApiRequestFactory, AuditLogsApiResponseProcessor} from "../apis/AuditLogsApi";

export class PromiseAuditLogsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: AuditLogsApiRequestFactory = new AuditLogsApiRequestFactory(configuration),
    private responseProcessor: AuditLogsApiResponseProcessor = new AuditLogsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieves a list of audit logs. 
   * List Audit Logs
   * @param pageToken Cursor that points to the first record of the next page of results. Get this value from the previous response. To preserve the results in the same order, use the same sorting and filters in the first request as all subsequent requests. 
   * @param pageSize Max number of items to return in the next page.
   * @param listAuditLogRequest The request body for filtering audit logs
   */
  public async listAuditLogsWithHttpInfo(pageToken?: string, pageSize?: number, listAuditLogRequest?: ListAuditLogRequest, _options?: Configuration): Promise<HttpInfo<ListAuditLogResponse>> {
    let requestContextValue = await this.requestFactory.listAuditLogs(pageToken, pageSize, listAuditLogRequest, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listAuditLogsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieves a list of audit logs. 
   * List Audit Logs
   * @param pageToken Cursor that points to the first record of the next page of results. Get this value from the previous response. To preserve the results in the same order, use the same sorting and filters in the first request as all subsequent requests. 
   * @param pageSize Max number of items to return in the next page.
   * @param listAuditLogRequest The request body for filtering audit logs
   */
  public async listAuditLogs(pageToken?: string, pageSize?: number, listAuditLogRequest?: ListAuditLogRequest, _options?: Configuration): Promise<ListAuditLogResponse> {
    const responseWithInfo = await this.listAuditLogsWithHttpInfo(pageToken, pageSize, listAuditLogRequest, _options);
    return responseWithInfo.data;
  }


}


import { AuditLogsPopulateApiRequestFactory, AuditLogsPopulateApiResponseProcessor} from "../apis/AuditLogsPopulateApi";

export class PromiseAuditLogsPopulateApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: AuditLogsPopulateApiRequestFactory = new AuditLogsPopulateApiRequestFactory(configuration),
    private responseProcessor: AuditLogsPopulateApiResponseProcessor = new AuditLogsPopulateApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Populates test data for a specific project with a given version number.
   * Populate test data
   * @param projectId The project ID
   * @param version the version number of the data to populate
   */
  public async auditLogsPopulateWithHttpInfo(projectId: string, version: number, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.auditLogsPopulate(projectId, version, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.auditLogsPopulateWithHttpInfo(responseContext)
  }

  /**
   * Description: Populates test data for a specific project with a given version number.
   * Populate test data
   * @param projectId The project ID
   * @param version the version number of the data to populate
   */
  public async auditLogsPopulate(projectId: string, version: number, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.auditLogsPopulateWithHttpInfo(projectId, version, _options);
    return responseWithInfo.data;
  }


}


import { AuthApiRequestFactory, AuthApiResponseProcessor} from "../apis/AuthApi";

export class PromiseAuthApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: AuthApiRequestFactory = new AuthApiRequestFactory(configuration),
    private responseProcessor: AuthApiResponseProcessor = new AuthApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieves an access token, which you then pass in the `Authorization` header when calling API methods.  The client ID and secret you provide when calling this method must be from an integration from your Eon account. To learn more about creating an integration and completing the authentication flow, see [Authentication](/api/using-the-api/authentication).  The returned access token is valid for 12 hours. After that, you must call this method again to get a new token. 
   * Get Access Token
   * @param integrationCredentials Auth parameters
   */
  public async getAccessTokenWithHttpInfo(integrationCredentials: IntegrationCredentials, _options?: Configuration): Promise<HttpInfo<TokenResponse>> {
    let requestContextValue = await this.requestFactory.getAccessToken(integrationCredentials, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getAccessTokenWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieves an access token, which you then pass in the `Authorization` header when calling API methods.  The client ID and secret you provide when calling this method must be from an integration from your Eon account. To learn more about creating an integration and completing the authentication flow, see [Authentication](/api/using-the-api/authentication).  The returned access token is valid for 12 hours. After that, you must call this method again to get a new token. 
   * Get Access Token
   * @param integrationCredentials Auth parameters
   */
  public async getAccessToken(integrationCredentials: IntegrationCredentials, _options?: Configuration): Promise<TokenResponse> {
    const responseWithInfo = await this.getAccessTokenWithHttpInfo(integrationCredentials, _options);
    return responseWithInfo.data;
  }


}


import { AzureMarketplaceApiRequestFactory, AzureMarketplaceApiResponseProcessor} from "../apis/AzureMarketplaceApi";

export class PromiseAzureMarketplaceApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: AzureMarketplaceApiRequestFactory = new AzureMarketplaceApiRequestFactory(configuration),
    private responseProcessor: AzureMarketplaceApiResponseProcessor = new AzureMarketplaceApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Endpoint to handle webhook events sent by Microsoft for subscription changes, such as ChangePlan, ChangeQuantity, Renew, Suspend, Unsubscribe, or Reinstate. The input schema is defined in the AzureMarketplaceWebhookInput.yaml file.
   * Handle Azure Marketplace webhook events.
   * @param azureMarketplaceWebhookInput Payload for the Azure Marketplace webhook event.
   */
  public async handleAzureMarketplaceWebhookWithHttpInfo(azureMarketplaceWebhookInput: AzureMarketplaceWebhookInput, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.handleAzureMarketplaceWebhook(azureMarketplaceWebhookInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.handleAzureMarketplaceWebhookWithHttpInfo(responseContext)
  }

  /**
   * Description: Endpoint to handle webhook events sent by Microsoft for subscription changes, such as ChangePlan, ChangeQuantity, Renew, Suspend, Unsubscribe, or Reinstate. The input schema is defined in the AzureMarketplaceWebhookInput.yaml file.
   * Handle Azure Marketplace webhook events.
   * @param azureMarketplaceWebhookInput Payload for the Azure Marketplace webhook event.
   */
  public async handleAzureMarketplaceWebhook(azureMarketplaceWebhookInput: AzureMarketplaceWebhookInput, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.handleAzureMarketplaceWebhookWithHttpInfo(azureMarketplaceWebhookInput, _options);
    return responseWithInfo.data;
  }


}


import { AzureOAuthCallbackApiRequestFactory, AzureOAuthCallbackApiResponseProcessor} from "../apis/AzureOAuthCallbackApi";

export class PromiseAzureOAuthCallbackApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: AzureOAuthCallbackApiRequestFactory = new AzureOAuthCallbackApiRequestFactory(configuration),
    private responseProcessor: AzureOAuthCallbackApiResponseProcessor = new AzureOAuthCallbackApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: API for onboarding Azure subscriptions to Eon
   * Callback endpoint for Azure OAuth
   * @param code Azure OAuth code
   * @param state Request id state
   */
  public async azureOAuthCallbackWithHttpInfo(code: string, state: string, _options?: Configuration): Promise<HttpInfo<string>> {
    let requestContextValue = await this.requestFactory.azureOAuthCallback(code, state, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.azureOAuthCallbackWithHttpInfo(responseContext)
  }

  /**
   * Description: API for onboarding Azure subscriptions to Eon
   * Callback endpoint for Azure OAuth
   * @param code Azure OAuth code
   * @param state Request id state
   */
  public async azureOAuthCallback(code: string, state: string, _options?: Configuration): Promise<string> {
    const responseWithInfo = await this.azureOAuthCallbackWithHttpInfo(code, state, _options);
    return responseWithInfo.data;
  }


}


import { AzureRegisterApiRequestFactory, AzureRegisterApiResponseProcessor} from "../apis/AzureRegisterApi";

export class PromiseAzureRegisterApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: AzureRegisterApiRequestFactory = new AzureRegisterApiRequestFactory(configuration),
    private responseProcessor: AzureRegisterApiResponseProcessor = new AzureRegisterApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint allows users to register a new account by providing payment token and initial login information.
   * Callback endpoint for Microsoft Azure marketplace
   * @param token Azure marketplace token
   */
  public async azureRegisterWithHttpInfo(token: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.azureRegister(token, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.azureRegisterWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint allows users to register a new account by providing payment token and initial login information.
   * Callback endpoint for Microsoft Azure marketplace
   * @param token Azure marketplace token
   */
  public async azureRegister(token: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.azureRegisterWithHttpInfo(token, _options);
    return responseWithInfo.data;
  }


}


import { BackupResourceFromDatesApiRequestFactory, BackupResourceFromDatesApiResponseProcessor} from "../apis/BackupResourceFromDatesApi";

export class PromiseBackupResourceFromDatesApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: BackupResourceFromDatesApiRequestFactory = new BackupResourceFromDatesApiRequestFactory(configuration),
    private responseProcessor: BackupResourceFromDatesApiResponseProcessor = new BackupResourceFromDatesApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: For a given date, adding to the backup backlog
   * Backup resource from dates
   * @param projectId The project ID
   * @param id ID of the inventory item
   * @param backupFromDatesInput The request body for backing up a resource from dates
   */
  public async backupResourceFromDatesWithHttpInfo(projectId: string, id: string, backupFromDatesInput: BackupFromDatesInput, _options?: Configuration): Promise<HttpInfo<BackupFromDatesResponse>> {
    let requestContextValue = await this.requestFactory.backupResourceFromDates(projectId, id, backupFromDatesInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.backupResourceFromDatesWithHttpInfo(responseContext)
  }

  /**
   * Description: For a given date, adding to the backup backlog
   * Backup resource from dates
   * @param projectId The project ID
   * @param id ID of the inventory item
   * @param backupFromDatesInput The request body for backing up a resource from dates
   */
  public async backupResourceFromDates(projectId: string, id: string, backupFromDatesInput: BackupFromDatesInput, _options?: Configuration): Promise<BackupFromDatesResponse> {
    const responseWithInfo = await this.backupResourceFromDatesWithHttpInfo(projectId, id, backupFromDatesInput, _options);
    return responseWithInfo.data;
  }


}


import { CallbackApiRequestFactory, CallbackApiResponseProcessor} from "../apis/CallbackApi";

export class PromiseCallbackApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CallbackApiRequestFactory = new CallbackApiRequestFactory(configuration),
    private responseProcessor: CallbackApiResponseProcessor = new CallbackApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Endpoint to handle OAuth callback and exchange authorization code for access token.
   * Handle OAuth callback.
   * @param code Authorization code received from the OAuth provider.
   */
  public async callbackWithHttpInfo(code: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.callback(code, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.callbackWithHttpInfo(responseContext)
  }

  /**
   * Description: Endpoint to handle OAuth callback and exchange authorization code for access token.
   * Handle OAuth callback.
   * @param code Authorization code received from the OAuth provider.
   */
  public async callback(code: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.callbackWithHttpInfo(code, _options);
    return responseWithInfo.data;
  }


}


import { CreateBackupJobApiRequestFactory, CreateBackupJobApiResponseProcessor} from "../apis/CreateBackupJobApi";

export class PromiseCreateBackupJobApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CreateBackupJobApiRequestFactory = new CreateBackupJobApiRequestFactory(configuration),
    private responseProcessor: CreateBackupJobApiResponseProcessor = new CreateBackupJobApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Creates a new backup job for a given project by specifying the project ID and providing necessary details in the request body.
   * Create a new backup job
   * @param projectId The project ID
   * @param createBackupJobInput The request body for creating a new backup job
   */
  public async createBackupJobWithHttpInfo(projectId: string, createBackupJobInput: CreateBackupJobInput, _options?: Configuration): Promise<HttpInfo<BackupJobResponse>> {
    let requestContextValue = await this.requestFactory.createBackupJob(projectId, createBackupJobInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.createBackupJobWithHttpInfo(responseContext)
  }

  /**
   * Description: Creates a new backup job for a given project by specifying the project ID and providing necessary details in the request body.
   * Create a new backup job
   * @param projectId The project ID
   * @param createBackupJobInput The request body for creating a new backup job
   */
  public async createBackupJob(projectId: string, createBackupJobInput: CreateBackupJobInput, _options?: Configuration): Promise<BackupJobResponse> {
    const responseWithInfo = await this.createBackupJobWithHttpInfo(projectId, createBackupJobInput, _options);
    return responseWithInfo.data;
  }


}


import { CreateBackupJobFromSnapshotsApiRequestFactory, CreateBackupJobFromSnapshotsApiResponseProcessor} from "../apis/CreateBackupJobFromSnapshotsApi";

export class PromiseCreateBackupJobFromSnapshotsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CreateBackupJobFromSnapshotsApiRequestFactory = new CreateBackupJobFromSnapshotsApiRequestFactory(configuration),
    private responseProcessor: CreateBackupJobFromSnapshotsApiResponseProcessor = new CreateBackupJobFromSnapshotsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Creates a new backup job for a given project by specifying the project ID and providing necessary details in the request body, and a list of snapshots to backup.
   * Create a new backup job from snapshots
   * @param projectId The project ID
   * @param id ID of the inventory item
   * @param backupFromSnapshotsInput The request body for creating a new backup job
   */
  public async createBackupJobFromSnapshotsWithHttpInfo(projectId: string, id: string, backupFromSnapshotsInput: BackupFromSnapshotsInput, _options?: Configuration): Promise<HttpInfo<BackupJobResponse>> {
    let requestContextValue = await this.requestFactory.createBackupJobFromSnapshots(projectId, id, backupFromSnapshotsInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.createBackupJobFromSnapshotsWithHttpInfo(responseContext)
  }

  /**
   * Description: Creates a new backup job for a given project by specifying the project ID and providing necessary details in the request body, and a list of snapshots to backup.
   * Create a new backup job from snapshots
   * @param projectId The project ID
   * @param id ID of the inventory item
   * @param backupFromSnapshotsInput The request body for creating a new backup job
   */
  public async createBackupJobFromSnapshots(projectId: string, id: string, backupFromSnapshotsInput: BackupFromSnapshotsInput, _options?: Configuration): Promise<BackupJobResponse> {
    const responseWithInfo = await this.createBackupJobFromSnapshotsWithHttpInfo(projectId, id, backupFromSnapshotsInput, _options);
    return responseWithInfo.data;
  }


}


import { CreateBackupPolicyApiRequestFactory, CreateBackupPolicyApiResponseProcessor} from "../apis/CreateBackupPolicyApi";

export class PromiseCreateBackupPolicyApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CreateBackupPolicyApiRequestFactory = new CreateBackupPolicyApiRequestFactory(configuration),
    private responseProcessor: CreateBackupPolicyApiResponseProcessor = new CreateBackupPolicyApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API allows the creation of a backup policy for a specified project.
   * Create backup policy
   * @param projectId The project ID
   * @param createPolicyInput The request body for creating a policy
   */
  public async createPolicyWithHttpInfo(projectId: string, createPolicyInput: CreatePolicyInput, _options?: Configuration): Promise<HttpInfo<BackupPolicy>> {
    let requestContextValue = await this.requestFactory.createPolicy(projectId, createPolicyInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.createPolicyWithHttpInfo(responseContext)
  }

  /**
   * Description: This API allows the creation of a backup policy for a specified project.
   * Create backup policy
   * @param projectId The project ID
   * @param createPolicyInput The request body for creating a policy
   */
  public async createPolicy(projectId: string, createPolicyInput: CreatePolicyInput, _options?: Configuration): Promise<BackupPolicy> {
    const responseWithInfo = await this.createPolicyWithHttpInfo(projectId, createPolicyInput, _options);
    return responseWithInfo.data;
  }


}


import { CreateBackupVaultApiRequestFactory, CreateBackupVaultApiResponseProcessor} from "../apis/CreateBackupVaultApi";

export class PromiseCreateBackupVaultApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CreateBackupVaultApiRequestFactory = new CreateBackupVaultApiRequestFactory(configuration),
    private responseProcessor: CreateBackupVaultApiResponseProcessor = new CreateBackupVaultApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API allows the creation of a new backup vault for a specified project.
   * Create a new backup vault
   * @param projectId The project ID
   * @param createBackupVaultInput The request body for creating a new backup vault
   */
  public async createVaultWithHttpInfo(projectId: string, createBackupVaultInput: CreateBackupVaultInput, _options?: Configuration): Promise<HttpInfo<BackupVault>> {
    let requestContextValue = await this.requestFactory.createVault(projectId, createBackupVaultInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.createVaultWithHttpInfo(responseContext)
  }

  /**
   * Description: This API allows the creation of a new backup vault for a specified project.
   * Create a new backup vault
   * @param projectId The project ID
   * @param createBackupVaultInput The request body for creating a new backup vault
   */
  public async createVault(projectId: string, createBackupVaultInput: CreateBackupVaultInput, _options?: Configuration): Promise<BackupVault> {
    const responseWithInfo = await this.createVaultWithHttpInfo(projectId, createBackupVaultInput, _options);
    return responseWithInfo.data;
  }


}


import { CreateControlApiRequestFactory, CreateControlApiResponseProcessor} from "../apis/CreateControlApi";

export class PromiseCreateControlApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CreateControlApiRequestFactory = new CreateControlApiRequestFactory(configuration),
    private responseProcessor: CreateControlApiResponseProcessor = new CreateControlApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API operation enables the creation of a new control within a specified project.
   * Create control
   * @param projectId The project ID
   * @param control The request body for creating a control
   */
  public async createControlWithHttpInfo(projectId: string, control: Control, _options?: Configuration): Promise<HttpInfo<Control>> {
    let requestContextValue = await this.requestFactory.createControl(projectId, control, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.createControlWithHttpInfo(responseContext)
  }

  /**
   * Description: This API operation enables the creation of a new control within a specified project.
   * Create control
   * @param projectId The project ID
   * @param control The request body for creating a control
   */
  public async createControl(projectId: string, control: Control, _options?: Configuration): Promise<Control> {
    const responseWithInfo = await this.createControlWithHttpInfo(projectId, control, _options);
    return responseWithInfo.data;
  }


}


import { CreateIntegrationApiRequestFactory, CreateIntegrationApiResponseProcessor} from "../apis/CreateIntegrationApi";

export class PromiseCreateIntegrationApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CreateIntegrationApiRequestFactory = new CreateIntegrationApiRequestFactory(configuration),
    private responseProcessor: CreateIntegrationApiResponseProcessor = new CreateIntegrationApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint allows the creation of a new integration for machine to machine communication. It requires administrative permissions.
   * Create a new integration
   * @param createIntegrationRequest The request body for creating an integration
   */
  public async createIntegrationWithHttpInfo(createIntegrationRequest: CreateIntegrationRequest, _options?: Configuration): Promise<HttpInfo<CreateIntegrationResponse>> {
    let requestContextValue = await this.requestFactory.createIntegration(createIntegrationRequest, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.createIntegrationWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint allows the creation of a new integration for machine to machine communication. It requires administrative permissions.
   * Create a new integration
   * @param createIntegrationRequest The request body for creating an integration
   */
  public async createIntegration(createIntegrationRequest: CreateIntegrationRequest, _options?: Configuration): Promise<CreateIntegrationResponse> {
    const responseWithInfo = await this.createIntegrationWithHttpInfo(createIntegrationRequest, _options);
    return responseWithInfo.data;
  }


}


import { CreateProjectApiRequestFactory, CreateProjectApiResponseProcessor} from "../apis/CreateProjectApi";

export class PromiseCreateProjectApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CreateProjectApiRequestFactory = new CreateProjectApiRequestFactory(configuration),
    private responseProcessor: CreateProjectApiResponseProcessor = new CreateProjectApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint allows an administrator to create a new project in the system. The request body must be in JSON format containing the project details.
   * Create a new project
   * @param createProjectInput The request body for creating a project
   */
  public async createProjectWithHttpInfo(createProjectInput: CreateProjectInput, _options?: Configuration): Promise<HttpInfo<Project>> {
    let requestContextValue = await this.requestFactory.createProject(createProjectInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.createProjectWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint allows an administrator to create a new project in the system. The request body must be in JSON format containing the project details.
   * Create a new project
   * @param createProjectInput The request body for creating a project
   */
  public async createProject(createProjectInput: CreateProjectInput, _options?: Configuration): Promise<Project> {
    const responseWithInfo = await this.createProjectWithHttpInfo(createProjectInput, _options);
    return responseWithInfo.data;
  }


}


import { CreateProjectRestoreAccountApiRequestFactory, CreateProjectRestoreAccountApiResponseProcessor} from "../apis/CreateProjectRestoreAccountApi";

export class PromiseCreateProjectRestoreAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CreateProjectRestoreAccountApiRequestFactory = new CreateProjectRestoreAccountApiRequestFactory(configuration),
    private responseProcessor: CreateProjectRestoreAccountApiResponseProcessor = new CreateProjectRestoreAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Creates a new restore account for the specified project by providing the required account information.
   * Create a new restore account
   * @param projectId The project ID
   * @param createAccountInput The request body for creating a restore account
   */
  public async createProjectRestoreAccountWithHttpInfo(projectId: string, createAccountInput: CreateAccountInput, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.createProjectRestoreAccount(projectId, createAccountInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.createProjectRestoreAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: Creates a new restore account for the specified project by providing the required account information.
   * Create a new restore account
   * @param projectId The project ID
   * @param createAccountInput The request body for creating a restore account
   */
  public async createProjectRestoreAccount(projectId: string, createAccountInput: CreateAccountInput, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.createProjectRestoreAccountWithHttpInfo(projectId, createAccountInput, _options);
    return responseWithInfo.data;
  }


}


import { CreateProjectSourceAccountApiRequestFactory, CreateProjectSourceAccountApiResponseProcessor} from "../apis/CreateProjectSourceAccountApi";

export class PromiseCreateProjectSourceAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CreateProjectSourceAccountApiRequestFactory = new CreateProjectSourceAccountApiRequestFactory(configuration),
    private responseProcessor: CreateProjectSourceAccountApiResponseProcessor = new CreateProjectSourceAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint allows users to create a new source account for a specified project identified by the projectId.
   * Create a new source account
   * @param projectId The project ID
   * @param createAccountInput The request body for creating a source account
   */
  public async createProjectSourceAccountWithHttpInfo(projectId: string, createAccountInput: CreateAccountInput, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.createProjectSourceAccount(projectId, createAccountInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.createProjectSourceAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint allows users to create a new source account for a specified project identified by the projectId.
   * Create a new source account
   * @param projectId The project ID
   * @param createAccountInput The request body for creating a source account
   */
  public async createProjectSourceAccount(projectId: string, createAccountInput: CreateAccountInput, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.createProjectSourceAccountWithHttpInfo(projectId, createAccountInput, _options);
    return responseWithInfo.data;
  }


}


import { CreateProjectUserApiRequestFactory, CreateProjectUserApiResponseProcessor} from "../apis/CreateProjectUserApi";

export class PromiseCreateProjectUserApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CreateProjectUserApiRequestFactory = new CreateProjectUserApiRequestFactory(configuration),
    private responseProcessor: CreateProjectUserApiResponseProcessor = new CreateProjectUserApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: API to create a new user in a specified project.
   * Create a new user
   * @param projectId The project ID
   * @param createUserInput The request body for creating a user
   */
  public async createProjectUserWithHttpInfo(projectId: string, createUserInput: CreateUserInput, _options?: Configuration): Promise<HttpInfo<User>> {
    let requestContextValue = await this.requestFactory.createProjectUser(projectId, createUserInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.createProjectUserWithHttpInfo(responseContext)
  }

  /**
   * Description: API to create a new user in a specified project.
   * Create a new user
   * @param projectId The project ID
   * @param createUserInput The request body for creating a user
   */
  public async createProjectUser(projectId: string, createUserInput: CreateUserInput, _options?: Configuration): Promise<User> {
    const responseWithInfo = await this.createProjectUserWithHttpInfo(projectId, createUserInput, _options);
    return responseWithInfo.data;
  }


}


import { CreateRestoreAccountApiRequestFactory, CreateRestoreAccountApiResponseProcessor} from "../apis/CreateRestoreAccountApi";

export class PromiseCreateRestoreAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CreateRestoreAccountApiRequestFactory = new CreateRestoreAccountApiRequestFactory(configuration),
    private responseProcessor: CreateRestoreAccountApiResponseProcessor = new CreateRestoreAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This operation allows administrators to create a new restore account. The request body should include necessary account details in JSON format.
   * Create a new restore account
   * @param createAccountInput The request body for creating a restore account
   */
  public async createRestoreAccountWithHttpInfo(createAccountInput: CreateAccountInput, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.createRestoreAccount(createAccountInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.createRestoreAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: This operation allows administrators to create a new restore account. The request body should include necessary account details in JSON format.
   * Create a new restore account
   * @param createAccountInput The request body for creating a restore account
   */
  public async createRestoreAccount(createAccountInput: CreateAccountInput, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.createRestoreAccountWithHttpInfo(createAccountInput, _options);
    return responseWithInfo.data;
  }


}


import { CreateRoleApiRequestFactory, CreateRoleApiResponseProcessor} from "../apis/CreateRoleApi";

export class PromiseCreateRoleApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CreateRoleApiRequestFactory = new CreateRoleApiRequestFactory(configuration),
    private responseProcessor: CreateRoleApiResponseProcessor = new CreateRoleApiResponseProcessor() 
  ) {
    
  }

  /**
   * Create a new role
   * @param createRoleInput 
   */
  public async createRoleWithHttpInfo(createRoleInput?: CreateRoleInput, _options?: Configuration): Promise<HttpInfo<Role>> {
    let requestContextValue = await this.requestFactory.createRole(createRoleInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.createRoleWithHttpInfo(responseContext)
  }

  /**
   * Create a new role
   * @param createRoleInput 
   */
  public async createRole(createRoleInput?: CreateRoleInput, _options?: Configuration): Promise<Role> {
    const responseWithInfo = await this.createRoleWithHttpInfo(createRoleInput, _options);
    return responseWithInfo.data;
  }


}


import { CreateSamlIdentityProviderConfigApiRequestFactory, CreateSamlIdentityProviderConfigApiResponseProcessor} from "../apis/CreateSamlIdentityProviderConfigApi";

export class PromiseCreateSamlIdentityProviderConfigApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CreateSamlIdentityProviderConfigApiRequestFactory = new CreateSamlIdentityProviderConfigApiRequestFactory(configuration),
    private responseProcessor: CreateSamlIdentityProviderConfigApiResponseProcessor = new CreateSamlIdentityProviderConfigApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API allows administrators to create a new SAML identity provider configuration for customer authentication. It requires admin permissions (admin:idp_configs) and includes validation to prevent the creation of duplicate configurations for the same domain.
   * Create a new saml identity provider config
   * @param createSamlIdentityProviderConfigInput The request body for creating saml identity provider config
   */
  public async createSamlIdentityProviderConfigWithHttpInfo(createSamlIdentityProviderConfigInput: CreateSamlIdentityProviderConfigInput, _options?: Configuration): Promise<HttpInfo<SamlIdentityProvider>> {
    let requestContextValue = await this.requestFactory.createSamlIdentityProviderConfig(createSamlIdentityProviderConfigInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.createSamlIdentityProviderConfigWithHttpInfo(responseContext)
  }

  /**
   * Description: This API allows administrators to create a new SAML identity provider configuration for customer authentication. It requires admin permissions (admin:idp_configs) and includes validation to prevent the creation of duplicate configurations for the same domain.
   * Create a new saml identity provider config
   * @param createSamlIdentityProviderConfigInput The request body for creating saml identity provider config
   */
  public async createSamlIdentityProviderConfig(createSamlIdentityProviderConfigInput: CreateSamlIdentityProviderConfigInput, _options?: Configuration): Promise<SamlIdentityProvider> {
    const responseWithInfo = await this.createSamlIdentityProviderConfigWithHttpInfo(createSamlIdentityProviderConfigInput, _options);
    return responseWithInfo.data;
  }


}


import { CreateScanJobApiRequestFactory, CreateScanJobApiResponseProcessor} from "../apis/CreateScanJobApi";

export class PromiseCreateScanJobApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CreateScanJobApiRequestFactory = new CreateScanJobApiRequestFactory(configuration),
    private responseProcessor: CreateScanJobApiResponseProcessor = new CreateScanJobApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Creates a new scan job for a specified project ID, invoking a scan process and returning a message indicating successful initiation.
   * Create a new scan job
   * @param projectId The project ID
   * @param createScanJobInput The request body for creating a new scan job
   */
  public async createScanJobWithHttpInfo(projectId: string, createScanJobInput: CreateScanJobInput, _options?: Configuration): Promise<HttpInfo<Message>> {
    let requestContextValue = await this.requestFactory.createScanJob(projectId, createScanJobInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.createScanJobWithHttpInfo(responseContext)
  }

  /**
   * Description: Creates a new scan job for a specified project ID, invoking a scan process and returning a message indicating successful initiation.
   * Create a new scan job
   * @param projectId The project ID
   * @param createScanJobInput The request body for creating a new scan job
   */
  public async createScanJob(projectId: string, createScanJobInput: CreateScanJobInput, _options?: Configuration): Promise<Message> {
    const responseWithInfo = await this.createScanJobWithHttpInfo(projectId, createScanJobInput, _options);
    return responseWithInfo.data;
  }


}


import { CreateScanningAccountApiRequestFactory, CreateScanningAccountApiResponseProcessor} from "../apis/CreateScanningAccountApi";

export class PromiseCreateScanningAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CreateScanningAccountApiRequestFactory = new CreateScanningAccountApiRequestFactory(configuration),
    private responseProcessor: CreateScanningAccountApiResponseProcessor = new CreateScanningAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API operation allows an admin to create a new scanning account. The request body required includes details needed for the account creation. Upon successful creation, it returns the account details, or a 409 status if the account already exists.
   * Create a new scanning account
   * @param createAccountInput The request body for creating a scanning account
   */
  public async createScanningAccountWithHttpInfo(createAccountInput: CreateAccountInput, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.createScanningAccount(createAccountInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.createScanningAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: This API operation allows an admin to create a new scanning account. The request body required includes details needed for the account creation. Upon successful creation, it returns the account details, or a 409 status if the account already exists.
   * Create a new scanning account
   * @param createAccountInput The request body for creating a scanning account
   */
  public async createScanningAccount(createAccountInput: CreateAccountInput, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.createScanningAccountWithHttpInfo(createAccountInput, _options);
    return responseWithInfo.data;
  }


}


import { CreateSourceAccountApiRequestFactory, CreateSourceAccountApiResponseProcessor} from "../apis/CreateSourceAccountApi";

export class PromiseCreateSourceAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CreateSourceAccountApiRequestFactory = new CreateSourceAccountApiRequestFactory(configuration),
    private responseProcessor: CreateSourceAccountApiResponseProcessor = new CreateSourceAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API creates a new source account. It requires admin permissions and expects a JSON request body conforming to the CreateAccountInput schema.
   * Create a new source account
   * @param createAccountInput The request body for creating a source account
   */
  public async createSourceAccountWithHttpInfo(createAccountInput: CreateAccountInput, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.createSourceAccount(createAccountInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.createSourceAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: This API creates a new source account. It requires admin permissions and expects a JSON request body conforming to the CreateAccountInput schema.
   * Create a new source account
   * @param createAccountInput The request body for creating a source account
   */
  public async createSourceAccount(createAccountInput: CreateAccountInput, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.createSourceAccountWithHttpInfo(createAccountInput, _options);
    return responseWithInfo.data;
  }


}


import { CreateUserApiRequestFactory, CreateUserApiResponseProcessor} from "../apis/CreateUserApi";

export class PromiseCreateUserApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CreateUserApiRequestFactory = new CreateUserApiRequestFactory(configuration),
    private responseProcessor: CreateUserApiResponseProcessor = new CreateUserApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint allows the creation of a new user. It requires administrative permissions and includes error handling for cases where the user already exists.
   * Create a new user
   * @param createUserInput The request body for creating a user
   */
  public async createUserWithHttpInfo(createUserInput: CreateUserInput, _options?: Configuration): Promise<HttpInfo<User>> {
    let requestContextValue = await this.requestFactory.createUser(createUserInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.createUserWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint allows the creation of a new user. It requires administrative permissions and includes error handling for cases where the user already exists.
   * Create a new user
   * @param createUserInput The request body for creating a user
   */
  public async createUser(createUserInput: CreateUserInput, _options?: Configuration): Promise<User> {
    const responseWithInfo = await this.createUserWithHttpInfo(createUserInput, _options);
    return responseWithInfo.data;
  }


}


import { CreateVaultAccountApiRequestFactory, CreateVaultAccountApiResponseProcessor} from "../apis/CreateVaultAccountApi";

export class PromiseCreateVaultAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: CreateVaultAccountApiRequestFactory = new CreateVaultAccountApiRequestFactory(configuration),
    private responseProcessor: CreateVaultAccountApiResponseProcessor = new CreateVaultAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint is used to create a new vault account. It requires admin permissions and expects a JSON request body with the necessary account creation details.
   * Create a new vault account
   * @param createAccountInput The request body for creating a vault account
   */
  public async createVaultAccountWithHttpInfo(createAccountInput: CreateAccountInput, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.createVaultAccount(createAccountInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.createVaultAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint is used to create a new vault account. It requires admin permissions and expects a JSON request body with the necessary account creation details.
   * Create a new vault account
   * @param createAccountInput The request body for creating a vault account
   */
  public async createVaultAccount(createAccountInput: CreateAccountInput, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.createVaultAccountWithHttpInfo(createAccountInput, _options);
    return responseWithInfo.data;
  }


}


import { DashboardApiRequestFactory, DashboardApiResponseProcessor} from "../apis/DashboardApi";

export class PromiseDashboardApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DashboardApiRequestFactory = new DashboardApiRequestFactory(configuration),
    private responseProcessor: DashboardApiResponseProcessor = new DashboardApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieves a list of snapshot storage sizes per day for the given date range.
   * Get Daily Storage Summaries
   * @param projectId ID of the project whose daily storage summaries you want to retrieve.
   * @param startDate First day of the date range in &#x60;YYYY-MM-DD&#x60; format.
   * @param endDate Last day of the date range in &#x60;YYYY-MM-DD&#x60; format.
   */
  public async getDailyStorageSummariesWithHttpInfo(projectId: string, startDate: string, endDate: string, _options?: Configuration): Promise<HttpInfo<DailyStorageSummaries>> {
    let requestContextValue = await this.requestFactory.getDailyStorageSummaries(projectId, startDate, endDate, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getDailyStorageSummariesWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieves a list of snapshot storage sizes per day for the given date range.
   * Get Daily Storage Summaries
   * @param projectId ID of the project whose daily storage summaries you want to retrieve.
   * @param startDate First day of the date range in &#x60;YYYY-MM-DD&#x60; format.
   * @param endDate Last day of the date range in &#x60;YYYY-MM-DD&#x60; format.
   */
  public async getDailyStorageSummaries(projectId: string, startDate: string, endDate: string, _options?: Configuration): Promise<DailyStorageSummaries> {
    const responseWithInfo = await this.getDailyStorageSummariesWithHttpInfo(projectId, startDate, endDate, _options);
    return responseWithInfo.data;
  }


}


import { DeleteAccountApiRequestFactory, DeleteAccountApiResponseProcessor} from "../apis/DeleteAccountApi";

export class PromiseDeleteAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DeleteAccountApiRequestFactory = new DeleteAccountApiRequestFactory(configuration),
    private responseProcessor: DeleteAccountApiResponseProcessor = new DeleteAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Deletes a specified account by ID.
   * Delete an account
   * @param id ID of the account to delete
   */
  public async deleteAccountWithHttpInfo(id: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.deleteAccount(id, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.deleteAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: Deletes a specified account by ID.
   * Delete an account
   * @param id ID of the account to delete
   */
  public async deleteAccount(id: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.deleteAccountWithHttpInfo(id, _options);
    return responseWithInfo.data;
  }


}


import { DeleteApplicationOverrideApiRequestFactory, DeleteApplicationOverrideApiResponseProcessor} from "../apis/DeleteApplicationOverrideApi";

export class PromiseDeleteApplicationOverrideApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DeleteApplicationOverrideApiRequestFactory = new DeleteApplicationOverrideApiRequestFactory(configuration),
    private responseProcessor: DeleteApplicationOverrideApiResponseProcessor = new DeleteApplicationOverrideApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API operation allows the deletion of application overrides for specified inventory items within a project.
   * Delete application override for an inventory item
   * @param projectId The project ID
   * @param applicationOverride The request body for deleting application override
   */
  public async deleteApplicationOverrideWithHttpInfo(projectId: string, applicationOverride: Array<ApplicationOverride>, _options?: Configuration): Promise<HttpInfo<BatchOperationResponse>> {
    let requestContextValue = await this.requestFactory.deleteApplicationOverride(projectId, applicationOverride, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.deleteApplicationOverrideWithHttpInfo(responseContext)
  }

  /**
   * Description: This API operation allows the deletion of application overrides for specified inventory items within a project.
   * Delete application override for an inventory item
   * @param projectId The project ID
   * @param applicationOverride The request body for deleting application override
   */
  public async deleteApplicationOverride(projectId: string, applicationOverride: Array<ApplicationOverride>, _options?: Configuration): Promise<BatchOperationResponse> {
    const responseWithInfo = await this.deleteApplicationOverrideWithHttpInfo(projectId, applicationOverride, _options);
    return responseWithInfo.data;
  }


}


import { DeleteBackupPolicyApiRequestFactory, DeleteBackupPolicyApiResponseProcessor} from "../apis/DeleteBackupPolicyApi";

export class PromiseDeleteBackupPolicyApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DeleteBackupPolicyApiRequestFactory = new DeleteBackupPolicyApiRequestFactory(configuration),
    private responseProcessor: DeleteBackupPolicyApiResponseProcessor = new DeleteBackupPolicyApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Deletes a specified policy by its ID within a given project.
   * Delete a policy
   * @param id ID of the policy to delete
   * @param projectId The project ID
   */
  public async deletePolicyWithHttpInfo(id: string, projectId: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.deletePolicy(id, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.deletePolicyWithHttpInfo(responseContext)
  }

  /**
   * Description: Deletes a specified policy by its ID within a given project.
   * Delete a policy
   * @param id ID of the policy to delete
   * @param projectId The project ID
   */
  public async deletePolicy(id: string, projectId: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.deletePolicyWithHttpInfo(id, projectId, _options);
    return responseWithInfo.data;
  }


}


import { DeleteBackupVaultApiRequestFactory, DeleteBackupVaultApiResponseProcessor} from "../apis/DeleteBackupVaultApi";

export class PromiseDeleteBackupVaultApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DeleteBackupVaultApiRequestFactory = new DeleteBackupVaultApiRequestFactory(configuration),
    private responseProcessor: DeleteBackupVaultApiResponseProcessor = new DeleteBackupVaultApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API deletes a specified backup vault identified by its ID and associated project ID.
   * Delete a backup vault
   * @param id ID of the backup vault to delete
   * @param projectId The project ID
   */
  public async deleteVaultWithHttpInfo(id: string, projectId: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.deleteVault(id, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.deleteVaultWithHttpInfo(responseContext)
  }

  /**
   * Description: This API deletes a specified backup vault identified by its ID and associated project ID.
   * Delete a backup vault
   * @param id ID of the backup vault to delete
   * @param projectId The project ID
   */
  public async deleteVault(id: string, projectId: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.deleteVaultWithHttpInfo(id, projectId, _options);
    return responseWithInfo.data;
  }


}


import { DeleteControlApiRequestFactory, DeleteControlApiResponseProcessor} from "../apis/DeleteControlApi";

export class PromiseDeleteControlApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DeleteControlApiRequestFactory = new DeleteControlApiRequestFactory(configuration),
    private responseProcessor: DeleteControlApiResponseProcessor = new DeleteControlApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API deletes a specific control identified by its ID within a given project.
   * Delete a control
   * @param id ID of the control to delete
   * @param projectId The project ID
   */
  public async deleteControlWithHttpInfo(id: string, projectId: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.deleteControl(id, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.deleteControlWithHttpInfo(responseContext)
  }

  /**
   * Description: This API deletes a specific control identified by its ID within a given project.
   * Delete a control
   * @param id ID of the control to delete
   * @param projectId The project ID
   */
  public async deleteControl(id: string, projectId: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.deleteControlWithHttpInfo(id, projectId, _options);
    return responseWithInfo.data;
  }


}


import { DeleteIntegrationApiRequestFactory, DeleteIntegrationApiResponseProcessor} from "../apis/DeleteIntegrationApi";

export class PromiseDeleteIntegrationApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DeleteIntegrationApiRequestFactory = new DeleteIntegrationApiRequestFactory(configuration),
    private responseProcessor: DeleteIntegrationApiResponseProcessor = new DeleteIntegrationApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint allows an admin to delete an integration by their ID.
   * Delete an integration
   * @param clientId Client ID of the integration to delete
   */
  public async deleteIntegrationWithHttpInfo(clientId: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.deleteIntegration(clientId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.deleteIntegrationWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint allows an admin to delete an integration by their ID.
   * Delete an integration
   * @param clientId Client ID of the integration to delete
   */
  public async deleteIntegration(clientId: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.deleteIntegrationWithHttpInfo(clientId, _options);
    return responseWithInfo.data;
  }


}


import { DeleteProjectApiRequestFactory, DeleteProjectApiResponseProcessor} from "../apis/DeleteProjectApi";

export class PromiseDeleteProjectApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DeleteProjectApiRequestFactory = new DeleteProjectApiRequestFactory(configuration),
    private responseProcessor: DeleteProjectApiResponseProcessor = new DeleteProjectApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API allows the deletion of a project identified by its unique projectId. It requires admin permissions and returns appropriate status codes based on the result of the operation.
   * Delete a project
   * @param projectId ID of the project to delete
   */
  public async deleteProjectWithHttpInfo(projectId: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.deleteProject(projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.deleteProjectWithHttpInfo(responseContext)
  }

  /**
   * Description: This API allows the deletion of a project identified by its unique projectId. It requires admin permissions and returns appropriate status codes based on the result of the operation.
   * Delete a project
   * @param projectId ID of the project to delete
   */
  public async deleteProject(projectId: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.deleteProjectWithHttpInfo(projectId, _options);
    return responseWithInfo.data;
  }


}


import { DeleteProjectRestoreAccountApiRequestFactory, DeleteProjectRestoreAccountApiResponseProcessor} from "../apis/DeleteProjectRestoreAccountApi";

export class PromiseDeleteProjectRestoreAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DeleteProjectRestoreAccountApiRequestFactory = new DeleteProjectRestoreAccountApiRequestFactory(configuration),
    private responseProcessor: DeleteProjectRestoreAccountApiResponseProcessor = new DeleteProjectRestoreAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This operation deletes a specified restore account associated with a given project.
   * Delete a restore account
   * @param accountId ID of the restore account to delete
   * @param projectId The project ID
   */
  public async deleteProjectRestoreAccountWithHttpInfo(accountId: string, projectId: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.deleteProjectRestoreAccount(accountId, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.deleteProjectRestoreAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: This operation deletes a specified restore account associated with a given project.
   * Delete a restore account
   * @param accountId ID of the restore account to delete
   * @param projectId The project ID
   */
  public async deleteProjectRestoreAccount(accountId: string, projectId: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.deleteProjectRestoreAccountWithHttpInfo(accountId, projectId, _options);
    return responseWithInfo.data;
  }


}


import { DeleteProjectUserApiRequestFactory, DeleteProjectUserApiResponseProcessor} from "../apis/DeleteProjectUserApi";

export class PromiseDeleteProjectUserApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DeleteProjectUserApiRequestFactory = new DeleteProjectUserApiRequestFactory(configuration),
    private responseProcessor: DeleteProjectUserApiResponseProcessor = new DeleteProjectUserApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API call deletes a user from a specified project. The user ID and project ID must be provided.
   * Delete a user
   * @param id ID of the user to delete
   * @param projectId The project ID
   */
  public async deleteProjectUserWithHttpInfo(id: string, projectId: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.deleteProjectUser(id, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.deleteProjectUserWithHttpInfo(responseContext)
  }

  /**
   * Description: This API call deletes a user from a specified project. The user ID and project ID must be provided.
   * Delete a user
   * @param id ID of the user to delete
   * @param projectId The project ID
   */
  public async deleteProjectUser(id: string, projectId: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.deleteProjectUserWithHttpInfo(id, projectId, _options);
    return responseWithInfo.data;
  }


}


import { DeleteRestoreAccountApiRequestFactory, DeleteRestoreAccountApiResponseProcessor} from "../apis/DeleteRestoreAccountApi";

export class PromiseDeleteRestoreAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DeleteRestoreAccountApiRequestFactory = new DeleteRestoreAccountApiRequestFactory(configuration),
    private responseProcessor: DeleteRestoreAccountApiResponseProcessor = new DeleteRestoreAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API deletes a specified restore account identified by its accountId.
   * Delete a restore account
   * @param accountId ID of the restore account to delete
   */
  public async deleteRestoreAccountWithHttpInfo(accountId: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.deleteRestoreAccount(accountId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.deleteRestoreAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: This API deletes a specified restore account identified by its accountId.
   * Delete a restore account
   * @param accountId ID of the restore account to delete
   */
  public async deleteRestoreAccount(accountId: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.deleteRestoreAccountWithHttpInfo(accountId, _options);
    return responseWithInfo.data;
  }


}


import { DeleteRoleApiRequestFactory, DeleteRoleApiResponseProcessor} from "../apis/DeleteRoleApi";

export class PromiseDeleteRoleApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DeleteRoleApiRequestFactory = new DeleteRoleApiRequestFactory(configuration),
    private responseProcessor: DeleteRoleApiResponseProcessor = new DeleteRoleApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API operation deletes a role using its unique ID. 
   * Delete a role
   * @param id ID of the role
   */
  public async deleteRoleWithHttpInfo(id: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.deleteRole(id, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.deleteRoleWithHttpInfo(responseContext)
  }

  /**
   * Description: This API operation deletes a role using its unique ID. 
   * Delete a role
   * @param id ID of the role
   */
  public async deleteRole(id: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.deleteRoleWithHttpInfo(id, _options);
    return responseWithInfo.data;
  }


}


import { DeleteSamlIdentityProviderConfigApiRequestFactory, DeleteSamlIdentityProviderConfigApiResponseProcessor} from "../apis/DeleteSamlIdentityProviderConfigApi";

export class PromiseDeleteSamlIdentityProviderConfigApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DeleteSamlIdentityProviderConfigApiRequestFactory = new DeleteSamlIdentityProviderConfigApiRequestFactory(configuration),
    private responseProcessor: DeleteSamlIdentityProviderConfigApiResponseProcessor = new DeleteSamlIdentityProviderConfigApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Deletes an existing SAML identity provider configuration by its ID.
   * Delete saml identity provider config
   * @param id The ID of the saml identity provider
   */
  public async deleteSamlIdentityProviderConfigWithHttpInfo(id: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.deleteSamlIdentityProviderConfig(id, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.deleteSamlIdentityProviderConfigWithHttpInfo(responseContext)
  }

  /**
   * Description: Deletes an existing SAML identity provider configuration by its ID.
   * Delete saml identity provider config
   * @param id The ID of the saml identity provider
   */
  public async deleteSamlIdentityProviderConfig(id: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.deleteSamlIdentityProviderConfigWithHttpInfo(id, _options);
    return responseWithInfo.data;
  }


}


import { DeleteScanningAccountApiRequestFactory, DeleteScanningAccountApiResponseProcessor} from "../apis/DeleteScanningAccountApi";

export class PromiseDeleteScanningAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DeleteScanningAccountApiRequestFactory = new DeleteScanningAccountApiRequestFactory(configuration),
    private responseProcessor: DeleteScanningAccountApiResponseProcessor = new DeleteScanningAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint allows the deletion of a scanning account identified by its UUID. Authorization with \'admin:scanning_accounts\' permission is required.
   * Delete a scanning account
   * @param accountId ID of the scanning account to delete
   */
  public async deleteScanningAccountWithHttpInfo(accountId: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.deleteScanningAccount(accountId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.deleteScanningAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint allows the deletion of a scanning account identified by its UUID. Authorization with \'admin:scanning_accounts\' permission is required.
   * Delete a scanning account
   * @param accountId ID of the scanning account to delete
   */
  public async deleteScanningAccount(accountId: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.deleteScanningAccountWithHttpInfo(accountId, _options);
    return responseWithInfo.data;
  }


}


import { DeleteSourceAccountApiRequestFactory, DeleteSourceAccountApiResponseProcessor} from "../apis/DeleteSourceAccountApi";

export class PromiseDeleteSourceAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DeleteSourceAccountApiRequestFactory = new DeleteSourceAccountApiRequestFactory(configuration),
    private responseProcessor: DeleteSourceAccountApiResponseProcessor = new DeleteSourceAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: API endpoint to delete a source account by its unique accountId. Requires admin permissions.
   * Delete a source account
   * @param accountId ID of the source account to delete
   */
  public async deleteSourceAccountWithHttpInfo(accountId: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.deleteSourceAccount(accountId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.deleteSourceAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: API endpoint to delete a source account by its unique accountId. Requires admin permissions.
   * Delete a source account
   * @param accountId ID of the source account to delete
   */
  public async deleteSourceAccount(accountId: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.deleteSourceAccountWithHttpInfo(accountId, _options);
    return responseWithInfo.data;
  }


}


import { DeleteUserApiRequestFactory, DeleteUserApiResponseProcessor} from "../apis/DeleteUserApi";

export class PromiseDeleteUserApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DeleteUserApiRequestFactory = new DeleteUserApiRequestFactory(configuration),
    private responseProcessor: DeleteUserApiResponseProcessor = new DeleteUserApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint allows an admin to delete a user by their ID.
   * Delete a user
   * @param id ID of the user to delete
   */
  public async deleteUserWithHttpInfo(id: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.deleteUser(id, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.deleteUserWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint allows an admin to delete a user by their ID.
   * Delete a user
   * @param id ID of the user to delete
   */
  public async deleteUser(id: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.deleteUserWithHttpInfo(id, _options);
    return responseWithInfo.data;
  }


}


import { DeleteVaultAccountApiRequestFactory, DeleteVaultAccountApiResponseProcessor} from "../apis/DeleteVaultAccountApi";

export class PromiseDeleteVaultAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DeleteVaultAccountApiRequestFactory = new DeleteVaultAccountApiRequestFactory(configuration),
    private responseProcessor: DeleteVaultAccountApiResponseProcessor = new DeleteVaultAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API operation deletes a vault account identified by its accountId. Requires admin permissions on vault accounts.
   * Delete a vault account
   * @param accountId ID of the vault account to delete
   */
  public async deleteVaultAccountWithHttpInfo(accountId: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.deleteVaultAccount(accountId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.deleteVaultAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: This API operation deletes a vault account identified by its accountId. Requires admin permissions on vault accounts.
   * Delete a vault account
   * @param accountId ID of the vault account to delete
   */
  public async deleteVaultAccount(accountId: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.deleteVaultAccountWithHttpInfo(accountId, _options);
    return responseWithInfo.data;
  }


}


import { DiscoveryApiRequestFactory, DiscoveryApiResponseProcessor} from "../apis/DiscoveryApi";

export class PromiseDiscoveryApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DiscoveryApiRequestFactory = new DiscoveryApiRequestFactory(configuration),
    private responseProcessor: DiscoveryApiResponseProcessor = new DiscoveryApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API operation initiates a discovery process for a specific account identified by projectId and accountId.
   * Invokes a discovery process for a specific account
   * @param projectId The project ID
   * @param accountId The account ID
   * @param discoveryInvokeParams The request body for invoking discovery
   */
  public async invokeDiscoverWithHttpInfo(projectId: string, accountId: string, discoveryInvokeParams: DiscoveryInvokeParams, _options?: Configuration): Promise<HttpInfo<Message>> {
    let requestContextValue = await this.requestFactory.invokeDiscover(projectId, accountId, discoveryInvokeParams, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.invokeDiscoverWithHttpInfo(responseContext)
  }

  /**
   * Description: This API operation initiates a discovery process for a specific account identified by projectId and accountId.
   * Invokes a discovery process for a specific account
   * @param projectId The project ID
   * @param accountId The account ID
   * @param discoveryInvokeParams The request body for invoking discovery
   */
  public async invokeDiscover(projectId: string, accountId: string, discoveryInvokeParams: DiscoveryInvokeParams, _options?: Configuration): Promise<Message> {
    const responseWithInfo = await this.invokeDiscoverWithHttpInfo(projectId, accountId, discoveryInvokeParams, _options);
    return responseWithInfo.data;
  }


}


import { DiscoveryStatusApiRequestFactory, DiscoveryStatusApiResponseProcessor} from "../apis/DiscoveryStatusApi";

export class PromiseDiscoveryStatusApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: DiscoveryStatusApiRequestFactory = new DiscoveryStatusApiRequestFactory(configuration),
    private responseProcessor: DiscoveryStatusApiResponseProcessor = new DiscoveryStatusApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieves the current status of the discovery process for a specified project and account.
   * Gets status of discovery process for a project
   * @param projectId The project ID
   * @param accountId The account ID
   */
  public async discoveryStatusWithHttpInfo(projectId: string, accountId: string, _options?: Configuration): Promise<HttpInfo<DiscoveryStatusResponse>> {
    let requestContextValue = await this.requestFactory.discoveryStatus(projectId, accountId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.discoveryStatusWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieves the current status of the discovery process for a specified project and account.
   * Gets status of discovery process for a project
   * @param projectId The project ID
   * @param accountId The account ID
   */
  public async discoveryStatus(projectId: string, accountId: string, _options?: Configuration): Promise<DiscoveryStatusResponse> {
    const responseWithInfo = await this.discoveryStatusWithHttpInfo(projectId, accountId, _options);
    return responseWithInfo.data;
  }


}


import { FileExplorerListPathApiRequestFactory, FileExplorerListPathApiResponseProcessor} from "../apis/FileExplorerListPathApi";

export class PromiseFileExplorerListPathApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: FileExplorerListPathApiRequestFactory = new FileExplorerListPathApiRequestFactory(configuration),
    private responseProcessor: FileExplorerListPathApiResponseProcessor = new FileExplorerListPathApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve a list of all files within a specified directory in the given project and cloud resource, supporting pagination and optional filters.
   * Get all items in a given path
   * @param projectId The project ID
   * @param resourceId The resource ID
   * @param prefix The path prefix of the directory
   * @param pageSize The number of items to return per page
   * @param pageToken The page token for pagination
   */
  public async fileExplorerListPathWithHttpInfo(projectId: string, resourceId: string, prefix?: string, pageSize?: number, pageToken?: string, _options?: Configuration): Promise<HttpInfo<FileExplorerResponse>> {
    let requestContextValue = await this.requestFactory.fileExplorerListPath(projectId, resourceId, prefix, pageSize, pageToken, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.fileExplorerListPathWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve a list of all files within a specified directory in the given project and cloud resource, supporting pagination and optional filters.
   * Get all items in a given path
   * @param projectId The project ID
   * @param resourceId The resource ID
   * @param prefix The path prefix of the directory
   * @param pageSize The number of items to return per page
   * @param pageToken The page token for pagination
   */
  public async fileExplorerListPath(projectId: string, resourceId: string, prefix?: string, pageSize?: number, pageToken?: string, _options?: Configuration): Promise<FileExplorerResponse> {
    const responseWithInfo = await this.fileExplorerListPathWithHttpInfo(projectId, resourceId, prefix, pageSize, pageToken, _options);
    return responseWithInfo.data;
  }


}


import { ForgotPasswordApiRequestFactory, ForgotPasswordApiResponseProcessor} from "../apis/ForgotPasswordApi";

export class PromiseForgotPasswordApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ForgotPasswordApiRequestFactory = new ForgotPasswordApiRequestFactory(configuration),
    private responseProcessor: ForgotPasswordApiResponseProcessor = new ForgotPasswordApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Endpoint to initiate forgot password process for users. It can redirect to the Cognito forgot password endpoint.
   * Forgot Password
   */
  public async forgotPasswordWithHttpInfo(_options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.forgotPassword(_options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.forgotPasswordWithHttpInfo(responseContext)
  }

  /**
   * Description: Endpoint to initiate forgot password process for users. It can redirect to the Cognito forgot password endpoint.
   * Forgot Password
   */
  public async forgotPassword(_options?: Configuration): Promise<void> {
    const responseWithInfo = await this.forgotPasswordWithHttpInfo(_options);
    return responseWithInfo.data;
  }


}


import { GetAccountUIPreferencesApiRequestFactory, GetAccountUIPreferencesApiResponseProcessor} from "../apis/GetAccountUIPreferencesApi";

export class PromiseGetAccountUIPreferencesApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetAccountUIPreferencesApiRequestFactory = new GetAccountUIPreferencesApiRequestFactory(configuration),
    private responseProcessor: GetAccountUIPreferencesApiResponseProcessor = new GetAccountUIPreferencesApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieves the UI preferences for a specific account based on the provided key.
   * Get account UI preferences
   * @param key Key of the preference
   */
  public async getAccountUIPreferencesWithHttpInfo(key: string, _options?: Configuration): Promise<HttpInfo<Preferences>> {
    let requestContextValue = await this.requestFactory.getAccountUIPreferences(key, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getAccountUIPreferencesWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieves the UI preferences for a specific account based on the provided key.
   * Get account UI preferences
   * @param key Key of the preference
   */
  public async getAccountUIPreferences(key: string, _options?: Configuration): Promise<Preferences> {
    const responseWithInfo = await this.getAccountUIPreferencesWithHttpInfo(key, _options);
    return responseWithInfo.data;
  }


}


import { GetAccountsApiRequestFactory, GetAccountsApiResponseProcessor} from "../apis/GetAccountsApi";

export class PromiseGetAccountsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetAccountsApiRequestFactory = new GetAccountsApiRequestFactory(configuration),
    private responseProcessor: GetAccountsApiResponseProcessor = new GetAccountsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve detailed information of a specified account using its ID.
   * Get details of an account
   * @param id ID of the account
   */
  public async getAccountWithHttpInfo(id: string, _options?: Configuration): Promise<HttpInfo<EonAccount>> {
    let requestContextValue = await this.requestFactory.getAccount(id, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve detailed information of a specified account using its ID.
   * Get details of an account
   * @param id ID of the account
   */
  public async getAccount(id: string, _options?: Configuration): Promise<EonAccount> {
    const responseWithInfo = await this.getAccountWithHttpInfo(id, _options);
    return responseWithInfo.data;
  }


}


import { GetAzureConsentUrlApiRequestFactory, GetAzureConsentUrlApiResponseProcessor} from "../apis/GetAzureConsentUrlApi";

export class PromiseGetAzureConsentUrlApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetAzureConsentUrlApiRequestFactory = new GetAzureConsentUrlApiRequestFactory(configuration),
    private responseProcessor: GetAzureConsentUrlApiResponseProcessor = new GetAzureConsentUrlApiResponseProcessor() 
  ) {
    
  }

  /**
   * Get Azure consent URL
   * @param projectId The project ID
   * @param requestId 
   */
  public async getAzureConsentUrlWithHttpInfo(projectId: string, requestId: string, _options?: Configuration): Promise<HttpInfo<AzureConsentUrlResponse>> {
    let requestContextValue = await this.requestFactory.getAzureConsentUrl(projectId, requestId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getAzureConsentUrlWithHttpInfo(responseContext)
  }

  /**
   * Get Azure consent URL
   * @param projectId The project ID
   * @param requestId 
   */
  public async getAzureConsentUrl(projectId: string, requestId: string, _options?: Configuration): Promise<AzureConsentUrlResponse> {
    const responseWithInfo = await this.getAzureConsentUrlWithHttpInfo(projectId, requestId, _options);
    return responseWithInfo.data;
  }


}


import { GetAzureOnboardingStatusApiRequestFactory, GetAzureOnboardingStatusApiResponseProcessor} from "../apis/GetAzureOnboardingStatusApi";

export class PromiseGetAzureOnboardingStatusApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetAzureOnboardingStatusApiRequestFactory = new GetAzureOnboardingStatusApiRequestFactory(configuration),
    private responseProcessor: GetAzureOnboardingStatusApiResponseProcessor = new GetAzureOnboardingStatusApiResponseProcessor() 
  ) {
    
  }

  /**
   * Azure subscriptions onboarding status
   * @param projectId The project ID
   * @param requestId 
   */
  public async getAzureOnboardingStatusWithHttpInfo(projectId: string, requestId: string, _options?: Configuration): Promise<HttpInfo<AzureOnboardingStatusResponse>> {
    let requestContextValue = await this.requestFactory.getAzureOnboardingStatus(projectId, requestId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getAzureOnboardingStatusWithHttpInfo(responseContext)
  }

  /**
   * Azure subscriptions onboarding status
   * @param projectId The project ID
   * @param requestId 
   */
  public async getAzureOnboardingStatus(projectId: string, requestId: string, _options?: Configuration): Promise<AzureOnboardingStatusResponse> {
    const responseWithInfo = await this.getAzureOnboardingStatusWithHttpInfo(projectId, requestId, _options);
    return responseWithInfo.data;
  }


}


import { GetAzureSubscriptionsApiRequestFactory, GetAzureSubscriptionsApiResponseProcessor} from "../apis/GetAzureSubscriptionsApi";

export class PromiseGetAzureSubscriptionsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetAzureSubscriptionsApiRequestFactory = new GetAzureSubscriptionsApiRequestFactory(configuration),
    private responseProcessor: GetAzureSubscriptionsApiResponseProcessor = new GetAzureSubscriptionsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Get available Azure subscriptions
   * @param projectId The project ID
   * @param requestId 
   */
  public async getAzureSubscriptionsWithHttpInfo(projectId: string, requestId: string, _options?: Configuration): Promise<HttpInfo<AzureSubscriptions>> {
    let requestContextValue = await this.requestFactory.getAzureSubscriptions(projectId, requestId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getAzureSubscriptionsWithHttpInfo(responseContext)
  }

  /**
   * Get available Azure subscriptions
   * @param projectId The project ID
   * @param requestId 
   */
  public async getAzureSubscriptions(projectId: string, requestId: string, _options?: Configuration): Promise<AzureSubscriptions> {
    const responseWithInfo = await this.getAzureSubscriptionsWithHttpInfo(projectId, requestId, _options);
    return responseWithInfo.data;
  }


}


import { GetBackedUpResourcesApiRequestFactory, GetBackedUpResourcesApiResponseProcessor} from "../apis/GetBackedUpResourcesApi";

export class PromiseGetBackedUpResourcesApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetBackedUpResourcesApiRequestFactory = new GetBackedUpResourcesApiRequestFactory(configuration),
    private responseProcessor: GetBackedUpResourcesApiResponseProcessor = new GetBackedUpResourcesApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetch details of resources that have been backed up for a specified project in the given cloud provider.
   * Get backed up resources
   * @param cloudProvider The cloudProvider
   * @param projectId The project ID
   */
  public async getBackedUpResourcesWithHttpInfo(cloudProvider: string, projectId: string, _options?: Configuration): Promise<HttpInfo<BackedUpByResourceType>> {
    let requestContextValue = await this.requestFactory.getBackedUpResources(cloudProvider, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getBackedUpResourcesWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetch details of resources that have been backed up for a specified project in the given cloud provider.
   * Get backed up resources
   * @param cloudProvider The cloudProvider
   * @param projectId The project ID
   */
  public async getBackedUpResources(cloudProvider: string, projectId: string, _options?: Configuration): Promise<BackedUpByResourceType> {
    const responseWithInfo = await this.getBackedUpResourcesWithHttpInfo(cloudProvider, projectId, _options);
    return responseWithInfo.data;
  }


}


import { GetBackedUpStorageDateHistogramApiRequestFactory, GetBackedUpStorageDateHistogramApiResponseProcessor} from "../apis/GetBackedUpStorageDateHistogramApi";

export class PromiseGetBackedUpStorageDateHistogramApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetBackedUpStorageDateHistogramApiRequestFactory = new GetBackedUpStorageDateHistogramApiRequestFactory(configuration),
    private responseProcessor: GetBackedUpStorageDateHistogramApiResponseProcessor = new GetBackedUpStorageDateHistogramApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API retrieves a histogram of backed-up storage data for a specified project.
   * Get backed up storage date histogram
   * @param projectId The project ID
   */
  public async getBackedUpStorageDateHistogramWithHttpInfo(projectId: string, _options?: Configuration): Promise<HttpInfo<BackedUpStorageDateHistogram>> {
    let requestContextValue = await this.requestFactory.getBackedUpStorageDateHistogram(projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getBackedUpStorageDateHistogramWithHttpInfo(responseContext)
  }

  /**
   * Description: This API retrieves a histogram of backed-up storage data for a specified project.
   * Get backed up storage date histogram
   * @param projectId The project ID
   */
  public async getBackedUpStorageDateHistogram(projectId: string, _options?: Configuration): Promise<BackedUpStorageDateHistogram> {
    const responseWithInfo = await this.getBackedUpStorageDateHistogramWithHttpInfo(projectId, _options);
    return responseWithInfo.data;
  }


}


import { GetBackupControlViolationsApiRequestFactory, GetBackupControlViolationsApiResponseProcessor} from "../apis/GetBackupControlViolationsApi";

export class PromiseGetBackupControlViolationsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetBackupControlViolationsApiRequestFactory = new GetBackupControlViolationsApiRequestFactory(configuration),
    private responseProcessor: GetBackupControlViolationsApiResponseProcessor = new GetBackupControlViolationsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetches violations of backup control policies for a specific project.
   * Get backup control violations
   * @param projectId The project ID
   */
  public async getBackupControlViolationsWithHttpInfo(projectId: string, _options?: Configuration): Promise<HttpInfo<BackupControlViolations>> {
    let requestContextValue = await this.requestFactory.getBackupControlViolations(projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getBackupControlViolationsWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetches violations of backup control policies for a specific project.
   * Get backup control violations
   * @param projectId The project ID
   */
  public async getBackupControlViolations(projectId: string, _options?: Configuration): Promise<BackupControlViolations> {
    const responseWithInfo = await this.getBackupControlViolationsWithHttpInfo(projectId, _options);
    return responseWithInfo.data;
  }


}


import { GetBackupPolicyApiRequestFactory, GetBackupPolicyApiResponseProcessor} from "../apis/GetBackupPolicyApi";

export class PromiseGetBackupPolicyApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetBackupPolicyApiRequestFactory = new GetBackupPolicyApiRequestFactory(configuration),
    private responseProcessor: GetBackupPolicyApiResponseProcessor = new GetBackupPolicyApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieves the details of a specific policy identified by its ID and project ID.
   * Get policy details
   * @param id ID of the policy to retrieve
   * @param projectId The project ID
   */
  public async getPolicyWithHttpInfo(id: string, projectId: string, _options?: Configuration): Promise<HttpInfo<BackupPolicy>> {
    let requestContextValue = await this.requestFactory.getPolicy(id, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getPolicyWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieves the details of a specific policy identified by its ID and project ID.
   * Get policy details
   * @param id ID of the policy to retrieve
   * @param projectId The project ID
   */
  public async getPolicy(id: string, projectId: string, _options?: Configuration): Promise<BackupPolicy> {
    const responseWithInfo = await this.getPolicyWithHttpInfo(id, projectId, _options);
    return responseWithInfo.data;
  }


}


import { GetBackupVaultApiRequestFactory, GetBackupVaultApiResponseProcessor} from "../apis/GetBackupVaultApi";

export class PromiseGetBackupVaultApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetBackupVaultApiRequestFactory = new GetBackupVaultApiRequestFactory(configuration),
    private responseProcessor: GetBackupVaultApiResponseProcessor = new GetBackupVaultApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve detailed information about a specific backup vault using its ID and the project ID.
   * Get details of a backup vault
   * @param id ID of the backup vault to retrieve
   * @param projectId The project ID
   */
  public async getVaultWithHttpInfo(id: string, projectId: string, _options?: Configuration): Promise<HttpInfo<BackupVault>> {
    let requestContextValue = await this.requestFactory.getVault(id, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getVaultWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve detailed information about a specific backup vault using its ID and the project ID.
   * Get details of a backup vault
   * @param id ID of the backup vault to retrieve
   * @param projectId The project ID
   */
  public async getVault(id: string, projectId: string, _options?: Configuration): Promise<BackupVault> {
    const responseWithInfo = await this.getVaultWithHttpInfo(id, projectId, _options);
    return responseWithInfo.data;
  }


}


import { GetControlApiRequestFactory, GetControlApiResponseProcessor} from "../apis/GetControlApi";

export class PromiseGetControlApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetControlApiRequestFactory = new GetControlApiRequestFactory(configuration),
    private responseProcessor: GetControlApiResponseProcessor = new GetControlApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve details of a specific control by its ID within a given project.
   * Get control details
   * @param id ID of the control to retrieve
   * @param projectId The project ID
   */
  public async getControlWithHttpInfo(id: string, projectId: string, _options?: Configuration): Promise<HttpInfo<Control>> {
    let requestContextValue = await this.requestFactory.getControl(id, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getControlWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve details of a specific control by its ID within a given project.
   * Get control details
   * @param id ID of the control to retrieve
   * @param projectId The project ID
   */
  public async getControl(id: string, projectId: string, _options?: Configuration): Promise<Control> {
    const responseWithInfo = await this.getControlWithHttpInfo(id, projectId, _options);
    return responseWithInfo.data;
  }


}


import { GetDashboardHomeDriftProtectionApiRequestFactory, GetDashboardHomeDriftProtectionApiResponseProcessor} from "../apis/GetDashboardHomeDriftProtectionApi";

export class PromiseGetDashboardHomeDriftProtectionApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetDashboardHomeDriftProtectionApiRequestFactory = new GetDashboardHomeDriftProtectionApiRequestFactory(configuration),
    private responseProcessor: GetDashboardHomeDriftProtectionApiResponseProcessor = new GetDashboardHomeDriftProtectionApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetches drift protection summary data for the specified project\'s dashboard home page, providing insights into drift status and protections.
   * Get the Drift protection data for the dashboard home page
   * @param projectId The project ID
   */
  public async getDashboardHomeDriftProtectionWithHttpInfo(projectId: string, _options?: Configuration): Promise<HttpInfo<DriftProtectionSummary>> {
    let requestContextValue = await this.requestFactory.getDashboardHomeDriftProtection(projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getDashboardHomeDriftProtectionWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetches drift protection summary data for the specified project\'s dashboard home page, providing insights into drift status and protections.
   * Get the Drift protection data for the dashboard home page
   * @param projectId The project ID
   */
  public async getDashboardHomeDriftProtection(projectId: string, _options?: Configuration): Promise<DriftProtectionSummary> {
    const responseWithInfo = await this.getDashboardHomeDriftProtectionWithHttpInfo(projectId, _options);
    return responseWithInfo.data;
  }


}


import { GetDatabasesByServerApiRequestFactory, GetDatabasesByServerApiResponseProcessor} from "../apis/GetDatabasesByServerApi";

export class PromiseGetDatabasesByServerApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetDatabasesByServerApiRequestFactory = new GetDatabasesByServerApiRequestFactory(configuration),
    private responseProcessor: GetDatabasesByServerApiResponseProcessor = new GetDatabasesByServerApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API fetches all databases associated with a specified server and snapshot within a project.
   * Get all databases for a given server and snapshot
   * @param serverId ID of the database server
   * @param snapshotId ID of the snapshot
   * @param projectId The project ID
   */
  public async getDatabasesByServerWithHttpInfo(serverId: string, snapshotId: string, projectId: string, _options?: Configuration): Promise<HttpInfo<DBServerRecord>> {
    let requestContextValue = await this.requestFactory.getDatabasesByServer(serverId, snapshotId, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getDatabasesByServerWithHttpInfo(responseContext)
  }

  /**
   * Description: This API fetches all databases associated with a specified server and snapshot within a project.
   * Get all databases for a given server and snapshot
   * @param serverId ID of the database server
   * @param snapshotId ID of the snapshot
   * @param projectId The project ID
   */
  public async getDatabasesByServer(serverId: string, snapshotId: string, projectId: string, _options?: Configuration): Promise<DBServerRecord> {
    const responseWithInfo = await this.getDatabasesByServerWithHttpInfo(serverId, snapshotId, projectId, _options);
    return responseWithInfo.data;
  }


}


import { GetDocsApiRequestFactory, GetDocsApiResponseProcessor} from "../apis/GetDocsApi";

export class PromiseGetDocsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetDocsApiRequestFactory = new GetDocsApiRequestFactory(configuration),
    private responseProcessor: GetDocsApiResponseProcessor = new GetDocsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetches the requested document given its path.
   * Get the requested document
   */
  public async getDocsWithHttpInfo(_options?: Configuration): Promise<HttpInfo<string>> {
    let requestContextValue = await this.requestFactory.getDocs(_options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getDocsWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetches the requested document given its path.
   * Get the requested document
   */
  public async getDocs(_options?: Configuration): Promise<string> {
    const responseWithInfo = await this.getDocsWithHttpInfo(_options);
    return responseWithInfo.data;
  }


}


import { GetIntegrationApiRequestFactory, GetIntegrationApiResponseProcessor} from "../apis/GetIntegrationApi";

export class PromiseGetIntegrationApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetIntegrationApiRequestFactory = new GetIntegrationApiRequestFactory(configuration),
    private responseProcessor: GetIntegrationApiResponseProcessor = new GetIntegrationApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetches detailed information about an integration given their unique ID.
   * Get details of an integration
   * @param clientId Client ID of the integration to fetch
   */
  public async getIntegrationWithHttpInfo(clientId: string, _options?: Configuration): Promise<HttpInfo<Integration>> {
    let requestContextValue = await this.requestFactory.getIntegration(clientId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getIntegrationWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetches detailed information about an integration given their unique ID.
   * Get details of an integration
   * @param clientId Client ID of the integration to fetch
   */
  public async getIntegration(clientId: string, _options?: Configuration): Promise<Integration> {
    const responseWithInfo = await this.getIntegrationWithHttpInfo(clientId, _options);
    return responseWithInfo.data;
  }


}


import { GetProjectApiRequestFactory, GetProjectApiResponseProcessor} from "../apis/GetProjectApi";

export class PromiseGetProjectApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetProjectApiRequestFactory = new GetProjectApiRequestFactory(configuration),
    private responseProcessor: GetProjectApiResponseProcessor = new GetProjectApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve detailed information about a specific project using its unique project ID.
   * Get details of a project
   * @param projectId ID of the project
   */
  public async getProjectWithHttpInfo(projectId: string, _options?: Configuration): Promise<HttpInfo<Project>> {
    let requestContextValue = await this.requestFactory.getProject(projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getProjectWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve detailed information about a specific project using its unique project ID.
   * Get details of a project
   * @param projectId ID of the project
   */
  public async getProject(projectId: string, _options?: Configuration): Promise<Project> {
    const responseWithInfo = await this.getProjectWithHttpInfo(projectId, _options);
    return responseWithInfo.data;
  }


}


import { GetProjectRestoreAccountApiRequestFactory, GetProjectRestoreAccountApiResponseProcessor} from "../apis/GetProjectRestoreAccountApi";

export class PromiseGetProjectRestoreAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetProjectRestoreAccountApiRequestFactory = new GetProjectRestoreAccountApiRequestFactory(configuration),
    private responseProcessor: GetProjectRestoreAccountApiResponseProcessor = new GetProjectRestoreAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve the details of a specific restore account using its account ID and project ID.
   * Get details of a restore account
   * @param accountId ID of the restore account
   * @param projectId The project ID
   */
  public async getProjectRestoreAccountWithHttpInfo(accountId: string, projectId: string, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.getProjectRestoreAccount(accountId, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getProjectRestoreAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve the details of a specific restore account using its account ID and project ID.
   * Get details of a restore account
   * @param accountId ID of the restore account
   * @param projectId The project ID
   */
  public async getProjectRestoreAccount(accountId: string, projectId: string, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.getProjectRestoreAccountWithHttpInfo(accountId, projectId, _options);
    return responseWithInfo.data;
  }


}


import { GetProjectScanningAccountApiRequestFactory, GetProjectScanningAccountApiResponseProcessor} from "../apis/GetProjectScanningAccountApi";

export class PromiseGetProjectScanningAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetProjectScanningAccountApiRequestFactory = new GetProjectScanningAccountApiRequestFactory(configuration),
    private responseProcessor: GetProjectScanningAccountApiResponseProcessor = new GetProjectScanningAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetch detailed information about a specified scanning account within a particular project.
   * Get details of a scanning account
   * @param accountId ID of the scanning account
   * @param projectId The project ID
   */
  public async getProjectScanningAccountWithHttpInfo(accountId: string, projectId: string, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.getProjectScanningAccount(accountId, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getProjectScanningAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetch detailed information about a specified scanning account within a particular project.
   * Get details of a scanning account
   * @param accountId ID of the scanning account
   * @param projectId The project ID
   */
  public async getProjectScanningAccount(accountId: string, projectId: string, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.getProjectScanningAccountWithHttpInfo(accountId, projectId, _options);
    return responseWithInfo.data;
  }


}


import { GetProjectSourceAccountApiRequestFactory, GetProjectSourceAccountApiResponseProcessor} from "../apis/GetProjectSourceAccountApi";

export class PromiseGetProjectSourceAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetProjectSourceAccountApiRequestFactory = new GetProjectSourceAccountApiRequestFactory(configuration),
    private responseProcessor: GetProjectSourceAccountApiResponseProcessor = new GetProjectSourceAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve the specified source account details for a given project using accountId and projectId.
   * Get details of a source account
   * @param accountId ID of the source account
   * @param projectId The project ID
   */
  public async getProjectSourceAccountWithHttpInfo(accountId: string, projectId: string, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.getProjectSourceAccount(accountId, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getProjectSourceAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve the specified source account details for a given project using accountId and projectId.
   * Get details of a source account
   * @param accountId ID of the source account
   * @param projectId The project ID
   */
  public async getProjectSourceAccount(accountId: string, projectId: string, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.getProjectSourceAccountWithHttpInfo(accountId, projectId, _options);
    return responseWithInfo.data;
  }


}


import { GetProjectUserApiRequestFactory, GetProjectUserApiResponseProcessor} from "../apis/GetProjectUserApi";

export class PromiseGetProjectUserApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetProjectUserApiRequestFactory = new GetProjectUserApiRequestFactory(configuration),
    private responseProcessor: GetProjectUserApiResponseProcessor = new GetProjectUserApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetch specific user details within a given project using user ID and project ID.
   * Get details of a user
   * @param id ID of the user
   * @param projectId The project ID
   */
  public async getProjectUserWithHttpInfo(id: string, projectId: string, _options?: Configuration): Promise<HttpInfo<User>> {
    let requestContextValue = await this.requestFactory.getProjectUser(id, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getProjectUserWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetch specific user details within a given project using user ID and project ID.
   * Get details of a user
   * @param id ID of the user
   * @param projectId The project ID
   */
  public async getProjectUser(id: string, projectId: string, _options?: Configuration): Promise<User> {
    const responseWithInfo = await this.getProjectUserWithHttpInfo(id, projectId, _options);
    return responseWithInfo.data;
  }


}


import { GetProjectVaultAccountApiRequestFactory, GetProjectVaultAccountApiResponseProcessor} from "../apis/GetProjectVaultAccountApi";

export class PromiseGetProjectVaultAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetProjectVaultAccountApiRequestFactory = new GetProjectVaultAccountApiRequestFactory(configuration),
    private responseProcessor: GetProjectVaultAccountApiResponseProcessor = new GetProjectVaultAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieves information about a specific vault account within a project using account and project IDs.
   * Get details of a vault account
   * @param accountId ID of the vault account
   * @param projectId The project ID
   */
  public async getProjectVaultAccountWithHttpInfo(accountId: string, projectId: string, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.getProjectVaultAccount(accountId, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getProjectVaultAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieves information about a specific vault account within a project using account and project IDs.
   * Get details of a vault account
   * @param accountId ID of the vault account
   * @param projectId The project ID
   */
  public async getProjectVaultAccount(accountId: string, projectId: string, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.getProjectVaultAccountWithHttpInfo(accountId, projectId, _options);
    return responseWithInfo.data;
  }


}


import { GetRestoreAccountApiRequestFactory, GetRestoreAccountApiResponseProcessor} from "../apis/GetRestoreAccountApi";

export class PromiseGetRestoreAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetRestoreAccountApiRequestFactory = new GetRestoreAccountApiRequestFactory(configuration),
    private responseProcessor: GetRestoreAccountApiResponseProcessor = new GetRestoreAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieves the information of a specific restore account using its accountId.
   * Get details of a restore account
   * @param accountId ID of the restore account
   */
  public async getRestoreAccountWithHttpInfo(accountId: string, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.getRestoreAccount(accountId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getRestoreAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieves the information of a specific restore account using its accountId.
   * Get details of a restore account
   * @param accountId ID of the restore account
   */
  public async getRestoreAccount(accountId: string, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.getRestoreAccountWithHttpInfo(accountId, _options);
    return responseWithInfo.data;
  }


}


import { GetRoleApiRequestFactory, GetRoleApiResponseProcessor} from "../apis/GetRoleApi";

export class PromiseGetRoleApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetRoleApiRequestFactory = new GetRoleApiRequestFactory(configuration),
    private responseProcessor: GetRoleApiResponseProcessor = new GetRoleApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetches specific details of a role using its unique ID.
   * Get details of a role
   * @param id ID of the role
   */
  public async getRoleWithHttpInfo(id: string, _options?: Configuration): Promise<HttpInfo<Role>> {
    let requestContextValue = await this.requestFactory.getRole(id, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getRoleWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetches specific details of a role using its unique ID.
   * Get details of a role
   * @param id ID of the role
   */
  public async getRole(id: string, _options?: Configuration): Promise<Role> {
    const responseWithInfo = await this.getRoleWithHttpInfo(id, _options);
    return responseWithInfo.data;
  }


}


import { GetSamlIdentityProviderConfigApiRequestFactory, GetSamlIdentityProviderConfigApiResponseProcessor} from "../apis/GetSamlIdentityProviderConfigApi";

export class PromiseGetSamlIdentityProviderConfigApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetSamlIdentityProviderConfigApiRequestFactory = new GetSamlIdentityProviderConfigApiRequestFactory(configuration),
    private responseProcessor: GetSamlIdentityProviderConfigApiResponseProcessor = new GetSamlIdentityProviderConfigApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetches the configuration details for a specified SAML identity provider by its ID.
   * Get saml identity provider config
   * @param id The ID of the saml identity provider
   */
  public async getSamlIdentityProviderConfigWithHttpInfo(id: string, _options?: Configuration): Promise<HttpInfo<SamlIdentityProvider>> {
    let requestContextValue = await this.requestFactory.getSamlIdentityProviderConfig(id, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getSamlIdentityProviderConfigWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetches the configuration details for a specified SAML identity provider by its ID.
   * Get saml identity provider config
   * @param id The ID of the saml identity provider
   */
  public async getSamlIdentityProviderConfig(id: string, _options?: Configuration): Promise<SamlIdentityProvider> {
    const responseWithInfo = await this.getSamlIdentityProviderConfigWithHttpInfo(id, _options);
    return responseWithInfo.data;
  }


}


import { GetScanningAccountApiRequestFactory, GetScanningAccountApiResponseProcessor} from "../apis/GetScanningAccountApi";

export class PromiseGetScanningAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetScanningAccountApiRequestFactory = new GetScanningAccountApiRequestFactory(configuration),
    private responseProcessor: GetScanningAccountApiResponseProcessor = new GetScanningAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve detailed information about a specific scanning account using its account ID.
   * Get details of a scanning account
   * @param accountId ID of the scanning account
   */
  public async getScanningAccountWithHttpInfo(accountId: string, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.getScanningAccount(accountId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getScanningAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve detailed information about a specific scanning account using its account ID.
   * Get details of a scanning account
   * @param accountId ID of the scanning account
   */
  public async getScanningAccount(accountId: string, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.getScanningAccountWithHttpInfo(accountId, _options);
    return responseWithInfo.data;
  }


}


import { GetServiceProviderDetailsApiRequestFactory, GetServiceProviderDetailsApiResponseProcessor} from "../apis/GetServiceProviderDetailsApi";

export class PromiseGetServiceProviderDetailsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetServiceProviderDetailsApiRequestFactory = new GetServiceProviderDetailsApiRequestFactory(configuration),
    private responseProcessor: GetServiceProviderDetailsApiResponseProcessor = new GetServiceProviderDetailsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetches details of a specified service provider, including options to filter by provider name.
   * Get service provider details
   * @param providerName The name of the identity provider
   */
  public async getServiceProviderDetailsWithHttpInfo(providerName?: string, _options?: Configuration): Promise<HttpInfo<ServiceProviderDetails>> {
    let requestContextValue = await this.requestFactory.getServiceProviderDetails(providerName, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getServiceProviderDetailsWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetches details of a specified service provider, including options to filter by provider name.
   * Get service provider details
   * @param providerName The name of the identity provider
   */
  public async getServiceProviderDetails(providerName?: string, _options?: Configuration): Promise<ServiceProviderDetails> {
    const responseWithInfo = await this.getServiceProviderDetailsWithHttpInfo(providerName, _options);
    return responseWithInfo.data;
  }


}


import { GetSnapshotsByServerApiRequestFactory, GetSnapshotsByServerApiResponseProcessor} from "../apis/GetSnapshotsByServerApi";

export class PromiseGetSnapshotsByServerApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetSnapshotsByServerApiRequestFactory = new GetSnapshotsByServerApiRequestFactory(configuration),
    private responseProcessor: GetSnapshotsByServerApiResponseProcessor = new GetSnapshotsByServerApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API operation retrieves all snapshots associated with a specific database server within a given project. It requires the server ID, project ID, start date, and end date.
   * Get all snapshots for a database server
   * @param serverId ID of the database server
   * @param start The start date for the query
   * @param end The end date for the query
   * @param projectId The project ID
   */
  public async getSnapshotsByServerWithHttpInfo(serverId: string, start: Date, end: Date, projectId: string, _options?: Configuration): Promise<HttpInfo<ListDatabaseSnapshotsResponse>> {
    let requestContextValue = await this.requestFactory.getSnapshotsByServer(serverId, start, end, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getSnapshotsByServerWithHttpInfo(responseContext)
  }

  /**
   * Description: This API operation retrieves all snapshots associated with a specific database server within a given project. It requires the server ID, project ID, start date, and end date.
   * Get all snapshots for a database server
   * @param serverId ID of the database server
   * @param start The start date for the query
   * @param end The end date for the query
   * @param projectId The project ID
   */
  public async getSnapshotsByServer(serverId: string, start: Date, end: Date, projectId: string, _options?: Configuration): Promise<ListDatabaseSnapshotsResponse> {
    const responseWithInfo = await this.getSnapshotsByServerWithHttpInfo(serverId, start, end, projectId, _options);
    return responseWithInfo.data;
  }


}


import { GetSourceAccountApiRequestFactory, GetSourceAccountApiResponseProcessor} from "../apis/GetSourceAccountApi";

export class PromiseGetSourceAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetSourceAccountApiRequestFactory = new GetSourceAccountApiRequestFactory(configuration),
    private responseProcessor: GetSourceAccountApiResponseProcessor = new GetSourceAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetches detailed information for a specific source account identified by its account ID.
   * Get details of a source account
   * @param accountId ID of the source account
   */
  public async getSourceAccountWithHttpInfo(accountId: string, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.getSourceAccount(accountId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getSourceAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetches detailed information for a specific source account identified by its account ID.
   * Get details of a source account
   * @param accountId ID of the source account
   */
  public async getSourceAccount(accountId: string, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.getSourceAccountWithHttpInfo(accountId, _options);
    return responseWithInfo.data;
  }


}


import { GetSourceStorageBackupStatusApiRequestFactory, GetSourceStorageBackupStatusApiResponseProcessor} from "../apis/GetSourceStorageBackupStatusApi";

export class PromiseGetSourceStorageBackupStatusApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetSourceStorageBackupStatusApiRequestFactory = new GetSourceStorageBackupStatusApiRequestFactory(configuration),
    private responseProcessor: GetSourceStorageBackupStatusApiResponseProcessor = new GetSourceStorageBackupStatusApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetch the backup status of source storage for a specified project.
   * Get source storage backup status
   * @param projectId The project ID
   */
  public async getSourceStorageBackupStatusWithHttpInfo(projectId: string, _options?: Configuration): Promise<HttpInfo<SourceStorageBackupStatus>> {
    let requestContextValue = await this.requestFactory.getSourceStorageBackupStatus(projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getSourceStorageBackupStatusWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetch the backup status of source storage for a specified project.
   * Get source storage backup status
   * @param projectId The project ID
   */
  public async getSourceStorageBackupStatus(projectId: string, _options?: Configuration): Promise<SourceStorageBackupStatus> {
    const responseWithInfo = await this.getSourceStorageBackupStatusWithHttpInfo(projectId, _options);
    return responseWithInfo.data;
  }


}


import { GetUserApiRequestFactory, GetUserApiResponseProcessor} from "../apis/GetUserApi";

export class PromiseGetUserApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetUserApiRequestFactory = new GetUserApiRequestFactory(configuration),
    private responseProcessor: GetUserApiResponseProcessor = new GetUserApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetches detailed information about a user given their unique ID.
   * Get details of a user
   * @param id ID of the user
   */
  public async getUserWithHttpInfo(id: string, _options?: Configuration): Promise<HttpInfo<User>> {
    let requestContextValue = await this.requestFactory.getUser(id, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getUserWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetches detailed information about a user given their unique ID.
   * Get details of a user
   * @param id ID of the user
   */
  public async getUser(id: string, _options?: Configuration): Promise<User> {
    const responseWithInfo = await this.getUserWithHttpInfo(id, _options);
    return responseWithInfo.data;
  }


}


import { GetVaultAccountApiRequestFactory, GetVaultAccountApiResponseProcessor} from "../apis/GetVaultAccountApi";

export class PromiseGetVaultAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetVaultAccountApiRequestFactory = new GetVaultAccountApiRequestFactory(configuration),
    private responseProcessor: GetVaultAccountApiResponseProcessor = new GetVaultAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetch details of a vault account by providing the account ID. This operation requires administrative permissions and returns account information if the account exists.
   * Get details of a vault account
   * @param accountId ID of the vault account
   */
  public async getVaultAccountWithHttpInfo(accountId: string, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.getVaultAccount(accountId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getVaultAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetch details of a vault account by providing the account ID. This operation requires administrative permissions and returns account information if the account exists.
   * Get details of a vault account
   * @param accountId ID of the vault account
   */
  public async getVaultAccount(accountId: string, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.getVaultAccountWithHttpInfo(accountId, _options);
    return responseWithInfo.data;
  }


}


import { GetViewerApiRequestFactory, GetViewerApiResponseProcessor} from "../apis/GetViewerApi";

export class PromiseGetViewerApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetViewerApiRequestFactory = new GetViewerApiRequestFactory(configuration),
    private responseProcessor: GetViewerApiResponseProcessor = new GetViewerApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve detailed information about the viewer.
   * Get viewer info
   */
  public async getViewerWithHttpInfo(_options?: Configuration): Promise<HttpInfo<Viewer>> {
    let requestContextValue = await this.requestFactory.getViewer(_options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getViewerWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve detailed information about the viewer.
   * Get viewer info
   */
  public async getViewer(_options?: Configuration): Promise<Viewer> {
    const responseWithInfo = await this.getViewerWithHttpInfo(_options);
    return responseWithInfo.data;
  }


}


import { GetViewerUIPreferencesApiRequestFactory, GetViewerUIPreferencesApiResponseProcessor} from "../apis/GetViewerUIPreferencesApi";

export class PromiseGetViewerUIPreferencesApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetViewerUIPreferencesApiRequestFactory = new GetViewerUIPreferencesApiRequestFactory(configuration),
    private responseProcessor: GetViewerUIPreferencesApiResponseProcessor = new GetViewerUIPreferencesApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieves the user\'s viewer interface preferences based on the provided key.
   * Get viewer UI preferences.
   * @param key Key of the preference
   */
  public async getViewerUIPreferencesWithHttpInfo(key: string, _options?: Configuration): Promise<HttpInfo<Preferences>> {
    let requestContextValue = await this.requestFactory.getViewerUIPreferences(key, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getViewerUIPreferencesWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieves the user\'s viewer interface preferences based on the provided key.
   * Get viewer UI preferences.
   * @param key Key of the preference
   */
  public async getViewerUIPreferences(key: string, _options?: Configuration): Promise<Preferences> {
    const responseWithInfo = await this.getViewerUIPreferencesWithHttpInfo(key, _options);
    return responseWithInfo.data;
  }


}


import { GetVolumeApiRequestFactory, GetVolumeApiResponseProcessor} from "../apis/GetVolumeApi";

export class PromiseGetVolumeApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: GetVolumeApiRequestFactory = new GetVolumeApiRequestFactory(configuration),
    private responseProcessor: GetVolumeApiResponseProcessor = new GetVolumeApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve detailed information about a specific volume within the given project and inventory.
   * Get a specific volume
   * @param id ID of the inventory item
   * @param projectId The project ID
   * @param volumeId The volume ID
   */
  public async getVolumeWithHttpInfo(id: string, projectId: string, volumeId: string, _options?: Configuration): Promise<HttpInfo<InventoryVolume>> {
    let requestContextValue = await this.requestFactory.getVolume(id, projectId, volumeId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getVolumeWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve detailed information about a specific volume within the given project and inventory.
   * Get a specific volume
   * @param id ID of the inventory item
   * @param projectId The project ID
   * @param volumeId The volume ID
   */
  public async getVolume(id: string, projectId: string, volumeId: string, _options?: Configuration): Promise<InventoryVolume> {
    const responseWithInfo = await this.getVolumeWithHttpInfo(id, projectId, volumeId, _options);
    return responseWithInfo.data;
  }


}


import { InitiateAuthApiRequestFactory, InitiateAuthApiResponseProcessor} from "../apis/InitiateAuthApi";

export class PromiseInitiateAuthApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: InitiateAuthApiRequestFactory = new InitiateAuthApiRequestFactory(configuration),
    private responseProcessor: InitiateAuthApiResponseProcessor = new InitiateAuthApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API operation initiates the authentication process by accepting authentication parameters and returning an authentication response.
   * InitiateAuth
   * @param initAuthInput Auth parameters
   */
  public async initiateAuthWithHttpInfo(initAuthInput: InitAuthInput, _options?: Configuration): Promise<HttpInfo<AuthResponse>> {
    let requestContextValue = await this.requestFactory.initiateAuth(initAuthInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.initiateAuthWithHttpInfo(responseContext)
  }

  /**
   * Description: This API operation initiates the authentication process by accepting authentication parameters and returning an authentication response.
   * InitiateAuth
   * @param initAuthInput Auth parameters
   */
  public async initiateAuth(initAuthInput: InitAuthInput, _options?: Configuration): Promise<AuthResponse> {
    const responseWithInfo = await this.initiateAuthWithHttpInfo(initAuthInput, _options);
    return responseWithInfo.data;
  }


}


import { InventoryPopulateApiRequestFactory, InventoryPopulateApiResponseProcessor} from "../apis/InventoryPopulateApi";

export class PromiseInventoryPopulateApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: InventoryPopulateApiRequestFactory = new InventoryPopulateApiRequestFactory(configuration),
    private responseProcessor: InventoryPopulateApiResponseProcessor = new InventoryPopulateApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API populates test data for a given project based on the specified version number.
   * Populate test data
   * @param version the version number of the data to populate
   * @param projectId The project ID
   */
  public async inventoryPopulateWithHttpInfo(version: number, projectId: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.inventoryPopulate(version, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.inventoryPopulateWithHttpInfo(responseContext)
  }

  /**
   * Description: This API populates test data for a given project based on the specified version number.
   * Populate test data
   * @param version the version number of the data to populate
   * @param projectId The project ID
   */
  public async inventoryPopulate(version: number, projectId: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.inventoryPopulateWithHttpInfo(version, projectId, _options);
    return responseWithInfo.data;
  }


}


import { JobsApiRequestFactory, JobsApiResponseProcessor} from "../apis/JobsApi";

export class PromiseJobsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: JobsApiRequestFactory = new JobsApiRequestFactory(configuration),
    private responseProcessor: JobsApiResponseProcessor = new JobsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API retrieves the details of a specific job by its ID and project ID.
   * Get Job
   * @param projectId The project ID
   * @param id The id of the job
   * @param jobType 
   */
  public async getJobWithHttpInfo(projectId: string, id: string, jobType?: 'restore' | 'backup' | 'scan', _options?: Configuration): Promise<HttpInfo<GetJobResponse>> {
    let requestContextValue = await this.requestFactory.getJob(projectId, id, jobType, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getJobWithHttpInfo(responseContext)
  }

  /**
   * Description: This API retrieves the details of a specific job by its ID and project ID.
   * Get Job
   * @param projectId The project ID
   * @param id The id of the job
   * @param jobType 
   */
  public async getJob(projectId: string, id: string, jobType?: 'restore' | 'backup' | 'scan', _options?: Configuration): Promise<GetJobResponse> {
    const responseWithInfo = await this.getJobWithHttpInfo(projectId, id, jobType, _options);
    return responseWithInfo.data;
  }

  /**
   * Description: Fetches all jobs for a given project ID, such as restore, scan, or backup jobs, with pagination and sorting options.
   * List Jobs of Type
   * @param projectId The project ID
   * @param pageToken The token for the next page
   * @param pageSize The numbers of items per page
   * @param listJobsRequest The request body for filtering jobs
   */
  public async listJobsOfTypeWithHttpInfo(projectId: string, pageToken?: string, pageSize?: number, listJobsRequest?: ListJobsRequest, _options?: Configuration): Promise<HttpInfo<ListJobsResponse>> {
    let requestContextValue = await this.requestFactory.listJobsOfType(projectId, pageToken, pageSize, listJobsRequest, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listJobsOfTypeWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetches all jobs for a given project ID, such as restore, scan, or backup jobs, with pagination and sorting options.
   * List Jobs of Type
   * @param projectId The project ID
   * @param pageToken The token for the next page
   * @param pageSize The numbers of items per page
   * @param listJobsRequest The request body for filtering jobs
   */
  public async listJobsOfType(projectId: string, pageToken?: string, pageSize?: number, listJobsRequest?: ListJobsRequest, _options?: Configuration): Promise<ListJobsResponse> {
    const responseWithInfo = await this.listJobsOfTypeWithHttpInfo(projectId, pageToken, pageSize, listJobsRequest, _options);
    return responseWithInfo.data;
  }


}


import { ListAccountsApiRequestFactory, ListAccountsApiResponseProcessor} from "../apis/ListAccountsApi";

export class PromiseListAccountsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListAccountsApiRequestFactory = new ListAccountsApiRequestFactory(configuration),
    private responseProcessor: ListAccountsApiResponseProcessor = new ListAccountsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve a list of all customer accounts.
   * Get all accounts
   */
  public async listAccountsWithHttpInfo(_options?: Configuration): Promise<HttpInfo<AccountsResponse>> {
    let requestContextValue = await this.requestFactory.listAccounts(_options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listAccountsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve a list of all customer accounts.
   * Get all accounts
   */
  public async listAccounts(_options?: Configuration): Promise<AccountsResponse> {
    const responseWithInfo = await this.listAccountsWithHttpInfo(_options);
    return responseWithInfo.data;
  }


}


import { ListApplicationOverridesApiRequestFactory, ListApplicationOverridesApiResponseProcessor} from "../apis/ListApplicationOverridesApi";

export class PromiseListApplicationOverridesApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListApplicationOverridesApiRequestFactory = new ListApplicationOverridesApiRequestFactory(configuration),
    private responseProcessor: ListApplicationOverridesApiResponseProcessor = new ListApplicationOverridesApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve the application-specific overrides configured for a specified inventory item within a given project.
   * Get application overrides for an inventory item
   * @param id ID of the inventory item
   * @param projectId The project ID
   */
  public async listApplicationOverridesWithHttpInfo(id: string, projectId: string, _options?: Configuration): Promise<HttpInfo<ListApplicationOverrideResponse>> {
    let requestContextValue = await this.requestFactory.listApplicationOverrides(id, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listApplicationOverridesWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve the application-specific overrides configured for a specified inventory item within a given project.
   * Get application overrides for an inventory item
   * @param id ID of the inventory item
   * @param projectId The project ID
   */
  public async listApplicationOverrides(id: string, projectId: string, _options?: Configuration): Promise<ListApplicationOverrideResponse> {
    const responseWithInfo = await this.listApplicationOverridesWithHttpInfo(id, projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListAtlasProjectsApiRequestFactory, ListAtlasProjectsApiResponseProcessor} from "../apis/ListAtlasProjectsApi";

export class PromiseListAtlasProjectsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListAtlasProjectsApiRequestFactory = new ListAtlasProjectsApiRequestFactory(configuration),
    private responseProcessor: ListAtlasProjectsApiResponseProcessor = new ListAtlasProjectsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve all Atlas projects associated with a specific restore account and project.
   * Gets restore information for account
   * @param accountId ID of the restore account
   * @param projectId The project ID
   */
  public async listAtlasProjectsWithHttpInfo(accountId: string, projectId: string, _options?: Configuration): Promise<HttpInfo<AtlasProjects>> {
    let requestContextValue = await this.requestFactory.listAtlasProjects(accountId, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listAtlasProjectsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve all Atlas projects associated with a specific restore account and project.
   * Gets restore information for account
   * @param accountId ID of the restore account
   * @param projectId The project ID
   */
  public async listAtlasProjects(accountId: string, projectId: string, _options?: Configuration): Promise<AtlasProjects> {
    const responseWithInfo = await this.listAtlasProjectsWithHttpInfo(accountId, projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListAuditLogsApiRequestFactory, ListAuditLogsApiResponseProcessor} from "../apis/ListAuditLogsApi";

export class PromiseListAuditLogsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListAuditLogsApiRequestFactory = new ListAuditLogsApiRequestFactory(configuration),
    private responseProcessor: ListAuditLogsApiResponseProcessor = new ListAuditLogsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve a paginated list of audit logs with optional sorting and filtering capabilities.
   * Get all audit logs
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param sort The sort order, field:direction
   * @param filter The filter to apply
   */
  public async listAuditLogsOldWithHttpInfo(pageIndex?: number, pageSize?: number, sort?: string, filter?: string, _options?: Configuration): Promise<HttpInfo<ListAuditLogsResponse>> {
    let requestContextValue = await this.requestFactory.listAuditLogsOld(pageIndex, pageSize, sort, filter, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listAuditLogsOldWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve a paginated list of audit logs with optional sorting and filtering capabilities.
   * Get all audit logs
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param sort The sort order, field:direction
   * @param filter The filter to apply
   */
  public async listAuditLogsOld(pageIndex?: number, pageSize?: number, sort?: string, filter?: string, _options?: Configuration): Promise<ListAuditLogsResponse> {
    const responseWithInfo = await this.listAuditLogsOldWithHttpInfo(pageIndex, pageSize, sort, filter, _options);
    return responseWithInfo.data;
  }


}


import { ListAvailabilityZonesApiRequestFactory, ListAvailabilityZonesApiResponseProcessor} from "../apis/ListAvailabilityZonesApi";

export class PromiseListAvailabilityZonesApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListAvailabilityZonesApiRequestFactory = new ListAvailabilityZonesApiRequestFactory(configuration),
    private responseProcessor: ListAvailabilityZonesApiResponseProcessor = new ListAvailabilityZonesApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API retrieves the available restore zones for a specified account in a particular region and project.
   * Gets restore information for account
   * @param accountId ID of the restore account
   * @param region The region to get availability zones for
   * @param projectId The project ID
   */
  public async listAvailabilityZonesWithHttpInfo(accountId: string, region: string, projectId: string, _options?: Configuration): Promise<HttpInfo<RestoreAvailabilityZones>> {
    let requestContextValue = await this.requestFactory.listAvailabilityZones(accountId, region, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listAvailabilityZonesWithHttpInfo(responseContext)
  }

  /**
   * Description: This API retrieves the available restore zones for a specified account in a particular region and project.
   * Gets restore information for account
   * @param accountId ID of the restore account
   * @param region The region to get availability zones for
   * @param projectId The project ID
   */
  public async listAvailabilityZones(accountId: string, region: string, projectId: string, _options?: Configuration): Promise<RestoreAvailabilityZones> {
    const responseWithInfo = await this.listAvailabilityZonesWithHttpInfo(accountId, region, projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListAwsInstanceProfilesApiRequestFactory, ListAwsInstanceProfilesApiResponseProcessor} from "../apis/ListAwsInstanceProfilesApi";

export class PromiseListAwsInstanceProfilesApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListAwsInstanceProfilesApiRequestFactory = new ListAwsInstanceProfilesApiRequestFactory(configuration),
    private responseProcessor: ListAwsInstanceProfilesApiResponseProcessor = new ListAwsInstanceProfilesApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve instance profiles information for a specific restore account.
   * Gets restore information for account
   * @param accountId ID of the restore account
   * @param region The region to connect to
   * @param projectId The project ID
   */
  public async listAwsInstanceProfilesWithHttpInfo(accountId: string, region: string, projectId: string, _options?: Configuration): Promise<HttpInfo<ListInstanceProfilesResponse>> {
    let requestContextValue = await this.requestFactory.listAwsInstanceProfiles(accountId, region, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listAwsInstanceProfilesWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve instance profiles information for a specific restore account.
   * Gets restore information for account
   * @param accountId ID of the restore account
   * @param region The region to connect to
   * @param projectId The project ID
   */
  public async listAwsInstanceProfiles(accountId: string, region: string, projectId: string, _options?: Configuration): Promise<ListInstanceProfilesResponse> {
    const responseWithInfo = await this.listAwsInstanceProfilesWithHttpInfo(accountId, region, projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListAwsInstanceTypesApiRequestFactory, ListAwsInstanceTypesApiResponseProcessor} from "../apis/ListAwsInstanceTypesApi";

export class PromiseListAwsInstanceTypesApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListAwsInstanceTypesApiRequestFactory = new ListAwsInstanceTypesApiRequestFactory(configuration),
    private responseProcessor: ListAwsInstanceTypesApiResponseProcessor = new ListAwsInstanceTypesApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve instance types information for a specific restore account in a given project and subnet.
   * Gets restore information for account
   * @param accountId ID of the restore account
   * @param region The region to get instance types for
   * @param projectId The project ID
   * @param subnetId The subnet ID to get instance types for
   */
  public async listAwsInstanceTypesWithHttpInfo(accountId: string, region: string, projectId: string, subnetId?: string, _options?: Configuration): Promise<HttpInfo<ListInstanceTypesResponse>> {
    let requestContextValue = await this.requestFactory.listAwsInstanceTypes(accountId, region, projectId, subnetId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listAwsInstanceTypesWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve instance types information for a specific restore account in a given project and subnet.
   * Gets restore information for account
   * @param accountId ID of the restore account
   * @param region The region to get instance types for
   * @param projectId The project ID
   * @param subnetId The subnet ID to get instance types for
   */
  public async listAwsInstanceTypes(accountId: string, region: string, projectId: string, subnetId?: string, _options?: Configuration): Promise<ListInstanceTypesResponse> {
    const responseWithInfo = await this.listAwsInstanceTypesWithHttpInfo(accountId, region, projectId, subnetId, _options);
    return responseWithInfo.data;
  }


}


import { ListAwsRdsSubnetGroupsApiRequestFactory, ListAwsRdsSubnetGroupsApiResponseProcessor} from "../apis/ListAwsRdsSubnetGroupsApi";

export class PromiseListAwsRdsSubnetGroupsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListAwsRdsSubnetGroupsApiRequestFactory = new ListAwsRdsSubnetGroupsApiRequestFactory(configuration),
    private responseProcessor: ListAwsRdsSubnetGroupsApiResponseProcessor = new ListAwsRdsSubnetGroupsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve RDS subnet groups information for a specific restore account in a given project and region.
   * Gets restore information for account
   * @param accountId ID of the restore account
   * @param region The region to get availability zones for
   * @param projectId The project ID
   */
  public async listAwsRdsSubnetGroupsWithHttpInfo(accountId: string, region: string, projectId: string, _options?: Configuration): Promise<HttpInfo<RestoreRdsSubnetGroups>> {
    let requestContextValue = await this.requestFactory.listAwsRdsSubnetGroups(accountId, region, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listAwsRdsSubnetGroupsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve RDS subnet groups information for a specific restore account in a given project and region.
   * Gets restore information for account
   * @param accountId ID of the restore account
   * @param region The region to get availability zones for
   * @param projectId The project ID
   */
  public async listAwsRdsSubnetGroups(accountId: string, region: string, projectId: string, _options?: Configuration): Promise<RestoreRdsSubnetGroups> {
    const responseWithInfo = await this.listAwsRdsSubnetGroupsWithHttpInfo(accountId, region, projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListAwsSecurityGroupsApiRequestFactory, ListAwsSecurityGroupsApiResponseProcessor} from "../apis/ListAwsSecurityGroupsApi";

export class PromiseListAwsSecurityGroupsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListAwsSecurityGroupsApiRequestFactory = new ListAwsSecurityGroupsApiRequestFactory(configuration),
    private responseProcessor: ListAwsSecurityGroupsApiResponseProcessor = new ListAwsSecurityGroupsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve security group information for a specific restore account in a given project and region.
   * Gets restore information for account
   * @param accountId ID of the restore account
   * @param region The region to get availability zones for
   * @param projectId The project ID
   * @param vpcId Optional VPC ID to filter security groups by
   * @param subnetId Optional subnet ID to filter security groups by
   */
  public async listAwsSecurityGroupsWithHttpInfo(accountId: string, region: string, projectId: string, vpcId?: string, subnetId?: string, _options?: Configuration): Promise<HttpInfo<RestoreSecurityGroups>> {
    let requestContextValue = await this.requestFactory.listAwsSecurityGroups(accountId, region, projectId, vpcId, subnetId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listAwsSecurityGroupsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve security group information for a specific restore account in a given project and region.
   * Gets restore information for account
   * @param accountId ID of the restore account
   * @param region The region to get availability zones for
   * @param projectId The project ID
   * @param vpcId Optional VPC ID to filter security groups by
   * @param subnetId Optional subnet ID to filter security groups by
   */
  public async listAwsSecurityGroups(accountId: string, region: string, projectId: string, vpcId?: string, subnetId?: string, _options?: Configuration): Promise<RestoreSecurityGroups> {
    const responseWithInfo = await this.listAwsSecurityGroupsWithHttpInfo(accountId, region, projectId, vpcId, subnetId, _options);
    return responseWithInfo.data;
  }


}


import { ListAwsSubnetsApiRequestFactory, ListAwsSubnetsApiResponseProcessor} from "../apis/ListAwsSubnetsApi";

export class PromiseListAwsSubnetsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListAwsSubnetsApiRequestFactory = new ListAwsSubnetsApiRequestFactory(configuration),
    private responseProcessor: ListAwsSubnetsApiResponseProcessor = new ListAwsSubnetsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve subnets information for a specific restore account in a given project and region.
   * Gets restore information for account
   * @param accountId ID of the restore account
   * @param region The region to get subnets for
   * @param projectId The project ID
   */
  public async listAwsSubnetsWithHttpInfo(accountId: string, region: string, projectId: string, _options?: Configuration): Promise<HttpInfo<ListSubnetsResponse>> {
    let requestContextValue = await this.requestFactory.listAwsSubnets(accountId, region, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listAwsSubnetsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve subnets information for a specific restore account in a given project and region.
   * Gets restore information for account
   * @param accountId ID of the restore account
   * @param region The region to get subnets for
   * @param projectId The project ID
   */
  public async listAwsSubnets(accountId: string, region: string, projectId: string, _options?: Configuration): Promise<ListSubnetsResponse> {
    const responseWithInfo = await this.listAwsSubnetsWithHttpInfo(accountId, region, projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListBackupPoliciesApiRequestFactory, ListBackupPoliciesApiResponseProcessor} from "../apis/ListBackupPoliciesApi";

export class PromiseListBackupPoliciesApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListBackupPoliciesApiRequestFactory = new ListBackupPoliciesApiRequestFactory(configuration),
    private responseProcessor: ListBackupPoliciesApiResponseProcessor = new ListBackupPoliciesApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieves a list of all backup policies associated with a specified project ID.
   * Get all policies
   * @param projectId The project ID
   */
  public async listPoliciesWithHttpInfo(projectId: string, _options?: Configuration): Promise<HttpInfo<ListBackupPolicyResponse>> {
    let requestContextValue = await this.requestFactory.listPolicies(projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listPoliciesWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieves a list of all backup policies associated with a specified project ID.
   * Get all policies
   * @param projectId The project ID
   */
  public async listPolicies(projectId: string, _options?: Configuration): Promise<ListBackupPolicyResponse> {
    const responseWithInfo = await this.listPoliciesWithHttpInfo(projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListBackupVaultsApiRequestFactory, ListBackupVaultsApiResponseProcessor} from "../apis/ListBackupVaultsApi";

export class PromiseListBackupVaultsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListBackupVaultsApiRequestFactory = new ListBackupVaultsApiRequestFactory(configuration),
    private responseProcessor: ListBackupVaultsApiResponseProcessor = new ListBackupVaultsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve a list of all backup vaults for a specified project.
   * Get all backup vaults
   * @param projectId The project ID
   */
  public async listVaultsWithHttpInfo(projectId: string, _options?: Configuration): Promise<HttpInfo<ListBackupVaultResponse>> {
    let requestContextValue = await this.requestFactory.listVaults(projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listVaultsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve a list of all backup vaults for a specified project.
   * Get all backup vaults
   * @param projectId The project ID
   */
  public async listVaults(projectId: string, _options?: Configuration): Promise<ListBackupVaultResponse> {
    const responseWithInfo = await this.listVaultsWithHttpInfo(projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListControlViolationsApiRequestFactory, ListControlViolationsApiResponseProcessor} from "../apis/ListControlViolationsApi";

export class PromiseListControlViolationsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListControlViolationsApiRequestFactory = new ListControlViolationsApiRequestFactory(configuration),
    private responseProcessor: ListControlViolationsApiResponseProcessor = new ListControlViolationsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API retrieves a list of all control violations for a specific resource identified by project ID and resource ID.
   * List all control violations
   * @param id ID of the resource
   * @param projectId The project ID
   */
  public async listControlViolationsWithHttpInfo(id: string, projectId: string, _options?: Configuration): Promise<HttpInfo<ListControlViolationsResponse>> {
    let requestContextValue = await this.requestFactory.listControlViolations(id, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listControlViolationsWithHttpInfo(responseContext)
  }

  /**
   * Description: This API retrieves a list of all control violations for a specific resource identified by project ID and resource ID.
   * List all control violations
   * @param id ID of the resource
   * @param projectId The project ID
   */
  public async listControlViolations(id: string, projectId: string, _options?: Configuration): Promise<ListControlViolationsResponse> {
    const responseWithInfo = await this.listControlViolationsWithHttpInfo(id, projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListControlsApiRequestFactory, ListControlsApiResponseProcessor} from "../apis/ListControlsApi";

export class PromiseListControlsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListControlsApiRequestFactory = new ListControlsApiRequestFactory(configuration),
    private responseProcessor: ListControlsApiResponseProcessor = new ListControlsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API retrieves a list of controls for a specified project, with options to paginate, sort, and filter the results.
   * List all controls
   * @param projectId The project ID
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param sort The sort order, field:direction
   * @param filter The filter to apply
   */
  public async listControlsWithHttpInfo(projectId: string, pageIndex?: number, pageSize?: number, sort?: string, filter?: string, _options?: Configuration): Promise<HttpInfo<ListControlsResponse>> {
    let requestContextValue = await this.requestFactory.listControls(projectId, pageIndex, pageSize, sort, filter, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listControlsWithHttpInfo(responseContext)
  }

  /**
   * Description: This API retrieves a list of controls for a specified project, with options to paginate, sort, and filter the results.
   * List all controls
   * @param projectId The project ID
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param sort The sort order, field:direction
   * @param filter The filter to apply
   */
  public async listControls(projectId: string, pageIndex?: number, pageSize?: number, sort?: string, filter?: string, _options?: Configuration): Promise<ListControlsResponse> {
    const responseWithInfo = await this.listControlsWithHttpInfo(projectId, pageIndex, pageSize, sort, filter, _options);
    return responseWithInfo.data;
  }


}


import { ListDataClassificationEntitiesApiRequestFactory, ListDataClassificationEntitiesApiResponseProcessor} from "../apis/ListDataClassificationEntitiesApi";

export class PromiseListDataClassificationEntitiesApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListDataClassificationEntitiesApiRequestFactory = new ListDataClassificationEntitiesApiRequestFactory(configuration),
    private responseProcessor: ListDataClassificationEntitiesApiResponseProcessor = new ListDataClassificationEntitiesApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve all data classification entities for a specified project and inventory item.
   * Get all data classification entities
   * @param projectId The project ID
   * @param id ID of the inventory item
   */
  public async listDataClassificationEntitiesWithHttpInfo(projectId: string, id: string, _options?: Configuration): Promise<HttpInfo<ListDataClassificationEntitiesResponse>> {
    let requestContextValue = await this.requestFactory.listDataClassificationEntities(projectId, id, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listDataClassificationEntitiesWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve all data classification entities for a specified project and inventory item.
   * Get all data classification entities
   * @param projectId The project ID
   * @param id ID of the inventory item
   */
  public async listDataClassificationEntities(projectId: string, id: string, _options?: Configuration): Promise<ListDataClassificationEntitiesResponse> {
    const responseWithInfo = await this.listDataClassificationEntitiesWithHttpInfo(projectId, id, _options);
    return responseWithInfo.data;
  }


}


import { ListEncryptionKeysApiRequestFactory, ListEncryptionKeysApiResponseProcessor} from "../apis/ListEncryptionKeysApi";

export class PromiseListEncryptionKeysApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListEncryptionKeysApiRequestFactory = new ListEncryptionKeysApiRequestFactory(configuration),
    private responseProcessor: ListEncryptionKeysApiResponseProcessor = new ListEncryptionKeysApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API retrieves a list of all encryption keys associated with a specific restore account, region, and project. It requires specifying the account ID, region, and project ID as path parameters.
   * Get all encryption keys
   * @param accountId ID of the restore account
   * @param region Region
   * @param projectId The project ID
   */
  public async listEncryptionKeysWithHttpInfo(accountId: string, region: string, projectId: string, _options?: Configuration): Promise<HttpInfo<EncryptionKeys>> {
    let requestContextValue = await this.requestFactory.listEncryptionKeys(accountId, region, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listEncryptionKeysWithHttpInfo(responseContext)
  }

  /**
   * Description: This API retrieves a list of all encryption keys associated with a specific restore account, region, and project. It requires specifying the account ID, region, and project ID as path parameters.
   * Get all encryption keys
   * @param accountId ID of the restore account
   * @param region Region
   * @param projectId The project ID
   */
  public async listEncryptionKeys(accountId: string, region: string, projectId: string, _options?: Configuration): Promise<EncryptionKeys> {
    const responseWithInfo = await this.listEncryptionKeysWithHttpInfo(accountId, region, projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListGenericSnapshotsApiRequestFactory, ListGenericSnapshotsApiResponseProcessor} from "../apis/ListGenericSnapshotsApi";

export class PromiseListGenericSnapshotsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListGenericSnapshotsApiRequestFactory = new ListGenericSnapshotsApiRequestFactory(configuration),
    private responseProcessor: ListGenericSnapshotsApiResponseProcessor = new ListGenericSnapshotsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve a list of all snapshots for a specific project, with pagination and optional sorting and filtering.
   * Get generic snapshots
   * @param projectId The project ID
   * @param id ID of the inventory item
   * @param start Start date of the time range, inclusive
   * @param end End date of the time range, inclusive
   */
  public async listGenericSnapshotsWithHttpInfo(projectId: string, id: string, start: Date, end: Date, _options?: Configuration): Promise<HttpInfo<ListGenericSnapshotsResponse>> {
    let requestContextValue = await this.requestFactory.listGenericSnapshots(projectId, id, start, end, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listGenericSnapshotsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve a list of all snapshots for a specific project, with pagination and optional sorting and filtering.
   * Get generic snapshots
   * @param projectId The project ID
   * @param id ID of the inventory item
   * @param start Start date of the time range, inclusive
   * @param end End date of the time range, inclusive
   */
  public async listGenericSnapshots(projectId: string, id: string, start: Date, end: Date, _options?: Configuration): Promise<ListGenericSnapshotsResponse> {
    const responseWithInfo = await this.listGenericSnapshotsWithHttpInfo(projectId, id, start, end, _options);
    return responseWithInfo.data;
  }


}


import { ListIntegrationsApiRequestFactory, ListIntegrationsApiResponseProcessor} from "../apis/ListIntegrationsApi";

export class PromiseListIntegrationsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListIntegrationsApiRequestFactory = new ListIntegrationsApiRequestFactory(configuration),
    private responseProcessor: ListIntegrationsApiResponseProcessor = new ListIntegrationsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API operation retrieves a list of all integrations associated with a specific account.
   * Get all integration users of an account
   */
  public async listIntegrationsWithHttpInfo(_options?: Configuration): Promise<HttpInfo<ListIntegrationsResponse>> {
    let requestContextValue = await this.requestFactory.listIntegrations(_options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listIntegrationsWithHttpInfo(responseContext)
  }

  /**
   * Description: This API operation retrieves a list of all integrations associated with a specific account.
   * Get all integration users of an account
   */
  public async listIntegrations(_options?: Configuration): Promise<ListIntegrationsResponse> {
    const responseWithInfo = await this.listIntegrationsWithHttpInfo(_options);
    return responseWithInfo.data;
  }


}


import { ListInventoryApiRequestFactory, ListInventoryApiResponseProcessor} from "../apis/ListInventoryApi";

export class PromiseListInventoryApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListInventoryApiRequestFactory = new ListInventoryApiRequestFactory(configuration),
    private responseProcessor: ListInventoryApiResponseProcessor = new ListInventoryApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetch a paginated list of all inventory items for a specified project with optional sorting and filtering.
   * Get all inventory items
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param sort The sort order, field:direction
   * @param projectId The project ID
   * @param filter The filter to apply
   */
  public async listInventoryWithHttpInfo(pageIndex: number, pageSize: number, sort: string, projectId: string, filter?: string, _options?: Configuration): Promise<HttpInfo<ListInventoryResourcesResponse>> {
    let requestContextValue = await this.requestFactory.listInventory(pageIndex, pageSize, sort, projectId, filter, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listInventoryWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetch a paginated list of all inventory items for a specified project with optional sorting and filtering.
   * Get all inventory items
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param sort The sort order, field:direction
   * @param projectId The project ID
   * @param filter The filter to apply
   */
  public async listInventory(pageIndex: number, pageSize: number, sort: string, projectId: string, filter?: string, _options?: Configuration): Promise<ListInventoryResourcesResponse> {
    const responseWithInfo = await this.listInventoryWithHttpInfo(pageIndex, pageSize, sort, projectId, filter, _options);
    return responseWithInfo.data;
  }


}


import { ListInventoryAppsApiRequestFactory, ListInventoryAppsApiResponseProcessor} from "../apis/ListInventoryAppsApi";

export class PromiseListInventoryAppsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListInventoryAppsApiRequestFactory = new ListInventoryAppsApiRequestFactory(configuration),
    private responseProcessor: ListInventoryAppsApiResponseProcessor = new ListInventoryAppsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve a paginated list of all resource applications associated with a specified project.
   * List all resources applications
   * @param projectId The project ID
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param filter The filter to apply
   */
  public async listInventoryAppsWithHttpInfo(projectId: string, pageIndex?: number, pageSize?: number, filter?: string, _options?: Configuration): Promise<HttpInfo<ListInventoryAppsResponse>> {
    let requestContextValue = await this.requestFactory.listInventoryApps(projectId, pageIndex, pageSize, filter, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listInventoryAppsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve a paginated list of all resource applications associated with a specified project.
   * List all resources applications
   * @param projectId The project ID
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param filter The filter to apply
   */
  public async listInventoryApps(projectId: string, pageIndex?: number, pageSize?: number, filter?: string, _options?: Configuration): Promise<ListInventoryAppsResponse> {
    const responseWithInfo = await this.listInventoryAppsWithHttpInfo(projectId, pageIndex, pageSize, filter, _options);
    return responseWithInfo.data;
  }


}


import { ListInventoryBackupRegionsApiRequestFactory, ListInventoryBackupRegionsApiResponseProcessor} from "../apis/ListInventoryBackupRegionsApi";

export class PromiseListInventoryBackupRegionsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListInventoryBackupRegionsApiRequestFactory = new ListInventoryBackupRegionsApiRequestFactory(configuration),
    private responseProcessor: ListInventoryBackupRegionsApiResponseProcessor = new ListInventoryBackupRegionsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Provides a list of all available backup regions for a given project ID.
   * List all backup regions
   * @param projectId The project ID
   */
  public async listInventoryBackupRegionsWithHttpInfo(projectId: string, _options?: Configuration): Promise<HttpInfo<ListInventoryBackupRegionsResponse>> {
    let requestContextValue = await this.requestFactory.listInventoryBackupRegions(projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listInventoryBackupRegionsWithHttpInfo(responseContext)
  }

  /**
   * Description: Provides a list of all available backup regions for a given project ID.
   * List all backup regions
   * @param projectId The project ID
   */
  public async listInventoryBackupRegions(projectId: string, _options?: Configuration): Promise<ListInventoryBackupRegionsResponse> {
    const responseWithInfo = await this.listInventoryBackupRegionsWithHttpInfo(projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListInventoryEnvironmentsApiRequestFactory, ListInventoryEnvironmentsApiResponseProcessor} from "../apis/ListInventoryEnvironmentsApi";

export class PromiseListInventoryEnvironmentsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListInventoryEnvironmentsApiRequestFactory = new ListInventoryEnvironmentsApiRequestFactory(configuration),
    private responseProcessor: ListInventoryEnvironmentsApiResponseProcessor = new ListInventoryEnvironmentsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API retrieves a list of all inventory environments associated with a specific project ID.
   * List all environments
   * @param projectId The project ID
   */
  public async listInventoryEnvironmentsWithHttpInfo(projectId: string, _options?: Configuration): Promise<HttpInfo<ListInventoryEnvironmentsResponse>> {
    let requestContextValue = await this.requestFactory.listInventoryEnvironments(projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listInventoryEnvironmentsWithHttpInfo(responseContext)
  }

  /**
   * Description: This API retrieves a list of all inventory environments associated with a specific project ID.
   * List all environments
   * @param projectId The project ID
   */
  public async listInventoryEnvironments(projectId: string, _options?: Configuration): Promise<ListInventoryEnvironmentsResponse> {
    const responseWithInfo = await this.listInventoryEnvironmentsWithHttpInfo(projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListInventoryItemSnapshotsApiRequestFactory, ListInventoryItemSnapshotsApiResponseProcessor} from "../apis/ListInventoryItemSnapshotsApi";

export class PromiseListInventoryItemSnapshotsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListInventoryItemSnapshotsApiRequestFactory = new ListInventoryItemSnapshotsApiRequestFactory(configuration),
    private responseProcessor: ListInventoryItemSnapshotsApiResponseProcessor = new ListInventoryItemSnapshotsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve all snapshots for a specified inventory item within a project by providing the inventory item ID, project ID, page index, page size, and snapshot type.
   * Get all snapshots for a specific inventory item
   * @param id ID of the inventory item
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param snapshotType snapshot types eon or gp
   * @param projectId The project ID
   */
  public async listInventoryItemSnapshotsWithHttpInfo(id: string, pageIndex: number, pageSize: number, snapshotType: string, projectId: string, _options?: Configuration): Promise<HttpInfo<ListInventoryItemSnapshotsResponse>> {
    let requestContextValue = await this.requestFactory.listInventoryItemSnapshots(id, pageIndex, pageSize, snapshotType, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listInventoryItemSnapshotsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve all snapshots for a specified inventory item within a project by providing the inventory item ID, project ID, page index, page size, and snapshot type.
   * Get all snapshots for a specific inventory item
   * @param id ID of the inventory item
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param snapshotType snapshot types eon or gp
   * @param projectId The project ID
   */
  public async listInventoryItemSnapshots(id: string, pageIndex: number, pageSize: number, snapshotType: string, projectId: string, _options?: Configuration): Promise<ListInventoryItemSnapshotsResponse> {
    const responseWithInfo = await this.listInventoryItemSnapshotsWithHttpInfo(id, pageIndex, pageSize, snapshotType, projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListInventoryNetworksApiRequestFactory, ListInventoryNetworksApiResponseProcessor} from "../apis/ListInventoryNetworksApi";

export class PromiseListInventoryNetworksApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListInventoryNetworksApiRequestFactory = new ListInventoryNetworksApiRequestFactory(configuration),
    private responseProcessor: ListInventoryNetworksApiResponseProcessor = new ListInventoryNetworksApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetch a paginated list of inventory networks for a specified project ID with optional filtering capabilities.
   * List all resources networks
   * @param projectId The project ID
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param filter The filter to apply
   */
  public async listInventoryNetworksWithHttpInfo(projectId: string, pageIndex?: number, pageSize?: number, filter?: string, _options?: Configuration): Promise<HttpInfo<ListInventoryNetworksResponse>> {
    let requestContextValue = await this.requestFactory.listInventoryNetworks(projectId, pageIndex, pageSize, filter, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listInventoryNetworksWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetch a paginated list of inventory networks for a specified project ID with optional filtering capabilities.
   * List all resources networks
   * @param projectId The project ID
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param filter The filter to apply
   */
  public async listInventoryNetworks(projectId: string, pageIndex?: number, pageSize?: number, filter?: string, _options?: Configuration): Promise<ListInventoryNetworksResponse> {
    const responseWithInfo = await this.listInventoryNetworksWithHttpInfo(projectId, pageIndex, pageSize, filter, _options);
    return responseWithInfo.data;
  }


}


import { ListInventoryResourceTypesApiRequestFactory, ListInventoryResourceTypesApiResponseProcessor} from "../apis/ListInventoryResourceTypesApi";

export class PromiseListInventoryResourceTypesApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListInventoryResourceTypesApiRequestFactory = new ListInventoryResourceTypesApiRequestFactory(configuration),
    private responseProcessor: ListInventoryResourceTypesApiResponseProcessor = new ListInventoryResourceTypesApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetches and lists all available resource types for a given project.
   * List all resource types
   * @param projectId The project ID
   */
  public async listInventoryResourceTypesWithHttpInfo(projectId: string, _options?: Configuration): Promise<HttpInfo<ListInventoryResourceTypesResponse>> {
    let requestContextValue = await this.requestFactory.listInventoryResourceTypes(projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listInventoryResourceTypesWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetches and lists all available resource types for a given project.
   * List all resource types
   * @param projectId The project ID
   */
  public async listInventoryResourceTypes(projectId: string, _options?: Configuration): Promise<ListInventoryResourceTypesResponse> {
    const responseWithInfo = await this.listInventoryResourceTypesWithHttpInfo(projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListInventorySourceRegionsApiRequestFactory, ListInventorySourceRegionsApiResponseProcessor} from "../apis/ListInventorySourceRegionsApi";

export class PromiseListInventorySourceRegionsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListInventorySourceRegionsApiRequestFactory = new ListInventorySourceRegionsApiRequestFactory(configuration),
    private responseProcessor: ListInventorySourceRegionsApiResponseProcessor = new ListInventorySourceRegionsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieves a list of all source regions within the inventory for a specified project.
   * List all resources source regions
   * @param projectId The project ID
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param filter The filter to apply
   */
  public async listInventorySourceRegionsWithHttpInfo(projectId: string, pageIndex?: number, pageSize?: number, filter?: string, _options?: Configuration): Promise<HttpInfo<ListInventorySourceRegionsResponse>> {
    let requestContextValue = await this.requestFactory.listInventorySourceRegions(projectId, pageIndex, pageSize, filter, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listInventorySourceRegionsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieves a list of all source regions within the inventory for a specified project.
   * List all resources source regions
   * @param projectId The project ID
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param filter The filter to apply
   */
  public async listInventorySourceRegions(projectId: string, pageIndex?: number, pageSize?: number, filter?: string, _options?: Configuration): Promise<ListInventorySourceRegionsResponse> {
    const responseWithInfo = await this.listInventorySourceRegionsWithHttpInfo(projectId, pageIndex, pageSize, filter, _options);
    return responseWithInfo.data;
  }


}


import { ListInventorySubnetsApiRequestFactory, ListInventorySubnetsApiResponseProcessor} from "../apis/ListInventorySubnetsApi";

export class PromiseListInventorySubnetsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListInventorySubnetsApiRequestFactory = new ListInventorySubnetsApiRequestFactory(configuration),
    private responseProcessor: ListInventorySubnetsApiResponseProcessor = new ListInventorySubnetsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve a paginated list of all subnets associated with a specific project.
   * List all resources subnets
   * @param projectId The project ID
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param filter The filter to apply
   */
  public async listInventorySubnetsWithHttpInfo(projectId: string, pageIndex?: number, pageSize?: number, filter?: string, _options?: Configuration): Promise<HttpInfo<ListInventorySubnetsResponse>> {
    let requestContextValue = await this.requestFactory.listInventorySubnets(projectId, pageIndex, pageSize, filter, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listInventorySubnetsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve a paginated list of all subnets associated with a specific project.
   * List all resources subnets
   * @param projectId The project ID
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param filter The filter to apply
   */
  public async listInventorySubnets(projectId: string, pageIndex?: number, pageSize?: number, filter?: string, _options?: Configuration): Promise<ListInventorySubnetsResponse> {
    const responseWithInfo = await this.listInventorySubnetsWithHttpInfo(projectId, pageIndex, pageSize, filter, _options);
    return responseWithInfo.data;
  }


}


import { ListJobsApiRequestFactory, ListJobsApiResponseProcessor} from "../apis/ListJobsApi";

export class PromiseListJobsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListJobsApiRequestFactory = new ListJobsApiRequestFactory(configuration),
    private responseProcessor: ListJobsApiResponseProcessor = new ListJobsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetches all jobs for a given project ID, such as restore, scan, or backup jobs, with pagination and sorting options.
   * Get all jobs of type - restore, scan or backup
   * @param projectId The project ID
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param jobType 
   * @param sort The sort order, field:direction
   * @param filter The filter to apply
   */
  public async listJobsWithHttpInfo(projectId: string, pageIndex: number, pageSize: number, jobType?: 'restore' | 'backup' | 'scan', sort?: string, filter?: string, _options?: Configuration): Promise<HttpInfo<ListJobs200Response>> {
    let requestContextValue = await this.requestFactory.listJobs(projectId, pageIndex, pageSize, jobType, sort, filter, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listJobsWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetches all jobs for a given project ID, such as restore, scan, or backup jobs, with pagination and sorting options.
   * Get all jobs of type - restore, scan or backup
   * @param projectId The project ID
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param jobType 
   * @param sort The sort order, field:direction
   * @param filter The filter to apply
   */
  public async listJobs(projectId: string, pageIndex: number, pageSize: number, jobType?: 'restore' | 'backup' | 'scan', sort?: string, filter?: string, _options?: Configuration): Promise<ListJobs200Response> {
    const responseWithInfo = await this.listJobsWithHttpInfo(projectId, pageIndex, pageSize, jobType, sort, filter, _options);
    return responseWithInfo.data;
  }


}


import { ListPathSnapshotsApiRequestFactory, ListPathSnapshotsApiResponseProcessor} from "../apis/ListPathSnapshotsApi";

export class PromiseListPathSnapshotsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListPathSnapshotsApiRequestFactory = new ListPathSnapshotsApiRequestFactory(configuration),
    private responseProcessor: ListPathSnapshotsApiResponseProcessor = new ListPathSnapshotsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve all snapshots for a specified file on a resource within a project
   * Get all snapshots for a specific file on resource
   * @param resourceId ID of the inventory item
   * @param start The start date for the query
   * @param end The end date for the query
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param projectId The project ID
   * @param pathSnapshotsRequest The request body for searching records
   */
  public async listPathSnapshotsWithHttpInfo(resourceId: string, start: Date, end: Date, pageIndex: number, pageSize: number, projectId: string, pathSnapshotsRequest: PathSnapshotsRequest, _options?: Configuration): Promise<HttpInfo<PathSnapshotsResponse>> {
    let requestContextValue = await this.requestFactory.listPathSnapshots(resourceId, start, end, pageIndex, pageSize, projectId, pathSnapshotsRequest, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listPathSnapshotsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve all snapshots for a specified file on a resource within a project
   * Get all snapshots for a specific file on resource
   * @param resourceId ID of the inventory item
   * @param start The start date for the query
   * @param end The end date for the query
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param projectId The project ID
   * @param pathSnapshotsRequest The request body for searching records
   */
  public async listPathSnapshots(resourceId: string, start: Date, end: Date, pageIndex: number, pageSize: number, projectId: string, pathSnapshotsRequest: PathSnapshotsRequest, _options?: Configuration): Promise<PathSnapshotsResponse> {
    const responseWithInfo = await this.listPathSnapshotsWithHttpInfo(resourceId, start, end, pageIndex, pageSize, projectId, pathSnapshotsRequest, _options);
    return responseWithInfo.data;
  }


}


import { ListProjectRestoreAccountsApiRequestFactory, ListProjectRestoreAccountsApiResponseProcessor} from "../apis/ListProjectRestoreAccountsApi";

export class PromiseListProjectRestoreAccountsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListProjectRestoreAccountsApiRequestFactory = new ListProjectRestoreAccountsApiRequestFactory(configuration),
    private responseProcessor: ListProjectRestoreAccountsApiResponseProcessor = new ListProjectRestoreAccountsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve a list of all restore accounts associated with a specific project using its project ID.
   * Get all restore accounts
   * @param projectId The project ID
   */
  public async listProjectRestoreAccountsWithHttpInfo(projectId: string, _options?: Configuration): Promise<HttpInfo<ListAccountsResponse>> {
    let requestContextValue = await this.requestFactory.listProjectRestoreAccounts(projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listProjectRestoreAccountsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve a list of all restore accounts associated with a specific project using its project ID.
   * Get all restore accounts
   * @param projectId The project ID
   */
  public async listProjectRestoreAccounts(projectId: string, _options?: Configuration): Promise<ListAccountsResponse> {
    const responseWithInfo = await this.listProjectRestoreAccountsWithHttpInfo(projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListProjectScanningAccountsApiRequestFactory, ListProjectScanningAccountsApiResponseProcessor} from "../apis/ListProjectScanningAccountsApi";

export class PromiseListProjectScanningAccountsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListProjectScanningAccountsApiRequestFactory = new ListProjectScanningAccountsApiRequestFactory(configuration),
    private responseProcessor: ListProjectScanningAccountsApiResponseProcessor = new ListProjectScanningAccountsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API retrieves all scanning accounts associated with a specified project.
   * Get all scanning accounts
   * @param projectId The project ID
   */
  public async listProjectScanningAccountsWithHttpInfo(projectId: string, _options?: Configuration): Promise<HttpInfo<ListAccountsResponse>> {
    let requestContextValue = await this.requestFactory.listProjectScanningAccounts(projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listProjectScanningAccountsWithHttpInfo(responseContext)
  }

  /**
   * Description: This API retrieves all scanning accounts associated with a specified project.
   * Get all scanning accounts
   * @param projectId The project ID
   */
  public async listProjectScanningAccounts(projectId: string, _options?: Configuration): Promise<ListAccountsResponse> {
    const responseWithInfo = await this.listProjectScanningAccountsWithHttpInfo(projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListProjectSourceAccountsApiRequestFactory, ListProjectSourceAccountsApiResponseProcessor} from "../apis/ListProjectSourceAccountsApi";

export class PromiseListProjectSourceAccountsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListProjectSourceAccountsApiRequestFactory = new ListProjectSourceAccountsApiRequestFactory(configuration),
    private responseProcessor: ListProjectSourceAccountsApiResponseProcessor = new ListProjectSourceAccountsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API retrieves all source accounts associated with a specific project identified by the project ID.
   * Get all source accounts
   * @param projectId The project ID
   */
  public async listProjectSourceAccountsWithHttpInfo(projectId: string, _options?: Configuration): Promise<HttpInfo<ListAccountsResponse>> {
    let requestContextValue = await this.requestFactory.listProjectSourceAccounts(projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listProjectSourceAccountsWithHttpInfo(responseContext)
  }

  /**
   * Description: This API retrieves all source accounts associated with a specific project identified by the project ID.
   * Get all source accounts
   * @param projectId The project ID
   */
  public async listProjectSourceAccounts(projectId: string, _options?: Configuration): Promise<ListAccountsResponse> {
    const responseWithInfo = await this.listProjectSourceAccountsWithHttpInfo(projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListProjectUsersApiRequestFactory, ListProjectUsersApiResponseProcessor} from "../apis/ListProjectUsersApi";

export class PromiseListProjectUsersApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListProjectUsersApiRequestFactory = new ListProjectUsersApiRequestFactory(configuration),
    private responseProcessor: ListProjectUsersApiResponseProcessor = new ListProjectUsersApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve a paginated list of users associated with a specific project.
   * Get all users of a project
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param sort The sort order, field:direction
   * @param projectId The project ID
   * @param filter The filter to apply
   */
  public async listProjectUsersWithHttpInfo(pageIndex: number, pageSize: number, sort: string, projectId: string, filter?: string, _options?: Configuration): Promise<HttpInfo<ListUsersResponse>> {
    let requestContextValue = await this.requestFactory.listProjectUsers(pageIndex, pageSize, sort, projectId, filter, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listProjectUsersWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve a paginated list of users associated with a specific project.
   * Get all users of a project
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param sort The sort order, field:direction
   * @param projectId The project ID
   * @param filter The filter to apply
   */
  public async listProjectUsers(pageIndex: number, pageSize: number, sort: string, projectId: string, filter?: string, _options?: Configuration): Promise<ListUsersResponse> {
    const responseWithInfo = await this.listProjectUsersWithHttpInfo(pageIndex, pageSize, sort, projectId, filter, _options);
    return responseWithInfo.data;
  }


}


import { ListProjectVaultAccountsApiRequestFactory, ListProjectVaultAccountsApiResponseProcessor} from "../apis/ListProjectVaultAccountsApi";

export class PromiseListProjectVaultAccountsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListProjectVaultAccountsApiRequestFactory = new ListProjectVaultAccountsApiRequestFactory(configuration),
    private responseProcessor: ListProjectVaultAccountsApiResponseProcessor = new ListProjectVaultAccountsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve a list of all vault accounts associated with a specific project ID.
   * Get all vault accounts
   * @param projectId The project ID
   */
  public async listProjectVaultAccountsWithHttpInfo(projectId: string, _options?: Configuration): Promise<HttpInfo<ListAccountsResponse>> {
    let requestContextValue = await this.requestFactory.listProjectVaultAccounts(projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listProjectVaultAccountsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve a list of all vault accounts associated with a specific project ID.
   * Get all vault accounts
   * @param projectId The project ID
   */
  public async listProjectVaultAccounts(projectId: string, _options?: Configuration): Promise<ListAccountsResponse> {
    const responseWithInfo = await this.listProjectVaultAccountsWithHttpInfo(projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListRestoreAccountsApiRequestFactory, ListRestoreAccountsApiResponseProcessor} from "../apis/ListRestoreAccountsApi";

export class PromiseListRestoreAccountsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListRestoreAccountsApiRequestFactory = new ListRestoreAccountsApiRequestFactory(configuration),
    private responseProcessor: ListRestoreAccountsApiResponseProcessor = new ListRestoreAccountsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API allows administrators to retrieve a list of all restore accounts.
   * Get all restore accounts
   */
  public async listRestoreAccountsWithHttpInfo(_options?: Configuration): Promise<HttpInfo<ListAccountsResponse>> {
    let requestContextValue = await this.requestFactory.listRestoreAccounts(_options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listRestoreAccountsWithHttpInfo(responseContext)
  }

  /**
   * Description: This API allows administrators to retrieve a list of all restore accounts.
   * Get all restore accounts
   */
  public async listRestoreAccounts(_options?: Configuration): Promise<ListAccountsResponse> {
    const responseWithInfo = await this.listRestoreAccountsWithHttpInfo(_options);
    return responseWithInfo.data;
  }


}


import { ListRestoreRegionsApiRequestFactory, ListRestoreRegionsApiResponseProcessor} from "../apis/ListRestoreRegionsApi";

export class PromiseListRestoreRegionsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListRestoreRegionsApiRequestFactory = new ListRestoreRegionsApiRequestFactory(configuration),
    private responseProcessor: ListRestoreRegionsApiResponseProcessor = new ListRestoreRegionsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint retrieves the restore information for a specified account. It returns details about the restore regions associated with an account in a given project.
   * Gets restore information for account
   * @param accountId ID of the restore account
   * @param projectId The project ID
   */
  public async listRestoreRegionsWithHttpInfo(accountId: string, projectId: string, _options?: Configuration): Promise<HttpInfo<RestoreAccountRegions>> {
    let requestContextValue = await this.requestFactory.listRestoreRegions(accountId, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listRestoreRegionsWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint retrieves the restore information for a specified account. It returns details about the restore regions associated with an account in a given project.
   * Gets restore information for account
   * @param accountId ID of the restore account
   * @param projectId The project ID
   */
  public async listRestoreRegions(accountId: string, projectId: string, _options?: Configuration): Promise<RestoreAccountRegions> {
    const responseWithInfo = await this.listRestoreRegionsWithHttpInfo(accountId, projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListRolesApiRequestFactory, ListRolesApiResponseProcessor} from "../apis/ListRolesApi";

export class PromiseListRolesApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListRolesApiRequestFactory = new ListRolesApiRequestFactory(configuration),
    private responseProcessor: ListRolesApiResponseProcessor = new ListRolesApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetches and retrieves a list of all user roles.
   * Get all roles
   */
  public async listRolesWithHttpInfo(_options?: Configuration): Promise<HttpInfo<RolesResponse>> {
    let requestContextValue = await this.requestFactory.listRoles(_options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listRolesWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetches and retrieves a list of all user roles.
   * Get all roles
   */
  public async listRoles(_options?: Configuration): Promise<RolesResponse> {
    const responseWithInfo = await this.listRolesWithHttpInfo(_options);
    return responseWithInfo.data;
  }


}


import { ListS3BucketsApiRequestFactory, ListS3BucketsApiResponseProcessor} from "../apis/ListS3BucketsApi";

export class PromiseListS3BucketsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListS3BucketsApiRequestFactory = new ListS3BucketsApiRequestFactory(configuration),
    private responseProcessor: ListS3BucketsApiResponseProcessor = new ListS3BucketsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve all S3 buckets associated with a specific restore account and project.
   * Get all buckets in account
   * @param accountId ID of the restore account
   * @param projectId The project ID
   */
  public async listS3BucketsWithHttpInfo(accountId: string, projectId: string, _options?: Configuration): Promise<HttpInfo<S3Buckets>> {
    let requestContextValue = await this.requestFactory.listS3Buckets(accountId, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listS3BucketsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve all S3 buckets associated with a specific restore account and project.
   * Get all buckets in account
   * @param accountId ID of the restore account
   * @param projectId The project ID
   */
  public async listS3Buckets(accountId: string, projectId: string, _options?: Configuration): Promise<S3Buckets> {
    const responseWithInfo = await this.listS3BucketsWithHttpInfo(accountId, projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListSamlIdentityProviderConfigsApiRequestFactory, ListSamlIdentityProviderConfigsApiResponseProcessor} from "../apis/ListSamlIdentityProviderConfigsApi";

export class PromiseListSamlIdentityProviderConfigsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListSamlIdentityProviderConfigsApiRequestFactory = new ListSamlIdentityProviderConfigsApiRequestFactory(configuration),
    private responseProcessor: ListSamlIdentityProviderConfigsApiResponseProcessor = new ListSamlIdentityProviderConfigsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetches all SAML identity provider configurations associated with a given account, requiring \'admin:idp_configs\' permissions.
   * Get all saml identity provider configs of an account
   */
  public async listSamlIdentityProviderConfigsWithHttpInfo(_options?: Configuration): Promise<HttpInfo<SamlIdentityProviderConfigsResponse>> {
    let requestContextValue = await this.requestFactory.listSamlIdentityProviderConfigs(_options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listSamlIdentityProviderConfigsWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetches all SAML identity provider configurations associated with a given account, requiring \'admin:idp_configs\' permissions.
   * Get all saml identity provider configs of an account
   */
  public async listSamlIdentityProviderConfigs(_options?: Configuration): Promise<SamlIdentityProviderConfigsResponse> {
    const responseWithInfo = await this.listSamlIdentityProviderConfigsWithHttpInfo(_options);
    return responseWithInfo.data;
  }


}


import { ListScanningAccountsApiRequestFactory, ListScanningAccountsApiResponseProcessor} from "../apis/ListScanningAccountsApi";

export class PromiseListScanningAccountsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListScanningAccountsApiRequestFactory = new ListScanningAccountsApiRequestFactory(configuration),
    private responseProcessor: ListScanningAccountsApiResponseProcessor = new ListScanningAccountsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Fetches a list of all scanning accounts available in the system.
   * Get all scanning accounts
   */
  public async listScanningAccountsWithHttpInfo(_options?: Configuration): Promise<HttpInfo<ListAccountsResponse>> {
    let requestContextValue = await this.requestFactory.listScanningAccounts(_options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listScanningAccountsWithHttpInfo(responseContext)
  }

  /**
   * Description: Fetches a list of all scanning accounts available in the system.
   * Get all scanning accounts
   */
  public async listScanningAccounts(_options?: Configuration): Promise<ListAccountsResponse> {
    const responseWithInfo = await this.listScanningAccountsWithHttpInfo(_options);
    return responseWithInfo.data;
  }


}


import { ListSecurityGroupsApiRequestFactory, ListSecurityGroupsApiResponseProcessor} from "../apis/ListSecurityGroupsApi";

export class PromiseListSecurityGroupsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListSecurityGroupsApiRequestFactory = new ListSecurityGroupsApiRequestFactory(configuration),
    private responseProcessor: ListSecurityGroupsApiResponseProcessor = new ListSecurityGroupsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve security group information for a specific restore account in a given project and region - that have a default RDS subnet group.
   * Gets restore information for account
   * @param accountId ID of the restore account
   * @param region The region to get availability zones for
   * @param projectId The project ID
   */
  public async listSecurityGroupsWithHttpInfo(accountId: string, region: string, projectId: string, _options?: Configuration): Promise<HttpInfo<RestoreSecurityGroups>> {
    let requestContextValue = await this.requestFactory.listSecurityGroups(accountId, region, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listSecurityGroupsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve security group information for a specific restore account in a given project and region - that have a default RDS subnet group.
   * Gets restore information for account
   * @param accountId ID of the restore account
   * @param region The region to get availability zones for
   * @param projectId The project ID
   */
  public async listSecurityGroups(accountId: string, region: string, projectId: string, _options?: Configuration): Promise<RestoreSecurityGroups> {
    const responseWithInfo = await this.listSecurityGroupsWithHttpInfo(accountId, region, projectId, _options);
    return responseWithInfo.data;
  }


}


import { ListSourceAccountsApiRequestFactory, ListSourceAccountsApiResponseProcessor} from "../apis/ListSourceAccountsApi";

export class PromiseListSourceAccountsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListSourceAccountsApiRequestFactory = new ListSourceAccountsApiRequestFactory(configuration),
    private responseProcessor: ListSourceAccountsApiResponseProcessor = new ListSourceAccountsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API allows users to retrieve all source accounts associated with their project. It requires admin-level permissions.
   * Get all source accounts
   */
  public async listSourceAccountsWithHttpInfo(_options?: Configuration): Promise<HttpInfo<ListAccountsResponse>> {
    let requestContextValue = await this.requestFactory.listSourceAccounts(_options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listSourceAccountsWithHttpInfo(responseContext)
  }

  /**
   * Description: This API allows users to retrieve all source accounts associated with their project. It requires admin-level permissions.
   * Get all source accounts
   */
  public async listSourceAccounts(_options?: Configuration): Promise<ListAccountsResponse> {
    const responseWithInfo = await this.listSourceAccountsWithHttpInfo(_options);
    return responseWithInfo.data;
  }


}


import { ListUsersApiRequestFactory, ListUsersApiResponseProcessor} from "../apis/ListUsersApi";

export class PromiseListUsersApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListUsersApiRequestFactory = new ListUsersApiRequestFactory(configuration),
    private responseProcessor: ListUsersApiResponseProcessor = new ListUsersApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API operation retrieves a list of all users associated with a specific account, supporting pagination, sorting, and filtering.
   * Get all users of an account
   */
  public async listUsersWithHttpInfo(_options?: Configuration): Promise<HttpInfo<ListUsersResponse>> {
    let requestContextValue = await this.requestFactory.listUsers(_options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listUsersWithHttpInfo(responseContext)
  }

  /**
   * Description: This API operation retrieves a list of all users associated with a specific account, supporting pagination, sorting, and filtering.
   * Get all users of an account
   */
  public async listUsers(_options?: Configuration): Promise<ListUsersResponse> {
    const responseWithInfo = await this.listUsersWithHttpInfo(_options);
    return responseWithInfo.data;
  }


}


import { ListVaultAccountsApiRequestFactory, ListVaultAccountsApiResponseProcessor} from "../apis/ListVaultAccountsApi";

export class PromiseListVaultAccountsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ListVaultAccountsApiRequestFactory = new ListVaultAccountsApiRequestFactory(configuration),
    private responseProcessor: ListVaultAccountsApiResponseProcessor = new ListVaultAccountsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve a list of all vault accounts. Requires admin permissions.
   * Get all vault accounts
   */
  public async listVaultAccountsWithHttpInfo(_options?: Configuration): Promise<HttpInfo<ListAccountsResponse>> {
    let requestContextValue = await this.requestFactory.listVaultAccounts(_options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listVaultAccountsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve a list of all vault accounts. Requires admin permissions.
   * Get all vault accounts
   */
  public async listVaultAccounts(_options?: Configuration): Promise<ListAccountsResponse> {
    const responseWithInfo = await this.listVaultAccountsWithHttpInfo(_options);
    return responseWithInfo.data;
  }


}


import { LoginApiRequestFactory, LoginApiResponseProcessor} from "../apis/LoginApi";

export class PromiseLoginApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: LoginApiRequestFactory = new LoginApiRequestFactory(configuration),
    private responseProcessor: LoginApiResponseProcessor = new LoginApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint handles user login by optionally accepting a domain and return URL as query parameters. Depending on the outcome, it will either return no content or redirect to Cognito callback.
   * Login
   * @param domain the domain of the account. If not provided, will be extracted from the subdomain of the request
   * @param returnUrl a url to redirect to after login. this only works when cognito is bypassed
   */
  public async loginWithHttpInfo(domain?: string, returnUrl?: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.login(domain, returnUrl, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.loginWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint handles user login by optionally accepting a domain and return URL as query parameters. Depending on the outcome, it will either return no content or redirect to Cognito callback.
   * Login
   * @param domain the domain of the account. If not provided, will be extracted from the subdomain of the request
   * @param returnUrl a url to redirect to after login. this only works when cognito is bypassed
   */
  public async login(domain?: string, returnUrl?: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.loginWithHttpInfo(domain, returnUrl, _options);
    return responseWithInfo.data;
  }


}


import { LogoutApiRequestFactory, LogoutApiResponseProcessor} from "../apis/LogoutApi";

export class PromiseLogoutApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: LogoutApiRequestFactory = new LogoutApiRequestFactory(configuration),
    private responseProcessor: LogoutApiResponseProcessor = new LogoutApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API operation logs out a user by generating a logout URL and redirecting to the cognito logout endpoint if necessary.
   * logout
   */
  public async logoutWithHttpInfo(_options?: Configuration): Promise<HttpInfo<Message>> {
    let requestContextValue = await this.requestFactory.logout(_options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.logoutWithHttpInfo(responseContext)
  }

  /**
   * Description: This API operation logs out a user by generating a logout URL and redirecting to the cognito logout endpoint if necessary.
   * logout
   */
  public async logout(_options?: Configuration): Promise<Message> {
    const responseWithInfo = await this.logoutWithHttpInfo(_options);
    return responseWithInfo.data;
  }


}


import { MeteringPopulateApiRequestFactory, MeteringPopulateApiResponseProcessor} from "../apis/MeteringPopulateApi";

export class PromiseMeteringPopulateApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: MeteringPopulateApiRequestFactory = new MeteringPopulateApiRequestFactory(configuration),
    private responseProcessor: MeteringPopulateApiResponseProcessor = new MeteringPopulateApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Populates test data for a specific project with a given version number.
   * Populate test data
   * @param projectId The project ID
   * @param version the version number of the data to populate
   */
  public async meteringPopulateWithHttpInfo(projectId: string, version: number, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.meteringPopulate(projectId, version, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.meteringPopulateWithHttpInfo(responseContext)
  }

  /**
   * Description: Populates test data for a specific project with a given version number.
   * Populate test data
   * @param projectId The project ID
   * @param version the version number of the data to populate
   */
  public async meteringPopulate(projectId: string, version: number, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.meteringPopulateWithHttpInfo(projectId, version, _options);
    return responseWithInfo.data;
  }


}


import { NotificationsApiRequestFactory, NotificationsApiResponseProcessor} from "../apis/NotificationsApi";

export class PromiseNotificationsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: NotificationsApiRequestFactory = new NotificationsApiRequestFactory(configuration),
    private responseProcessor: NotificationsApiResponseProcessor = new NotificationsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Deletes a notification policy by its ID within a given project.
   * Delete Notification Policy
   * @param id ID of the policy to delete
   * @param projectId The project ID
   */
  public async deleteNotificationPolicyWithHttpInfo(id: string, projectId: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.deleteNotificationPolicy(id, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.deleteNotificationPolicyWithHttpInfo(responseContext)
  }

  /**
   * Description: Deletes a notification policy by its ID within a given project.
   * Delete Notification Policy
   * @param id ID of the policy to delete
   * @param projectId The project ID
   */
  public async deleteNotificationPolicy(id: string, projectId: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.deleteNotificationPolicyWithHttpInfo(id, projectId, _options);
    return responseWithInfo.data;
  }

  /**
   * Description: Retrieves a list of all notification policies associated with a specified project.
   * List Notification Policies
   * @param projectId The project ID
   */
  public async listNotificationPoliciesWithHttpInfo(projectId: string, _options?: Configuration): Promise<HttpInfo<ListNotificationPoliciesResponse>> {
    let requestContextValue = await this.requestFactory.listNotificationPolicies(projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listNotificationPoliciesWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieves a list of all notification policies associated with a specified project.
   * List Notification Policies
   * @param projectId The project ID
   */
  public async listNotificationPolicies(projectId: string, _options?: Configuration): Promise<ListNotificationPoliciesResponse> {
    const responseWithInfo = await this.listNotificationPoliciesWithHttpInfo(projectId, _options);
    return responseWithInfo.data;
  }

  /**
   * Description: Retrieves a project\'s notifications.
   * List Notifications
   * @param projectId The project ID
   * @param pageToken Cursor that points to the first record of the next page of results. Get this value from the previous response. To preserve the results in the same order, use the same sorting and filters in the first request as all subsequent requests. 
   * @param pageSize Max number of items to return in the next page.
   * @param listNotificationsRequest Sort and filter options.
   */
  public async listNotificationsWithHttpInfo(projectId: string, pageToken?: string, pageSize?: number, listNotificationsRequest?: ListNotificationsRequest, _options?: Configuration): Promise<HttpInfo<ListNotificationsResponse>> {
    let requestContextValue = await this.requestFactory.listNotifications(projectId, pageToken, pageSize, listNotificationsRequest, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listNotificationsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieves a project\'s notifications.
   * List Notifications
   * @param projectId The project ID
   * @param pageToken Cursor that points to the first record of the next page of results. Get this value from the previous response. To preserve the results in the same order, use the same sorting and filters in the first request as all subsequent requests. 
   * @param pageSize Max number of items to return in the next page.
   * @param listNotificationsRequest Sort and filter options.
   */
  public async listNotifications(projectId: string, pageToken?: string, pageSize?: number, listNotificationsRequest?: ListNotificationsRequest, _options?: Configuration): Promise<ListNotificationsResponse> {
    const responseWithInfo = await this.listNotificationsWithHttpInfo(projectId, pageToken, pageSize, listNotificationsRequest, _options);
    return responseWithInfo.data;
  }

  /**
   * Description: Updates a notification policy identified by its ID and project ID.
   * Update Notification Policy
   * @param id ID of the notification policy to update
   * @param projectId The project ID
   * @param notificationPolicy 
   */
  public async updateNotificationPolicyWithHttpInfo(id: string, projectId: string, notificationPolicy: NotificationPolicy, _options?: Configuration): Promise<HttpInfo<NotificationPolicy>> {
    let requestContextValue = await this.requestFactory.updateNotificationPolicy(id, projectId, notificationPolicy, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateNotificationPolicyWithHttpInfo(responseContext)
  }

  /**
   * Description: Updates a notification policy identified by its ID and project ID.
   * Update Notification Policy
   * @param id ID of the notification policy to update
   * @param projectId The project ID
   * @param notificationPolicy 
   */
  public async updateNotificationPolicy(id: string, projectId: string, notificationPolicy: NotificationPolicy, _options?: Configuration): Promise<NotificationPolicy> {
    const responseWithInfo = await this.updateNotificationPolicyWithHttpInfo(id, projectId, notificationPolicy, _options);
    return responseWithInfo.data;
  }


}


import { OnboardAzureSubscriptionsApiRequestFactory, OnboardAzureSubscriptionsApiResponseProcessor} from "../apis/OnboardAzureSubscriptionsApi";

export class PromiseOnboardAzureSubscriptionsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: OnboardAzureSubscriptionsApiRequestFactory = new OnboardAzureSubscriptionsApiRequestFactory(configuration),
    private responseProcessor: OnboardAzureSubscriptionsApiResponseProcessor = new OnboardAzureSubscriptionsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Onboard Azure subscriptions to Eon
   * @param projectId The project ID
   * @param requestId 
   * @param azureOnboardingRequest 
   */
  public async onboardAzureSubscriptionsWithHttpInfo(projectId: string, requestId: string, azureOnboardingRequest?: AzureOnboardingRequest, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.onboardAzureSubscriptions(projectId, requestId, azureOnboardingRequest, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.onboardAzureSubscriptionsWithHttpInfo(responseContext)
  }

  /**
   * Onboard Azure subscriptions to Eon
   * @param projectId The project ID
   * @param requestId 
   * @param azureOnboardingRequest 
   */
  public async onboardAzureSubscriptions(projectId: string, requestId: string, azureOnboardingRequest?: AzureOnboardingRequest, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.onboardAzureSubscriptionsWithHttpInfo(projectId, requestId, azureOnboardingRequest, _options);
    return responseWithInfo.data;
  }


}


import { OverrideApplicationsApiRequestFactory, OverrideApplicationsApiResponseProcessor} from "../apis/OverrideApplicationsApi";

export class PromiseOverrideApplicationsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: OverrideApplicationsApiRequestFactory = new OverrideApplicationsApiRequestFactory(configuration),
    private responseProcessor: OverrideApplicationsApiResponseProcessor = new OverrideApplicationsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API allows you to update the application override settings for a specific inventory item identified by the project ID.
   * Update application override for an inventory item
   * @param projectId The project ID
   * @param batchApplicationOverrides The request body for updating application override
   */
  public async overrideApplicationsWithHttpInfo(projectId: string, batchApplicationOverrides: BatchApplicationOverrides, _options?: Configuration): Promise<HttpInfo<BatchOperationResponse>> {
    let requestContextValue = await this.requestFactory.overrideApplications(projectId, batchApplicationOverrides, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.overrideApplicationsWithHttpInfo(responseContext)
  }

  /**
   * Description: This API allows you to update the application override settings for a specific inventory item identified by the project ID.
   * Update application override for an inventory item
   * @param projectId The project ID
   * @param batchApplicationOverrides The request body for updating application override
   */
  public async overrideApplications(projectId: string, batchApplicationOverrides: BatchApplicationOverrides, _options?: Configuration): Promise<BatchOperationResponse> {
    const responseWithInfo = await this.overrideApplicationsWithHttpInfo(projectId, batchApplicationOverrides, _options);
    return responseWithInfo.data;
  }


}


import { ProjectPopulateApiRequestFactory, ProjectPopulateApiResponseProcessor} from "../apis/ProjectPopulateApi";

export class PromiseProjectPopulateApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ProjectPopulateApiRequestFactory = new ProjectPopulateApiRequestFactory(configuration),
    private responseProcessor: ProjectPopulateApiResponseProcessor = new ProjectPopulateApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint populates test data for a specified project using the given project ID and version number.
   * Populate test data
   * @param projectId The project ID
   * @param version the version number of the data to populate
   */
  public async projectPopulateWithHttpInfo(projectId: string, version: number, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.projectPopulate(projectId, version, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.projectPopulateWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint populates test data for a specified project using the given project ID and version number.
   * Populate test data
   * @param projectId The project ID
   * @param version the version number of the data to populate
   */
  public async projectPopulate(projectId: string, version: number, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.projectPopulateWithHttpInfo(projectId, version, _options);
    return responseWithInfo.data;
  }


}


import { ProjectsApiRequestFactory, ProjectsApiResponseProcessor} from "../apis/ProjectsApi";

export class PromiseProjectsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ProjectsApiRequestFactory = new ProjectsApiRequestFactory(configuration),
    private responseProcessor: ProjectsApiResponseProcessor = new ProjectsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieves a list of projects in your Eon account.  This method takes no parameters and returns a list of projects the access key has access to. 
   * List projects
   */
  public async listProjectsWithHttpInfo(_options?: Configuration): Promise<HttpInfo<ListProjectsResponse>> {
    let requestContextValue = await this.requestFactory.listProjects(_options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listProjectsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieves a list of projects in your Eon account.  This method takes no parameters and returns a list of projects the access key has access to. 
   * List projects
   */
  public async listProjects(_options?: Configuration): Promise<ListProjectsResponse> {
    const responseWithInfo = await this.listProjectsWithHttpInfo(_options);
    return responseWithInfo.data;
  }


}


import { QueryDBApiRequestFactory, QueryDBApiResponseProcessor} from "../apis/QueryDBApi";

export class PromiseQueryDBApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: QueryDBApiRequestFactory = new QueryDBApiRequestFactory(configuration),
    private responseProcessor: QueryDBApiResponseProcessor = new QueryDBApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API searches for database records within a specified project. It allows pagination through the use of `pageIndex` and `pageSize` query parameters, and requires a JSON request body conforming to the SearchInput schema.
   * Search for database records
   * @param projectId The project ID
   * @param searchInput The request body for searching records
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   */
  public async queryDBWithHttpInfo(projectId: string, searchInput: SearchInput, pageIndex?: number, pageSize?: number, _options?: Configuration): Promise<HttpInfo<SearchDBResponse>> {
    let requestContextValue = await this.requestFactory.queryDB(projectId, searchInput, pageIndex, pageSize, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.queryDBWithHttpInfo(responseContext)
  }

  /**
   * Description: This API searches for database records within a specified project. It allows pagination through the use of `pageIndex` and `pageSize` query parameters, and requires a JSON request body conforming to the SearchInput schema.
   * Search for database records
   * @param projectId The project ID
   * @param searchInput The request body for searching records
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   */
  public async queryDB(projectId: string, searchInput: SearchInput, pageIndex?: number, pageSize?: number, _options?: Configuration): Promise<SearchDBResponse> {
    const responseWithInfo = await this.queryDBWithHttpInfo(projectId, searchInput, pageIndex, pageSize, _options);
    return responseWithInfo.data;
  }


}


import { QueryDBResultsApiRequestFactory, QueryDBResultsApiResponseProcessor} from "../apis/QueryDBResultsApi";

export class PromiseQueryDBResultsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: QueryDBResultsApiRequestFactory = new QueryDBResultsApiRequestFactory(configuration),
    private responseProcessor: QueryDBResultsApiResponseProcessor = new QueryDBResultsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint allows fetching database records for a given search request identified by a query ID and project ID. It supports pagination through maxResults and nextToken parameters.
   * Fetch database records for a completed search request
   * @param queryId Query ID token
   * @param projectId The project ID
   * @param maxResults number of records to return for pagination
   * @param pageToken pagination token for fetching next set of records
   */
  public async queryDBResultsWithHttpInfo(queryId: string, projectId: string, maxResults?: number, pageToken?: string, _options?: Configuration): Promise<HttpInfo<QueryDBResultResponse>> {
    let requestContextValue = await this.requestFactory.queryDBResults(queryId, projectId, maxResults, pageToken, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.queryDBResultsWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint allows fetching database records for a given search request identified by a query ID and project ID. It supports pagination through maxResults and nextToken parameters.
   * Fetch database records for a completed search request
   * @param queryId Query ID token
   * @param projectId The project ID
   * @param maxResults number of records to return for pagination
   * @param pageToken pagination token for fetching next set of records
   */
  public async queryDBResults(queryId: string, projectId: string, maxResults?: number, pageToken?: string, _options?: Configuration): Promise<QueryDBResultResponse> {
    const responseWithInfo = await this.queryDBResultsWithHttpInfo(queryId, projectId, maxResults, pageToken, _options);
    return responseWithInfo.data;
  }


}


import { QueryDBSnapshotApiRequestFactory, QueryDBSnapshotApiResponseProcessor} from "../apis/QueryDBSnapshotApi";

export class PromiseQueryDBSnapshotApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: QueryDBSnapshotApiRequestFactory = new QueryDBSnapshotApiRequestFactory(configuration),
    private responseProcessor: QueryDBSnapshotApiResponseProcessor = new QueryDBSnapshotApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API allows the user to issue a search request for database records based on provided snapshot and database parameters. The results can be stored in a specified target account and bucket.
   * Issue search request for database records
   * @param snapshotId snapshot ID
   * @param databaseName Database name to query
   * @param query SQL to execute on snapshot
   * @param projectId The project ID
   * @param targetAccountId The target account ID to store the query resutls
   * @param bucketName Bucket name to store query results
   * @param bucketPrefix Bucket key prefix when storing query results
   */
  public async queryDBSnapshotWithHttpInfo(snapshotId: string, databaseName: string, query: string, projectId: string, targetAccountId?: string, bucketName?: string, bucketPrefix?: string, _options?: Configuration): Promise<HttpInfo<QueryDBResponse>> {
    let requestContextValue = await this.requestFactory.queryDBSnapshot(snapshotId, databaseName, query, projectId, targetAccountId, bucketName, bucketPrefix, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.queryDBSnapshotWithHttpInfo(responseContext)
  }

  /**
   * Description: This API allows the user to issue a search request for database records based on provided snapshot and database parameters. The results can be stored in a specified target account and bucket.
   * Issue search request for database records
   * @param snapshotId snapshot ID
   * @param databaseName Database name to query
   * @param query SQL to execute on snapshot
   * @param projectId The project ID
   * @param targetAccountId The target account ID to store the query resutls
   * @param bucketName Bucket name to store query results
   * @param bucketPrefix Bucket key prefix when storing query results
   */
  public async queryDBSnapshot(snapshotId: string, databaseName: string, query: string, projectId: string, targetAccountId?: string, bucketName?: string, bucketPrefix?: string, _options?: Configuration): Promise<QueryDBResponse> {
    const responseWithInfo = await this.queryDBSnapshotWithHttpInfo(snapshotId, databaseName, query, projectId, targetAccountId, bucketName, bucketPrefix, _options);
    return responseWithInfo.data;
  }


}


import { QueryDBStatusApiRequestFactory, QueryDBStatusApiResponseProcessor} from "../apis/QueryDBStatusApi";

export class PromiseQueryDBStatusApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: QueryDBStatusApiRequestFactory = new QueryDBStatusApiRequestFactory(configuration),
    private responseProcessor: QueryDBStatusApiResponseProcessor = new QueryDBStatusApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Endpoint to check the current status of a database records search request by providing a Query ID and Project ID.
   * Check status of database records search request
   * @param queryId Query ID token
   * @param projectId The project ID
   */
  public async queryDBStatusWithHttpInfo(queryId: string, projectId: string, _options?: Configuration): Promise<HttpInfo<QueryDBStatusResponse>> {
    let requestContextValue = await this.requestFactory.queryDBStatus(queryId, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.queryDBStatusWithHttpInfo(responseContext)
  }

  /**
   * Description: Endpoint to check the current status of a database records search request by providing a Query ID and Project ID.
   * Check status of database records search request
   * @param queryId Query ID token
   * @param projectId The project ID
   */
  public async queryDBStatus(queryId: string, projectId: string, _options?: Configuration): Promise<QueryDBStatusResponse> {
    const responseWithInfo = await this.queryDBStatusWithHttpInfo(queryId, projectId, _options);
    return responseWithInfo.data;
  }


}


import { RefreshApiRequestFactory, RefreshApiResponseProcessor} from "../apis/RefreshApi";

export class PromiseRefreshApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: RefreshApiRequestFactory = new RefreshApiRequestFactory(configuration),
    private responseProcessor: RefreshApiResponseProcessor = new RefreshApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Endpoint to refresh the OAuth token and potentially redirect to a specified URL or the homepage.
   * refresh
   * @param redirectUrl the url to redirect to after refresh. should be the original url that was requested. If not provided, will redirect to homepage
   */
  public async refreshWithHttpInfo(redirectUrl?: string, _options?: Configuration): Promise<HttpInfo<Message>> {
    let requestContextValue = await this.requestFactory.refresh(redirectUrl, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.refreshWithHttpInfo(responseContext)
  }

  /**
   * Description: Endpoint to refresh the OAuth token and potentially redirect to a specified URL or the homepage.
   * refresh
   * @param redirectUrl the url to redirect to after refresh. should be the original url that was requested. If not provided, will redirect to homepage
   */
  public async refresh(redirectUrl?: string, _options?: Configuration): Promise<Message> {
    const responseWithInfo = await this.refreshWithHttpInfo(redirectUrl, _options);
    return responseWithInfo.data;
  }


}


import { RegisterApiRequestFactory, RegisterApiResponseProcessor} from "../apis/RegisterApi";

export class PromiseRegisterApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: RegisterApiRequestFactory = new RegisterApiRequestFactory(configuration),
    private responseProcessor: RegisterApiResponseProcessor = new RegisterApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint allows users to register a new account by providing payment token and initial login information.
   * Register a new account
   * @param registerInput Includes payment token, and initial login information
   */
  public async registerWithHttpInfo(registerInput: RegisterInput, _options?: Configuration): Promise<HttpInfo<RegisterResponse>> {
    let requestContextValue = await this.requestFactory.register(registerInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.registerWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint allows users to register a new account by providing payment token and initial login information.
   * Register a new account
   * @param registerInput Includes payment token, and initial login information
   */
  public async register(registerInput: RegisterInput, _options?: Configuration): Promise<RegisterResponse> {
    const responseWithInfo = await this.registerWithHttpInfo(registerInput, _options);
    return responseWithInfo.data;
  }


}


import { ResourcesApiRequestFactory, ResourcesApiResponseProcessor} from "../apis/ResourcesApi";

export class PromiseResourcesApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: ResourcesApiRequestFactory = new ResourcesApiRequestFactory(configuration),
    private responseProcessor: ResourcesApiResponseProcessor = new ResourcesApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Allows a resource to be backed up by Eon, scanned, and checked for violations. 
   * Cancel Resource Backup Exclusion
   * @param projectId ID of the resource\&#39;s parent project.
   * @param id ID of the resource to cancel the backup exclusion for.
   */
  public async cancelResourceBackupExclusionWithHttpInfo(projectId: string, id: string, _options?: Configuration): Promise<HttpInfo<CancelExclusionFromBackupResponse>> {
    let requestContextValue = await this.requestFactory.cancelResourceBackupExclusion(projectId, id, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.cancelResourceBackupExclusionWithHttpInfo(responseContext)
  }

  /**
   * Description: Allows a resource to be backed up by Eon, scanned, and checked for violations. 
   * Cancel Resource Backup Exclusion
   * @param projectId ID of the resource\&#39;s parent project.
   * @param id ID of the resource to cancel the backup exclusion for.
   */
  public async cancelResourceBackupExclusion(projectId: string, id: string, _options?: Configuration): Promise<CancelExclusionFromBackupResponse> {
    const responseWithInfo = await this.cancelResourceBackupExclusionWithHttpInfo(projectId, id, _options);
    return responseWithInfo.data;
  }

  /**
   * Description: Prevents a resource from being backed up by Eon and suppresses scanning and violations.  You can cancel this action by calling [Cancel Resource Backup Exclusion](cancel-resource-backup-exclusion) for the same resource. 
   * Exclude Resource from Backup
   * @param projectId ID of the resource\&#39;s parent project.
   * @param id ID of the resource to exclude from backup.
   */
  public async excludeResourceFromBackupWithHttpInfo(projectId: string, id: string, _options?: Configuration): Promise<HttpInfo<ExcludeFromBackupResponse>> {
    let requestContextValue = await this.requestFactory.excludeResourceFromBackup(projectId, id, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.excludeResourceFromBackupWithHttpInfo(responseContext)
  }

  /**
   * Description: Prevents a resource from being backed up by Eon and suppresses scanning and violations.  You can cancel this action by calling [Cancel Resource Backup Exclusion](cancel-resource-backup-exclusion) for the same resource. 
   * Exclude Resource from Backup
   * @param projectId ID of the resource\&#39;s parent project.
   * @param id ID of the resource to exclude from backup.
   */
  public async excludeResourceFromBackup(projectId: string, id: string, _options?: Configuration): Promise<ExcludeFromBackupResponse> {
    const responseWithInfo = await this.excludeResourceFromBackupWithHttpInfo(projectId, id, _options);
    return responseWithInfo.data;
  }

  /**
   * Description: Retrieves a resource by ID.
   * Get Resource
   * @param id ID of the resource to retrieve.
   * @param projectId ID of the resource\&#39;s parent project.
   */
  public async getResourceWithHttpInfo(id: string, projectId: string, _options?: Configuration): Promise<HttpInfo<GetResourceResponse>> {
    let requestContextValue = await this.requestFactory.getResource(id, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getResourceWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieves a resource by ID.
   * Get Resource
   * @param id ID of the resource to retrieve.
   * @param projectId ID of the resource\&#39;s parent project.
   */
  public async getResource(id: string, projectId: string, _options?: Configuration): Promise<GetResourceResponse> {
    const responseWithInfo = await this.getResourceWithHttpInfo(id, projectId, _options);
    return responseWithInfo.data;
  }

  /**
   * Description: Retrieves a list of resources for the given project.
   * List Resources
   * @param projectId ID of the project whose resources you want to retrieve.
   * @param pageToken Cursor that points to the first record of the next page of results. Get this value from the previous response. To preserve the results in the same order, use the same sorting and filters in the first request as all subsequent requests. 
   * @param pageSize Max number of items to return in the next page.
   * @param listInventoryRequest 
   */
  public async listResourcesWithHttpInfo(projectId: string, pageToken?: string, pageSize?: number, listInventoryRequest?: ListInventoryRequest, _options?: Configuration): Promise<HttpInfo<ListResourcesResponse>> {
    let requestContextValue = await this.requestFactory.listResources(projectId, pageToken, pageSize, listInventoryRequest, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listResourcesWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieves a list of resources for the given project.
   * List Resources
   * @param projectId ID of the project whose resources you want to retrieve.
   * @param pageToken Cursor that points to the first record of the next page of results. Get this value from the previous response. To preserve the results in the same order, use the same sorting and filters in the first request as all subsequent requests. 
   * @param pageSize Max number of items to return in the next page.
   * @param listInventoryRequest 
   */
  public async listResources(projectId: string, pageToken?: string, pageSize?: number, listInventoryRequest?: ListInventoryRequest, _options?: Configuration): Promise<ListResourcesResponse> {
    const responseWithInfo = await this.listResourcesWithHttpInfo(projectId, pageToken, pageSize, listInventoryRequest, _options);
    return responseWithInfo.data;
  }

  /**
   * Description: Manually sets a resource\'s data classes, overriding auto-classification.  You can revert to auto-classification of data classes by calling [Remove Data Classes Override](remove-data-classes-override) for the same resource. 
   * Override Data Classes
   * @param projectId ID of the resource\&#39;s parent project.
   * @param id ID of the resource to override.
   * @param overrideDataClassificationsRequest 
   */
  public async overrideDataClassesWithHttpInfo(projectId: string, id: string, overrideDataClassificationsRequest: OverrideDataClassificationsRequest, _options?: Configuration): Promise<HttpInfo<OverrideDataClassificationsResponse>> {
    let requestContextValue = await this.requestFactory.overrideDataClasses(projectId, id, overrideDataClassificationsRequest, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.overrideDataClassesWithHttpInfo(responseContext)
  }

  /**
   * Description: Manually sets a resource\'s data classes, overriding auto-classification.  You can revert to auto-classification of data classes by calling [Remove Data Classes Override](remove-data-classes-override) for the same resource. 
   * Override Data Classes
   * @param projectId ID of the resource\&#39;s parent project.
   * @param id ID of the resource to override.
   * @param overrideDataClassificationsRequest 
   */
  public async overrideDataClasses(projectId: string, id: string, overrideDataClassificationsRequest: OverrideDataClassificationsRequest, _options?: Configuration): Promise<OverrideDataClassificationsResponse> {
    const responseWithInfo = await this.overrideDataClassesWithHttpInfo(projectId, id, overrideDataClassificationsRequest, _options);
    return responseWithInfo.data;
  }

  /**
   * Description: Manually sets a resource\'s environment, overriding auto-classification.  You can revert to auto-classification of environment by calling [Remove Environment Override](remove-environment-override) for the same resource. 
   * Override Environment
   * @param projectId ID of the resource\&#39;s parent project.
   * @param id ID of the resource to override.
   * @param overrideEnvironmentRequest 
   */
  public async overrideEnvironmentWithHttpInfo(projectId: string, id: string, overrideEnvironmentRequest: OverrideEnvironmentRequest, _options?: Configuration): Promise<HttpInfo<OverrideEnvironmentResponse>> {
    let requestContextValue = await this.requestFactory.overrideEnvironment(projectId, id, overrideEnvironmentRequest, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.overrideEnvironmentWithHttpInfo(responseContext)
  }

  /**
   * Description: Manually sets a resource\'s environment, overriding auto-classification.  You can revert to auto-classification of environment by calling [Remove Environment Override](remove-environment-override) for the same resource. 
   * Override Environment
   * @param projectId ID of the resource\&#39;s parent project.
   * @param id ID of the resource to override.
   * @param overrideEnvironmentRequest 
   */
  public async overrideEnvironment(projectId: string, id: string, overrideEnvironmentRequest: OverrideEnvironmentRequest, _options?: Configuration): Promise<OverrideEnvironmentResponse> {
    const responseWithInfo = await this.overrideEnvironmentWithHttpInfo(projectId, id, overrideEnvironmentRequest, _options);
    return responseWithInfo.data;
  }

  /**
   * Description: Removes a resource\'s data classes override, which re-enables auto-classification for the resource. 
   * Remove Data Classes Override
   * @param projectId ID of the resource\&#39;s parent project.
   * @param id ID of the resource to remove the override from.
   */
  public async removeDataClassesOverrideWithHttpInfo(projectId: string, id: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.removeDataClassesOverride(projectId, id, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.removeDataClassesOverrideWithHttpInfo(responseContext)
  }

  /**
   * Description: Removes a resource\'s data classes override, which re-enables auto-classification for the resource. 
   * Remove Data Classes Override
   * @param projectId ID of the resource\&#39;s parent project.
   * @param id ID of the resource to remove the override from.
   */
  public async removeDataClassesOverride(projectId: string, id: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.removeDataClassesOverrideWithHttpInfo(projectId, id, _options);
    return responseWithInfo.data;
  }

  /**
   * Description: Removes a resource\'s environment override, which re-enables auto-classification for the resource. 
   * Remove Environment Override
   * @param projectId ID of the resource\&#39;s parent project.
   * @param id ID of the resource to remove the override from.
   */
  public async removeEnvironmentOverrideWithHttpInfo(projectId: string, id: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.removeEnvironmentOverride(projectId, id, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.removeEnvironmentOverrideWithHttpInfo(responseContext)
  }

  /**
   * Description: Removes a resource\'s environment override, which re-enables auto-classification for the resource. 
   * Remove Environment Override
   * @param projectId ID of the resource\&#39;s parent project.
   * @param id ID of the resource to remove the override from.
   */
  public async removeEnvironmentOverride(projectId: string, id: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.removeEnvironmentOverrideWithHttpInfo(projectId, id, _options);
    return responseWithInfo.data;
  }


}


import { RestoreAccountsApiRequestFactory, RestoreAccountsApiResponseProcessor} from "../apis/RestoreAccountsApi";

export class PromiseRestoreAccountsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: RestoreAccountsApiRequestFactory = new RestoreAccountsApiRequestFactory(configuration),
    private responseProcessor: RestoreAccountsApiResponseProcessor = new RestoreAccountsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieve a list of all restore accounts associated with a specific project using its project ID.
   * List Restore Accounts
   * @param projectId The project ID
   */
  public async restoreAccountsWithHttpInfo(projectId: string, _options?: Configuration): Promise<HttpInfo<ListRestoreAccountsResponse>> {
    let requestContextValue = await this.requestFactory.restoreAccounts(projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.restoreAccountsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieve a list of all restore accounts associated with a specific project using its project ID.
   * List Restore Accounts
   * @param projectId The project ID
   */
  public async restoreAccounts(projectId: string, _options?: Configuration): Promise<ListRestoreAccountsResponse> {
    const responseWithInfo = await this.restoreAccountsWithHttpInfo(projectId, _options);
    return responseWithInfo.data;
  }


}


import { RestoreAtlasClusterApiRequestFactory, RestoreAtlasClusterApiResponseProcessor} from "../apis/RestoreAtlasClusterApi";

export class PromiseRestoreAtlasClusterApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: RestoreAtlasClusterApiRequestFactory = new RestoreAtlasClusterApiRequestFactory(configuration),
    private responseProcessor: RestoreAtlasClusterApiResponseProcessor = new RestoreAtlasClusterApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API initiates the process of restoring a database from a specified snapshot.
   * Start a database restore from snapshot
   * @param projectId The project ID
   * @param id The inventory item ID
   * @param snapshotId The snapshot ID
   * @param restoreAtlasClusterInput The request body for restoring files
   */
  public async restoreAtlasClusterWithHttpInfo(projectId: string, id: string, snapshotId: string, restoreAtlasClusterInput: RestoreAtlasClusterInput, _options?: Configuration): Promise<HttpInfo<Identifier>> {
    let requestContextValue = await this.requestFactory.restoreAtlasCluster(projectId, id, snapshotId, restoreAtlasClusterInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.restoreAtlasClusterWithHttpInfo(responseContext)
  }

  /**
   * Description: This API initiates the process of restoring a database from a specified snapshot.
   * Start a database restore from snapshot
   * @param projectId The project ID
   * @param id The inventory item ID
   * @param snapshotId The snapshot ID
   * @param restoreAtlasClusterInput The request body for restoring files
   */
  public async restoreAtlasCluster(projectId: string, id: string, snapshotId: string, restoreAtlasClusterInput: RestoreAtlasClusterInput, _options?: Configuration): Promise<Identifier> {
    const responseWithInfo = await this.restoreAtlasClusterWithHttpInfo(projectId, id, snapshotId, restoreAtlasClusterInput, _options);
    return responseWithInfo.data;
  }


}


import { RestoreInstanceApiRequestFactory, RestoreInstanceApiResponseProcessor} from "../apis/RestoreInstanceApi";

export class PromiseRestoreInstanceApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: RestoreInstanceApiRequestFactory = new RestoreInstanceApiRequestFactory(configuration),
    private responseProcessor: RestoreInstanceApiResponseProcessor = new RestoreInstanceApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API initiates the process of restoring a instance from a specified snapshot.
   * Start an instance restore from snapshot
   * @param projectId The project ID
   * @param id The inventory item ID
   * @param snapshotId The snapshot ID
   * @param restoreInstanceInput The request body for restoring the instance
   */
  public async restoreInstanceWithHttpInfo(projectId: string, id: string, snapshotId: string, restoreInstanceInput: RestoreInstanceInput, _options?: Configuration): Promise<HttpInfo<Identifier>> {
    let requestContextValue = await this.requestFactory.restoreInstance(projectId, id, snapshotId, restoreInstanceInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.restoreInstanceWithHttpInfo(responseContext)
  }

  /**
   * Description: This API initiates the process of restoring a instance from a specified snapshot.
   * Start an instance restore from snapshot
   * @param projectId The project ID
   * @param id The inventory item ID
   * @param snapshotId The snapshot ID
   * @param restoreInstanceInput The request body for restoring the instance
   */
  public async restoreInstance(projectId: string, id: string, snapshotId: string, restoreInstanceInput: RestoreInstanceInput, _options?: Configuration): Promise<Identifier> {
    const responseWithInfo = await this.restoreInstanceWithHttpInfo(projectId, id, snapshotId, restoreInstanceInput, _options);
    return responseWithInfo.data;
  }


}


import { RotateIntegrationSecretApiRequestFactory, RotateIntegrationSecretApiResponseProcessor} from "../apis/RotateIntegrationSecretApi";

export class PromiseRotateIntegrationSecretApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: RotateIntegrationSecretApiRequestFactory = new RotateIntegrationSecretApiRequestFactory(configuration),
    private responseProcessor: RotateIntegrationSecretApiResponseProcessor = new RotateIntegrationSecretApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint allows an admin to rotate an integration by their ID.
   * Rotate an integration token
   * @param clientId Client ID of the integration
   */
  public async rotateIntegrationTokenWithHttpInfo(clientId: string, _options?: Configuration): Promise<HttpInfo<IntegrationCredentials>> {
    let requestContextValue = await this.requestFactory.rotateIntegrationToken(clientId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.rotateIntegrationTokenWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint allows an admin to rotate an integration by their ID.
   * Rotate an integration token
   * @param clientId Client ID of the integration
   */
  public async rotateIntegrationToken(clientId: string, _options?: Configuration): Promise<IntegrationCredentials> {
    const responseWithInfo = await this.rotateIntegrationTokenWithHttpInfo(clientId, _options);
    return responseWithInfo.data;
  }


}


import { SearchApiRequestFactory, SearchApiResponseProcessor} from "../apis/SearchApi";

export class PromiseSearchApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: SearchApiRequestFactory = new SearchApiRequestFactory(configuration),
    private responseProcessor: SearchApiResponseProcessor = new SearchApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API allows searching for records within a specified project with pagination support.
   * Search for records
   * @param projectId The project ID
   * @param searchInput The request body for searching records
   */
  public async searchWithHttpInfo(projectId: string, searchInput: SearchInput, _options?: Configuration): Promise<HttpInfo<SearchFilesResponse>> {
    let requestContextValue = await this.requestFactory.search(projectId, searchInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.searchWithHttpInfo(responseContext)
  }

  /**
   * Description: This API allows searching for records within a specified project with pagination support.
   * Search for records
   * @param projectId The project ID
   * @param searchInput The request body for searching records
   */
  public async search(projectId: string, searchInput: SearchInput, _options?: Configuration): Promise<SearchFilesResponse> {
    const responseWithInfo = await this.searchWithHttpInfo(projectId, searchInput, _options);
    return responseWithInfo.data;
  }


}


import { SearchPopulateApiRequestFactory, SearchPopulateApiResponseProcessor} from "../apis/SearchPopulateApi";

export class PromiseSearchPopulateApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: SearchPopulateApiRequestFactory = new SearchPopulateApiRequestFactory(configuration),
    private responseProcessor: SearchPopulateApiResponseProcessor = new SearchPopulateApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint is used to populate test data for a specific project. It requires the project ID and the version number of the data to be populated.
   * Populate test data
   * @param version the version number of the data to populate
   * @param projectId The project ID
   */
  public async searchPopulateWithHttpInfo(version: number, projectId: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.searchPopulate(version, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.searchPopulateWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint is used to populate test data for a specific project. It requires the project ID and the version number of the data to be populated.
   * Populate test data
   * @param version the version number of the data to populate
   * @param projectId The project ID
   */
  public async searchPopulate(version: number, projectId: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.searchPopulateWithHttpInfo(version, projectId, _options);
    return responseWithInfo.data;
  }


}


import { SearchPopulateDeleteApiRequestFactory, SearchPopulateDeleteApiResponseProcessor} from "../apis/SearchPopulateDeleteApi";

export class PromiseSearchPopulateDeleteApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: SearchPopulateDeleteApiRequestFactory = new SearchPopulateDeleteApiRequestFactory(configuration),
    private responseProcessor: SearchPopulateDeleteApiResponseProcessor = new SearchPopulateDeleteApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Deletes test data associated with a given project ID.
   * Delete test data
   * @param projectId The project ID
   */
  public async searchPopulateDeleteWithHttpInfo(projectId: string, _options?: Configuration): Promise<HttpInfo<void>> {
    let requestContextValue = await this.requestFactory.searchPopulateDelete(projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.searchPopulateDeleteWithHttpInfo(responseContext)
  }

  /**
   * Description: Deletes test data associated with a given project ID.
   * Delete test data
   * @param projectId The project ID
   */
  public async searchPopulateDelete(projectId: string, _options?: Configuration): Promise<void> {
    const responseWithInfo = await this.searchPopulateDeleteWithHttpInfo(projectId, _options);
    return responseWithInfo.data;
  }


}


import { SnapshotsApiRequestFactory, SnapshotsApiResponseProcessor} from "../apis/SnapshotsApi";

export class PromiseSnapshotsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: SnapshotsApiRequestFactory = new SnapshotsApiRequestFactory(configuration),
    private responseProcessor: SnapshotsApiResponseProcessor = new SnapshotsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Retrieves an Eon snapshot.
   * Get Snapshot
   * @param id ID of the Eon snapshot to retrieve.
   * @param projectId ID of the snapshot\&#39;s parent project.
   */
  public async getSnapshotWithHttpInfo(id: string, projectId: string, _options?: Configuration): Promise<HttpInfo<GetSnapshotResponse>> {
    let requestContextValue = await this.requestFactory.getSnapshot(id, projectId, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.getSnapshotWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieves an Eon snapshot.
   * Get Snapshot
   * @param id ID of the Eon snapshot to retrieve.
   * @param projectId ID of the snapshot\&#39;s parent project.
   */
  public async getSnapshot(id: string, projectId: string, _options?: Configuration): Promise<GetSnapshotResponse> {
    const responseWithInfo = await this.getSnapshotWithHttpInfo(id, projectId, _options);
    return responseWithInfo.data;
  }

  /**
   * Description: Retrieves a list of Eon snapshots for the given resource. 
   * List Resource Snapshots
   * @param id ID of the resource whose Eon snapshots you want to retrieve.
   * @param projectId ID of the resource\&#39;s parent project.
   * @param pageToken Cursor that points to the first record of the next page of results. Get this value from the previous response. To preserve the results in the same order, use the same sorting and filters in the first request as all subsequent requests. 
   * @param pageSize Max number of items to return in the next page.
   */
  public async listResourceSnapshotsWithHttpInfo(id: string, projectId: string, pageToken?: string, pageSize?: number, _options?: Configuration): Promise<HttpInfo<ListInventorySnapshotsResponse>> {
    let requestContextValue = await this.requestFactory.listResourceSnapshots(id, projectId, pageToken, pageSize, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.listResourceSnapshotsWithHttpInfo(responseContext)
  }

  /**
   * Description: Retrieves a list of Eon snapshots for the given resource. 
   * List Resource Snapshots
   * @param id ID of the resource whose Eon snapshots you want to retrieve.
   * @param projectId ID of the resource\&#39;s parent project.
   * @param pageToken Cursor that points to the first record of the next page of results. Get this value from the previous response. To preserve the results in the same order, use the same sorting and filters in the first request as all subsequent requests. 
   * @param pageSize Max number of items to return in the next page.
   */
  public async listResourceSnapshots(id: string, projectId: string, pageToken?: string, pageSize?: number, _options?: Configuration): Promise<ListInventorySnapshotsResponse> {
    const responseWithInfo = await this.listResourceSnapshotsWithHttpInfo(id, projectId, pageToken, pageSize, _options);
    return responseWithInfo.data;
  }

  /**
   * Description: Restores a bucket from an Eon snapshot.  This operation is asynchronous. It triggers a restore job and returns a job ID, which can be used to track the progress of the restore job. You can see your jobs in the [Restore tab of the Jobs page](eonconsole:///jobs/restore) in the console.  :::important To avoid additional costs, delete the restored data as soon as you\'re done with it. ::: 
   * Restore Bucket
   * @param projectId ID of the snapshot\&#39;s parent project.
   * @param id Eon-assigned resource ID.
   * @param snapshotId ID of the Eon snapshot to restore.
   * @param restoreBucketRequest 
   */
  public async restoreBucketWithHttpInfo(projectId: string, id: string, snapshotId: string, restoreBucketRequest: RestoreBucketRequest, _options?: Configuration): Promise<HttpInfo<RestoreJobInitiationResponse>> {
    let requestContextValue = await this.requestFactory.restoreBucket(projectId, id, snapshotId, restoreBucketRequest, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.restoreBucketWithHttpInfo(responseContext)
  }

  /**
   * Description: Restores a bucket from an Eon snapshot.  This operation is asynchronous. It triggers a restore job and returns a job ID, which can be used to track the progress of the restore job. You can see your jobs in the [Restore tab of the Jobs page](eonconsole:///jobs/restore) in the console.  :::important To avoid additional costs, delete the restored data as soon as you\'re done with it. ::: 
   * Restore Bucket
   * @param projectId ID of the snapshot\&#39;s parent project.
   * @param id Eon-assigned resource ID.
   * @param snapshotId ID of the Eon snapshot to restore.
   * @param restoreBucketRequest 
   */
  public async restoreBucket(projectId: string, id: string, snapshotId: string, restoreBucketRequest: RestoreBucketRequest, _options?: Configuration): Promise<RestoreJobInitiationResponse> {
    const responseWithInfo = await this.restoreBucketWithHttpInfo(projectId, id, snapshotId, restoreBucketRequest, _options);
    return responseWithInfo.data;
  }

  /**
   * Description: Restores a database from an Eon snapshot.  This operation is asynchronous. It triggers a restore job and returns a job ID, which can be used to track the progress of the restore job. You can see your jobs in the [Restore tab of the Jobs page](eonconsole:///jobs/restore) in the console.  :::important To avoid additional costs, delete the restored data as soon as you\'re done with it. ::: 
   * Restore Database
   * @param projectId ID of the snapshot\&#39;s parent project.
   * @param id Eon-assigned resource ID.
   * @param snapshotId ID of the Eon snapshot to restore.
   * @param restoreDbToRdsInstanceRequest 
   */
  public async restoreDbToRdsInstanceWithHttpInfo(projectId: string, id: string, snapshotId: string, restoreDbToRdsInstanceRequest: RestoreDbToRdsInstanceRequest, _options?: Configuration): Promise<HttpInfo<RestoreJobInitiationResponse>> {
    let requestContextValue = await this.requestFactory.restoreDbToRdsInstance(projectId, id, snapshotId, restoreDbToRdsInstanceRequest, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.restoreDbToRdsInstanceWithHttpInfo(responseContext)
  }

  /**
   * Description: Restores a database from an Eon snapshot.  This operation is asynchronous. It triggers a restore job and returns a job ID, which can be used to track the progress of the restore job. You can see your jobs in the [Restore tab of the Jobs page](eonconsole:///jobs/restore) in the console.  :::important To avoid additional costs, delete the restored data as soon as you\'re done with it. ::: 
   * Restore Database
   * @param projectId ID of the snapshot\&#39;s parent project.
   * @param id Eon-assigned resource ID.
   * @param snapshotId ID of the Eon snapshot to restore.
   * @param restoreDbToRdsInstanceRequest 
   */
  public async restoreDbToRdsInstance(projectId: string, id: string, snapshotId: string, restoreDbToRdsInstanceRequest: RestoreDbToRdsInstanceRequest, _options?: Configuration): Promise<RestoreJobInitiationResponse> {
    const responseWithInfo = await this.restoreDbToRdsInstanceWithHttpInfo(projectId, id, snapshotId, restoreDbToRdsInstanceRequest, _options);
    return responseWithInfo.data;
  }

  /**
   * Description: This API initiates the process of restoring a database from a specified snapshot.
   * Start a database restore from snapshot
   * @param projectId The project ID
   * @param id The inventory item ID
   * @param snapshotId The snapshot ID
   * @param restoreDbToRdsSnapshotRequest The request body for restoring files
   */
  public async restoreDbToRdsSnapshotWithHttpInfo(projectId: string, id: string, snapshotId: string, restoreDbToRdsSnapshotRequest: RestoreDbToRdsSnapshotRequest, _options?: Configuration): Promise<HttpInfo<RestoreJobInitiationResponse>> {
    let requestContextValue = await this.requestFactory.restoreDbToRdsSnapshot(projectId, id, snapshotId, restoreDbToRdsSnapshotRequest, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.restoreDbToRdsSnapshotWithHttpInfo(responseContext)
  }

  /**
   * Description: This API initiates the process of restoring a database from a specified snapshot.
   * Start a database restore from snapshot
   * @param projectId The project ID
   * @param id The inventory item ID
   * @param snapshotId The snapshot ID
   * @param restoreDbToRdsSnapshotRequest The request body for restoring files
   */
  public async restoreDbToRdsSnapshot(projectId: string, id: string, snapshotId: string, restoreDbToRdsSnapshotRequest: RestoreDbToRdsSnapshotRequest, _options?: Configuration): Promise<RestoreJobInitiationResponse> {
    const responseWithInfo = await this.restoreDbToRdsSnapshotWithHttpInfo(projectId, id, snapshotId, restoreDbToRdsSnapshotRequest, _options);
    return responseWithInfo.data;
  }

  /**
   * Description: Restores files from an Eon snapshot.  This operation is asynchronous. It triggers a restore job and returns a job ID, which can be used to track the progress of the restore job. You can see your jobs in the [Restore tab of the Jobs page](eonconsole:///jobs/restore) in the console.  :::important To avoid additional costs, delete the restored data as soon as you\'re done with it. ::: 
   * Restore Files
   * @param projectId ID of the snapshot\&#39;s parent project.
   * @param id Eon-assigned resource ID.
   * @param snapshotId ID of the Eon snapshot to restore.
   * @param restoreFilesRequest 
   */
  public async restoreFilesWithHttpInfo(projectId: string, id: string, snapshotId: string, restoreFilesRequest: RestoreFilesRequest, _options?: Configuration): Promise<HttpInfo<RestoreJobInitiationResponse>> {
    let requestContextValue = await this.requestFactory.restoreFiles(projectId, id, snapshotId, restoreFilesRequest, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.restoreFilesWithHttpInfo(responseContext)
  }

  /**
   * Description: Restores files from an Eon snapshot.  This operation is asynchronous. It triggers a restore job and returns a job ID, which can be used to track the progress of the restore job. You can see your jobs in the [Restore tab of the Jobs page](eonconsole:///jobs/restore) in the console.  :::important To avoid additional costs, delete the restored data as soon as you\'re done with it. ::: 
   * Restore Files
   * @param projectId ID of the snapshot\&#39;s parent project.
   * @param id Eon-assigned resource ID.
   * @param snapshotId ID of the Eon snapshot to restore.
   * @param restoreFilesRequest 
   */
  public async restoreFiles(projectId: string, id: string, snapshotId: string, restoreFilesRequest: RestoreFilesRequest, _options?: Configuration): Promise<RestoreJobInitiationResponse> {
    const responseWithInfo = await this.restoreFilesWithHttpInfo(projectId, id, snapshotId, restoreFilesRequest, _options);
    return responseWithInfo.data;
  }

  /**
   * Description: Restores a volume from Eon an snapshot.  This operation is asynchronous. It triggers a restore job and returns a job ID, which can be used to track the progress of the restore job. You can see your jobs in the [Restore tab of the Jobs page](eonconsole:///jobs/restore) in the console.  :::important To avoid additional costs, delete the restored data as soon as you\'re done with it. ::: 
   * Restore Volume
   * @param projectId ID of the snapshot\&#39;s parent project.
   * @param id Eon-assigned resource ID.
   * @param snapshotId ID of the Eon snapshot to restore.
   * @param restoreVolumeToEbsRequest 
   */
  public async restoreVolumeToEbsWithHttpInfo(projectId: string, id: string, snapshotId: string, restoreVolumeToEbsRequest: RestoreVolumeToEbsRequest, _options?: Configuration): Promise<HttpInfo<RestoreJobInitiationResponse>> {
    let requestContextValue = await this.requestFactory.restoreVolumeToEbs(projectId, id, snapshotId, restoreVolumeToEbsRequest, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.restoreVolumeToEbsWithHttpInfo(responseContext)
  }

  /**
   * Description: Restores a volume from Eon an snapshot.  This operation is asynchronous. It triggers a restore job and returns a job ID, which can be used to track the progress of the restore job. You can see your jobs in the [Restore tab of the Jobs page](eonconsole:///jobs/restore) in the console.  :::important To avoid additional costs, delete the restored data as soon as you\'re done with it. ::: 
   * Restore Volume
   * @param projectId ID of the snapshot\&#39;s parent project.
   * @param id Eon-assigned resource ID.
   * @param snapshotId ID of the Eon snapshot to restore.
   * @param restoreVolumeToEbsRequest 
   */
  public async restoreVolumeToEbs(projectId: string, id: string, snapshotId: string, restoreVolumeToEbsRequest: RestoreVolumeToEbsRequest, _options?: Configuration): Promise<RestoreJobInitiationResponse> {
    const responseWithInfo = await this.restoreVolumeToEbsWithHttpInfo(projectId, id, snapshotId, restoreVolumeToEbsRequest, _options);
    return responseWithInfo.data;
  }

  /**
   * Description: Restores an EBS snapshot from an Eon snapshot.  This operation is asynchronous. It triggers a restore job and returns a job ID, which can be used to track the progress of the restore job. You can see your jobs in the [Restore tab of the Jobs page](eonconsole:///jobs/restore) in the console.  :::important To avoid additional costs, delete the restored data as soon as you\'re done with it. ::: 
   * Restore Volume to EBS Snapshot
   * @param projectId ID of the snapshot\&#39;s parent project.
   * @param id Eon-assigned resource ID.
   * @param snapshotId ID of the Eon snapshot to restore.
   * @param restoreVolumeToEbsSnapshotRequest 
   */
  public async restoreVolumeToEbsSnapshotWithHttpInfo(projectId: string, id: string, snapshotId: string, restoreVolumeToEbsSnapshotRequest: RestoreVolumeToEbsSnapshotRequest, _options?: Configuration): Promise<HttpInfo<RestoreJobInitiationResponse>> {
    let requestContextValue = await this.requestFactory.restoreVolumeToEbsSnapshot(projectId, id, snapshotId, restoreVolumeToEbsSnapshotRequest, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.restoreVolumeToEbsSnapshotWithHttpInfo(responseContext)
  }

  /**
   * Description: Restores an EBS snapshot from an Eon snapshot.  This operation is asynchronous. It triggers a restore job and returns a job ID, which can be used to track the progress of the restore job. You can see your jobs in the [Restore tab of the Jobs page](eonconsole:///jobs/restore) in the console.  :::important To avoid additional costs, delete the restored data as soon as you\'re done with it. ::: 
   * Restore Volume to EBS Snapshot
   * @param projectId ID of the snapshot\&#39;s parent project.
   * @param id Eon-assigned resource ID.
   * @param snapshotId ID of the Eon snapshot to restore.
   * @param restoreVolumeToEbsSnapshotRequest 
   */
  public async restoreVolumeToEbsSnapshot(projectId: string, id: string, snapshotId: string, restoreVolumeToEbsSnapshotRequest: RestoreVolumeToEbsSnapshotRequest, _options?: Configuration): Promise<RestoreJobInitiationResponse> {
    const responseWithInfo = await this.restoreVolumeToEbsSnapshotWithHttpInfo(projectId, id, snapshotId, restoreVolumeToEbsSnapshotRequest, _options);
    return responseWithInfo.data;
  }


}


import { UpdateAccountOverrideApiRequestFactory, UpdateAccountOverrideApiResponseProcessor} from "../apis/UpdateAccountOverrideApi";

export class PromiseUpdateAccountOverrideApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateAccountOverrideApiRequestFactory = new UpdateAccountOverrideApiRequestFactory(configuration),
    private responseProcessor: UpdateAccountOverrideApiResponseProcessor = new UpdateAccountOverrideApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Update an existing account by overriding its current settings.
   * Update a account - override
   * @param id ID of the account to override
   * @param updateAccountInput The request body for updating an override
   */
  public async updateAccountOverrideWithHttpInfo(id: string, updateAccountInput: UpdateAccountInput, _options?: Configuration): Promise<HttpInfo<EonAccount>> {
    let requestContextValue = await this.requestFactory.updateAccountOverride(id, updateAccountInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateAccountOverrideWithHttpInfo(responseContext)
  }

  /**
   * Description: Update an existing account by overriding its current settings.
   * Update a account - override
   * @param id ID of the account to override
   * @param updateAccountInput The request body for updating an override
   */
  public async updateAccountOverride(id: string, updateAccountInput: UpdateAccountInput, _options?: Configuration): Promise<EonAccount> {
    const responseWithInfo = await this.updateAccountOverrideWithHttpInfo(id, updateAccountInput, _options);
    return responseWithInfo.data;
  }


}


import { UpdateAccountUIPreferencesApiRequestFactory, UpdateAccountUIPreferencesApiResponseProcessor} from "../apis/UpdateAccountUIPreferencesApi";

export class PromiseUpdateAccountUIPreferencesApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateAccountUIPreferencesApiRequestFactory = new UpdateAccountUIPreferencesApiRequestFactory(configuration),
    private responseProcessor: UpdateAccountUIPreferencesApiResponseProcessor = new UpdateAccountUIPreferencesApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint allows updating the UI preferences at the organization level. It requires the key of the preference to be updated and a request body containing the new preference settings.
   * Update UI preferences at organization level
   * @param key Key of the preference
   * @param requestBody The request body for updating account UI preferences
   */
  public async updateAccountUIPreferencesWithHttpInfo(key: string, requestBody: { [key: string]: any; }, _options?: Configuration): Promise<HttpInfo<Preferences>> {
    let requestContextValue = await this.requestFactory.updateAccountUIPreferences(key, requestBody, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateAccountUIPreferencesWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint allows updating the UI preferences at the organization level. It requires the key of the preference to be updated and a request body containing the new preference settings.
   * Update UI preferences at organization level
   * @param key Key of the preference
   * @param requestBody The request body for updating account UI preferences
   */
  public async updateAccountUIPreferences(key: string, requestBody: { [key: string]: any; }, _options?: Configuration): Promise<Preferences> {
    const responseWithInfo = await this.updateAccountUIPreferencesWithHttpInfo(key, requestBody, _options);
    return responseWithInfo.data;
  }


}


import { UpdateBackupPolicyApiRequestFactory, UpdateBackupPolicyApiResponseProcessor} from "../apis/UpdateBackupPolicyApi";

export class PromiseUpdateBackupPolicyApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateBackupPolicyApiRequestFactory = new UpdateBackupPolicyApiRequestFactory(configuration),
    private responseProcessor: UpdateBackupPolicyApiResponseProcessor = new UpdateBackupPolicyApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API updates an existing backup policy identified by its ID and project ID.
   * Update a policy
   * @param id ID of the policy to update
   * @param projectId The project ID
   * @param backupPolicy The request body for updating a policy
   */
  public async updatePolicyWithHttpInfo(id: string, projectId: string, backupPolicy: BackupPolicy, _options?: Configuration): Promise<HttpInfo<BackupPolicy>> {
    let requestContextValue = await this.requestFactory.updatePolicy(id, projectId, backupPolicy, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updatePolicyWithHttpInfo(responseContext)
  }

  /**
   * Description: This API updates an existing backup policy identified by its ID and project ID.
   * Update a policy
   * @param id ID of the policy to update
   * @param projectId The project ID
   * @param backupPolicy The request body for updating a policy
   */
  public async updatePolicy(id: string, projectId: string, backupPolicy: BackupPolicy, _options?: Configuration): Promise<BackupPolicy> {
    const responseWithInfo = await this.updatePolicyWithHttpInfo(id, projectId, backupPolicy, _options);
    return responseWithInfo.data;
  }


}


import { UpdateBackupVaultNameApiRequestFactory, UpdateBackupVaultNameApiResponseProcessor} from "../apis/UpdateBackupVaultNameApi";

export class PromiseUpdateBackupVaultNameApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateBackupVaultNameApiRequestFactory = new UpdateBackupVaultNameApiRequestFactory(configuration),
    private responseProcessor: UpdateBackupVaultNameApiResponseProcessor = new UpdateBackupVaultNameApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: API to update the name of an existing backup vault in a specified project.
   * Update a backup vault name
   * @param id ID of the backup vault to update
   * @param projectId The project ID
   * @param updateBackupVaultNameInput The request body for creating an updated backup vault
   */
  public async updateVaultNameWithHttpInfo(id: string, projectId: string, updateBackupVaultNameInput: UpdateBackupVaultNameInput, _options?: Configuration): Promise<HttpInfo<BackupVault>> {
    let requestContextValue = await this.requestFactory.updateVaultName(id, projectId, updateBackupVaultNameInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateVaultNameWithHttpInfo(responseContext)
  }

  /**
   * Description: API to update the name of an existing backup vault in a specified project.
   * Update a backup vault name
   * @param id ID of the backup vault to update
   * @param projectId The project ID
   * @param updateBackupVaultNameInput The request body for creating an updated backup vault
   */
  public async updateVaultName(id: string, projectId: string, updateBackupVaultNameInput: UpdateBackupVaultNameInput, _options?: Configuration): Promise<BackupVault> {
    const responseWithInfo = await this.updateVaultNameWithHttpInfo(id, projectId, updateBackupVaultNameInput, _options);
    return responseWithInfo.data;
  }


}


import { UpdateControlApiRequestFactory, UpdateControlApiResponseProcessor} from "../apis/UpdateControlApi";

export class PromiseUpdateControlApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateControlApiRequestFactory = new UpdateControlApiRequestFactory(configuration),
    private responseProcessor: UpdateControlApiResponseProcessor = new UpdateControlApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: API for updating a specific control in a project. Requires the control ID and project ID as path parameters, and the control data as request body. Returns updated control data on success.
   * Update a control
   * @param id ID of the control to update
   * @param projectId The project ID
   * @param control The request body for updating a control
   */
  public async updateControlWithHttpInfo(id: string, projectId: string, control: Control, _options?: Configuration): Promise<HttpInfo<Control>> {
    let requestContextValue = await this.requestFactory.updateControl(id, projectId, control, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateControlWithHttpInfo(responseContext)
  }

  /**
   * Description: API for updating a specific control in a project. Requires the control ID and project ID as path parameters, and the control data as request body. Returns updated control data on success.
   * Update a control
   * @param id ID of the control to update
   * @param projectId The project ID
   * @param control The request body for updating a control
   */
  public async updateControl(id: string, projectId: string, control: Control, _options?: Configuration): Promise<Control> {
    const responseWithInfo = await this.updateControlWithHttpInfo(id, projectId, control, _options);
    return responseWithInfo.data;
  }


}


import { UpdateControlViolationApiRequestFactory, UpdateControlViolationApiResponseProcessor} from "../apis/UpdateControlViolationApi";

export class PromiseUpdateControlViolationApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateControlViolationApiRequestFactory = new UpdateControlViolationApiRequestFactory(configuration),
    private responseProcessor: UpdateControlViolationApiResponseProcessor = new UpdateControlViolationApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: An operation to update details of a specific control violation identified by ID and project ID.
   * Update a control violation
   * @param id ID of the inventory item
   * @param projectId The project ID
   * @param updateControlViolationInput The request body for updating a control violation
   */
  public async updateControlViolationWithHttpInfo(id: string, projectId: string, updateControlViolationInput: UpdateControlViolationInput, _options?: Configuration): Promise<HttpInfo<ControlViolation>> {
    let requestContextValue = await this.requestFactory.updateControlViolation(id, projectId, updateControlViolationInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateControlViolationWithHttpInfo(responseContext)
  }

  /**
   * Description: An operation to update details of a specific control violation identified by ID and project ID.
   * Update a control violation
   * @param id ID of the inventory item
   * @param projectId The project ID
   * @param updateControlViolationInput The request body for updating a control violation
   */
  public async updateControlViolation(id: string, projectId: string, updateControlViolationInput: UpdateControlViolationInput, _options?: Configuration): Promise<ControlViolation> {
    const responseWithInfo = await this.updateControlViolationWithHttpInfo(id, projectId, updateControlViolationInput, _options);
    return responseWithInfo.data;
  }


}


import { UpdateIntegrationApiRequestFactory, UpdateIntegrationApiResponseProcessor} from "../apis/UpdateIntegrationApi";

export class PromiseUpdateIntegrationApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateIntegrationApiRequestFactory = new UpdateIntegrationApiRequestFactory(configuration),
    private responseProcessor: UpdateIntegrationApiResponseProcessor = new UpdateIntegrationApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API updates the details of an existing integration identified by their ID. It overrides the api\'s information with the provided data.
   * Update an integration
   * @param clientId Client ID of the integration to update
   * @param updateIntegrationRequest The request body for updating an integration
   */
  public async updateIntegrationWithHttpInfo(clientId: string, updateIntegrationRequest: UpdateIntegrationRequest, _options?: Configuration): Promise<HttpInfo<Integration>> {
    let requestContextValue = await this.requestFactory.updateIntegration(clientId, updateIntegrationRequest, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateIntegrationWithHttpInfo(responseContext)
  }

  /**
   * Description: This API updates the details of an existing integration identified by their ID. It overrides the api\'s information with the provided data.
   * Update an integration
   * @param clientId Client ID of the integration to update
   * @param updateIntegrationRequest The request body for updating an integration
   */
  public async updateIntegration(clientId: string, updateIntegrationRequest: UpdateIntegrationRequest, _options?: Configuration): Promise<Integration> {
    const responseWithInfo = await this.updateIntegrationWithHttpInfo(clientId, updateIntegrationRequest, _options);
    return responseWithInfo.data;
  }


}


import { UpdateProjectRestoreAccountApiRequestFactory, UpdateProjectRestoreAccountApiResponseProcessor} from "../apis/UpdateProjectRestoreAccountApi";

export class PromiseUpdateProjectRestoreAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateProjectRestoreAccountApiRequestFactory = new UpdateProjectRestoreAccountApiRequestFactory(configuration),
    private responseProcessor: UpdateProjectRestoreAccountApiResponseProcessor = new UpdateProjectRestoreAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint allows users to update the details of a specified restore account within a project by providing the account ID and project ID.
   * Update a restore account
   * @param accountId ID of the restore account
   * @param projectId The project ID
   * @param accountUpdateInput The request body for updating a restore account
   */
  public async updateProjectRestoreAccountWithHttpInfo(accountId: string, projectId: string, accountUpdateInput: AccountUpdateInput, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.updateProjectRestoreAccount(accountId, projectId, accountUpdateInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateProjectRestoreAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint allows users to update the details of a specified restore account within a project by providing the account ID and project ID.
   * Update a restore account
   * @param accountId ID of the restore account
   * @param projectId The project ID
   * @param accountUpdateInput The request body for updating a restore account
   */
  public async updateProjectRestoreAccount(accountId: string, projectId: string, accountUpdateInput: AccountUpdateInput, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.updateProjectRestoreAccountWithHttpInfo(accountId, projectId, accountUpdateInput, _options);
    return responseWithInfo.data;
  }


}


import { UpdateProjectSourceAccountApiRequestFactory, UpdateProjectSourceAccountApiResponseProcessor} from "../apis/UpdateProjectSourceAccountApi";

export class PromiseUpdateProjectSourceAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateProjectSourceAccountApiRequestFactory = new UpdateProjectSourceAccountApiRequestFactory(configuration),
    private responseProcessor: UpdateProjectSourceAccountApiResponseProcessor = new UpdateProjectSourceAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API operation updates the details of a specified source account within a project.
   * Update a source account
   * @param accountId ID of the source account
   * @param projectId The project ID
   * @param accountUpdateInput The request body for updating a source account
   */
  public async updateProjectSourceAccountWithHttpInfo(accountId: string, projectId: string, accountUpdateInput: AccountUpdateInput, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.updateProjectSourceAccount(accountId, projectId, accountUpdateInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateProjectSourceAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: This API operation updates the details of a specified source account within a project.
   * Update a source account
   * @param accountId ID of the source account
   * @param projectId The project ID
   * @param accountUpdateInput The request body for updating a source account
   */
  public async updateProjectSourceAccount(accountId: string, projectId: string, accountUpdateInput: AccountUpdateInput, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.updateProjectSourceAccountWithHttpInfo(accountId, projectId, accountUpdateInput, _options);
    return responseWithInfo.data;
  }


}


import { UpdateProjectUserOverrideApiRequestFactory, UpdateProjectUserOverrideApiResponseProcessor} from "../apis/UpdateProjectUserOverrideApi";

export class PromiseUpdateProjectUserOverrideApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateProjectUserOverrideApiRequestFactory = new UpdateProjectUserOverrideApiRequestFactory(configuration),
    private responseProcessor: UpdateProjectUserOverrideApiResponseProcessor = new UpdateProjectUserOverrideApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: Update user details within a project. Requires admin permissions.
   * Update a user - override
   * @param id ID of the user
   * @param projectId The project ID
   * @param updateUserInput The request body for updating a user
   */
  public async updateProjectUserOverrideWithHttpInfo(id: string, projectId: string, updateUserInput: UpdateUserInput, _options?: Configuration): Promise<HttpInfo<User>> {
    let requestContextValue = await this.requestFactory.updateProjectUserOverride(id, projectId, updateUserInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateProjectUserOverrideWithHttpInfo(responseContext)
  }

  /**
   * Description: Update user details within a project. Requires admin permissions.
   * Update a user - override
   * @param id ID of the user
   * @param projectId The project ID
   * @param updateUserInput The request body for updating a user
   */
  public async updateProjectUserOverride(id: string, projectId: string, updateUserInput: UpdateUserInput, _options?: Configuration): Promise<User> {
    const responseWithInfo = await this.updateProjectUserOverrideWithHttpInfo(id, projectId, updateUserInput, _options);
    return responseWithInfo.data;
  }


}


import { UpdateProjectsApiRequestFactory, UpdateProjectsApiResponseProcessor} from "../apis/UpdateProjectsApi";

export class PromiseUpdateProjectsApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateProjectsApiRequestFactory = new UpdateProjectsApiRequestFactory(configuration),
    private responseProcessor: UpdateProjectsApiResponseProcessor = new UpdateProjectsApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API allows you to update the details of a specific project by providing the project ID and the new data in the request body.
   * Update a project
   * @param projectId ID of the project
   * @param project The request body for updating a project
   */
  public async updateProjectWithHttpInfo(projectId: string, project: Project, _options?: Configuration): Promise<HttpInfo<Project>> {
    let requestContextValue = await this.requestFactory.updateProject(projectId, project, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateProjectWithHttpInfo(responseContext)
  }

  /**
   * Description: This API allows you to update the details of a specific project by providing the project ID and the new data in the request body.
   * Update a project
   * @param projectId ID of the project
   * @param project The request body for updating a project
   */
  public async updateProject(projectId: string, project: Project, _options?: Configuration): Promise<Project> {
    const responseWithInfo = await this.updateProjectWithHttpInfo(projectId, project, _options);
    return responseWithInfo.data;
  }


}


import { UpdateRestoreAccountApiRequestFactory, UpdateRestoreAccountApiResponseProcessor} from "../apis/UpdateRestoreAccountApi";

export class PromiseUpdateRestoreAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateRestoreAccountApiRequestFactory = new UpdateRestoreAccountApiRequestFactory(configuration),
    private responseProcessor: UpdateRestoreAccountApiResponseProcessor = new UpdateRestoreAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API operation updates the details of an existing restore account identified by the given account ID.
   * Update a restore account
   * @param accountId ID of the restore account
   * @param accountUpdateInput The request body for updating a restore account
   */
  public async updateRestoreAccountWithHttpInfo(accountId: string, accountUpdateInput: AccountUpdateInput, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.updateRestoreAccount(accountId, accountUpdateInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateRestoreAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: This API operation updates the details of an existing restore account identified by the given account ID.
   * Update a restore account
   * @param accountId ID of the restore account
   * @param accountUpdateInput The request body for updating a restore account
   */
  public async updateRestoreAccount(accountId: string, accountUpdateInput: AccountUpdateInput, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.updateRestoreAccountWithHttpInfo(accountId, accountUpdateInput, _options);
    return responseWithInfo.data;
  }


}


import { UpdateRoleApiRequestFactory, UpdateRoleApiResponseProcessor} from "../apis/UpdateRoleApi";

export class PromiseUpdateRoleApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateRoleApiRequestFactory = new UpdateRoleApiRequestFactory(configuration),
    private responseProcessor: UpdateRoleApiResponseProcessor = new UpdateRoleApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API operation updates the details of a role using its unique ID. The request body must contain the updated role details.
   * Update a role
   * @param id ID of the role
   * @param updateRoleInput The request body for updating a user
   */
  public async updateRoleWithHttpInfo(id: string, updateRoleInput: UpdateRoleInput, _options?: Configuration): Promise<HttpInfo<Role>> {
    let requestContextValue = await this.requestFactory.updateRole(id, updateRoleInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateRoleWithHttpInfo(responseContext)
  }

  /**
   * Description: This API operation updates the details of a role using its unique ID. The request body must contain the updated role details.
   * Update a role
   * @param id ID of the role
   * @param updateRoleInput The request body for updating a user
   */
  public async updateRole(id: string, updateRoleInput: UpdateRoleInput, _options?: Configuration): Promise<Role> {
    const responseWithInfo = await this.updateRoleWithHttpInfo(id, updateRoleInput, _options);
    return responseWithInfo.data;
  }


}


import { UpdateSamlIdentityProviderConfigOverrideApiRequestFactory, UpdateSamlIdentityProviderConfigOverrideApiResponseProcessor} from "../apis/UpdateSamlIdentityProviderConfigOverrideApi";

export class PromiseUpdateSamlIdentityProviderConfigOverrideApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateSamlIdentityProviderConfigOverrideApiRequestFactory = new UpdateSamlIdentityProviderConfigOverrideApiRequestFactory(configuration),
    private responseProcessor: UpdateSamlIdentityProviderConfigOverrideApiResponseProcessor = new UpdateSamlIdentityProviderConfigOverrideApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API operation allows an admin to update the SAML identity provider configuration for a specified provider by ID. It requires admin permissions and accepts a JSON request body with the SAML configuration details.
   * Update saml identity provider config - override
   * @param id The ID of the saml identity provider
   * @param updateSamlIdentityProviderInput The request body for updating saml identity provider config.
   */
  public async updateSamlConfigOverrideWithHttpInfo(id: string, updateSamlIdentityProviderInput: UpdateSamlIdentityProviderInput, _options?: Configuration): Promise<HttpInfo<SamlIdentityProvider>> {
    let requestContextValue = await this.requestFactory.updateSamlConfigOverride(id, updateSamlIdentityProviderInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateSamlConfigOverrideWithHttpInfo(responseContext)
  }

  /**
   * Description: This API operation allows an admin to update the SAML identity provider configuration for a specified provider by ID. It requires admin permissions and accepts a JSON request body with the SAML configuration details.
   * Update saml identity provider config - override
   * @param id The ID of the saml identity provider
   * @param updateSamlIdentityProviderInput The request body for updating saml identity provider config.
   */
  public async updateSamlConfigOverride(id: string, updateSamlIdentityProviderInput: UpdateSamlIdentityProviderInput, _options?: Configuration): Promise<SamlIdentityProvider> {
    const responseWithInfo = await this.updateSamlConfigOverrideWithHttpInfo(id, updateSamlIdentityProviderInput, _options);
    return responseWithInfo.data;
  }


}


import { UpdateScanningAccountApiRequestFactory, UpdateScanningAccountApiResponseProcessor} from "../apis/UpdateScanningAccountApi";

export class PromiseUpdateScanningAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateScanningAccountApiRequestFactory = new UpdateScanningAccountApiRequestFactory(configuration),
    private responseProcessor: UpdateScanningAccountApiResponseProcessor = new UpdateScanningAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API operation allows updating the details of an existing scanning account using its unique account ID.
   * Update a scanning account
   * @param accountId ID of the scanning account
   * @param accountUpdateInput The request body for updating a scanning account
   */
  public async updateScanningAccountWithHttpInfo(accountId: string, accountUpdateInput: AccountUpdateInput, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.updateScanningAccount(accountId, accountUpdateInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateScanningAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: This API operation allows updating the details of an existing scanning account using its unique account ID.
   * Update a scanning account
   * @param accountId ID of the scanning account
   * @param accountUpdateInput The request body for updating a scanning account
   */
  public async updateScanningAccount(accountId: string, accountUpdateInput: AccountUpdateInput, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.updateScanningAccountWithHttpInfo(accountId, accountUpdateInput, _options);
    return responseWithInfo.data;
  }


}


import { UpdateSourceAccountApiRequestFactory, UpdateSourceAccountApiResponseProcessor} from "../apis/UpdateSourceAccountApi";

export class PromiseUpdateSourceAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateSourceAccountApiRequestFactory = new UpdateSourceAccountApiRequestFactory(configuration),
    private responseProcessor: UpdateSourceAccountApiResponseProcessor = new UpdateSourceAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API operation updates the details of a source account identified by a unique accountId.
   * Update a source account
   * @param accountId ID of the source account
   * @param accountUpdateInput The request body for updating a source account
   */
  public async updateSourceAccountWithHttpInfo(accountId: string, accountUpdateInput: AccountUpdateInput, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.updateSourceAccount(accountId, accountUpdateInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateSourceAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: This API operation updates the details of a source account identified by a unique accountId.
   * Update a source account
   * @param accountId ID of the source account
   * @param accountUpdateInput The request body for updating a source account
   */
  public async updateSourceAccount(accountId: string, accountUpdateInput: AccountUpdateInput, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.updateSourceAccountWithHttpInfo(accountId, accountUpdateInput, _options);
    return responseWithInfo.data;
  }


}


import { UpdateUserOverrideApiRequestFactory, UpdateUserOverrideApiResponseProcessor} from "../apis/UpdateUserOverrideApi";

export class PromiseUpdateUserOverrideApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateUserOverrideApiRequestFactory = new UpdateUserOverrideApiRequestFactory(configuration),
    private responseProcessor: UpdateUserOverrideApiResponseProcessor = new UpdateUserOverrideApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API updates the details of an existing user identified by their ID. It overrides the user\'s information with the provided data.
   * Update a user - override
   * @param id ID of the user
   * @param updateUserInput The request body for updating a user
   */
  public async updateUserOverrideWithHttpInfo(id: string, updateUserInput: UpdateUserInput, _options?: Configuration): Promise<HttpInfo<User>> {
    let requestContextValue = await this.requestFactory.updateUserOverride(id, updateUserInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateUserOverrideWithHttpInfo(responseContext)
  }

  /**
   * Description: This API updates the details of an existing user identified by their ID. It overrides the user\'s information with the provided data.
   * Update a user - override
   * @param id ID of the user
   * @param updateUserInput The request body for updating a user
   */
  public async updateUserOverride(id: string, updateUserInput: UpdateUserInput, _options?: Configuration): Promise<User> {
    const responseWithInfo = await this.updateUserOverrideWithHttpInfo(id, updateUserInput, _options);
    return responseWithInfo.data;
  }


}


import { UpdateVaultAccountApiRequestFactory, UpdateVaultAccountApiResponseProcessor} from "../apis/UpdateVaultAccountApi";

export class PromiseUpdateVaultAccountApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateVaultAccountApiRequestFactory = new UpdateVaultAccountApiRequestFactory(configuration),
    private responseProcessor: UpdateVaultAccountApiResponseProcessor = new UpdateVaultAccountApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API is used to update the details of an existing vault account using its ID.
   * Update a vault account
   * @param accountId ID of the vault account
   * @param accountUpdateInput The request body for updating a vault account
   */
  public async updateVaultAccountWithHttpInfo(accountId: string, accountUpdateInput: AccountUpdateInput, _options?: Configuration): Promise<HttpInfo<Account>> {
    let requestContextValue = await this.requestFactory.updateVaultAccount(accountId, accountUpdateInput, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateVaultAccountWithHttpInfo(responseContext)
  }

  /**
   * Description: This API is used to update the details of an existing vault account using its ID.
   * Update a vault account
   * @param accountId ID of the vault account
   * @param accountUpdateInput The request body for updating a vault account
   */
  public async updateVaultAccount(accountId: string, accountUpdateInput: AccountUpdateInput, _options?: Configuration): Promise<Account> {
    const responseWithInfo = await this.updateVaultAccountWithHttpInfo(accountId, accountUpdateInput, _options);
    return responseWithInfo.data;
  }


}


import { UpdateViewerRoleApiRequestFactory, UpdateViewerRoleApiResponseProcessor} from "../apis/UpdateViewerRoleApi";

export class PromiseUpdateViewerRoleApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateViewerRoleApiRequestFactory = new UpdateViewerRoleApiRequestFactory(configuration),
    private responseProcessor: UpdateViewerRoleApiResponseProcessor = new UpdateViewerRoleApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint allows updating the role of a viewer by specifying a target role ID.
   * Update viewer role
   * @param updateViewerRoleRequest Target role
   */
  public async updateViewerRoleWithHttpInfo(updateViewerRoleRequest: UpdateViewerRoleRequest, _options?: Configuration): Promise<HttpInfo<Message>> {
    let requestContextValue = await this.requestFactory.updateViewerRole(updateViewerRoleRequest, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateViewerRoleWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint allows updating the role of a viewer by specifying a target role ID.
   * Update viewer role
   * @param updateViewerRoleRequest Target role
   */
  public async updateViewerRole(updateViewerRoleRequest: UpdateViewerRoleRequest, _options?: Configuration): Promise<Message> {
    const responseWithInfo = await this.updateViewerRoleWithHttpInfo(updateViewerRoleRequest, _options);
    return responseWithInfo.data;
  }


}


import { UpdateViewerUIPreferencesApiRequestFactory, UpdateViewerUIPreferencesApiResponseProcessor} from "../apis/UpdateViewerUIPreferencesApi";

export class PromiseUpdateViewerUIPreferencesApi {

  public constructor(
    private configuration: Configuration,
    private requestFactory: UpdateViewerUIPreferencesApiRequestFactory = new UpdateViewerUIPreferencesApiRequestFactory(configuration),
    private responseProcessor: UpdateViewerUIPreferencesApiResponseProcessor = new UpdateViewerUIPreferencesApiResponseProcessor() 
  ) {
    
  }

  /**
   * Description: This API endpoint updates the UI preferences for a viewer based on a specified key.
   * Update viewer UI preferences
   * @param key Key of the preference
   * @param requestBody The request body for updating viewer UI preferences
   */
  public async updateViewerUIPreferencesWithHttpInfo(key: string, requestBody: { [key: string]: any; }, _options?: Configuration): Promise<HttpInfo<Preferences>> {
    let requestContextValue = await this.requestFactory.updateViewerUIPreferences(key, requestBody, _options);
    const responseContext = await requestWithRetries(requestContextValue, this.configuration)
    return await this.responseProcessor.updateViewerUIPreferencesWithHttpInfo(responseContext)
  }

  /**
   * Description: This API endpoint updates the UI preferences for a viewer based on a specified key.
   * Update viewer UI preferences
   * @param key Key of the preference
   * @param requestBody The request body for updating viewer UI preferences
   */
  public async updateViewerUIPreferences(key: string, requestBody: { [key: string]: any; }, _options?: Configuration): Promise<Preferences> {
    const responseWithInfo = await this.updateViewerUIPreferencesWithHttpInfo(key, requestBody, _options);
    return responseWithInfo.data;
  }


}


