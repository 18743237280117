/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class DiscoveryStatusResponse {
  /**
  * Whether the discovery process has finished
  */
  'finished': boolean;
  /**
  * The date the discovery process finished
  */
  'finishedAt': Date;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "finished",
      "baseName": "finished",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "finishedAt",
      "baseName": "finishedAt",
      "type": "Date",
      "format": "date-time"
    }  ];

  static getAttributeTypeMap() {
    return DiscoveryStatusResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

