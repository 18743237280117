/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { Notification } from '../models/Notification';
import { Pagination } from '../models/Pagination';

export class ListNotificationsResponse {
  'notifications': Array<Notification>;
  'pagination': Pagination;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "notifications",
      "baseName": "notifications",
      "type": "Array<Notification>",
      "format": ""
    },
    {
      "name": "pagination",
      "baseName": "pagination",
      "type": "Pagination",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListNotificationsResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

