/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { GenericSnapshotData } from '../models/GenericSnapshotData';

export class GenericSnapshotsPerVolume {
  /**
  * The volume ID of the snapshots.
  */
  'volumeId'?: string;
  'snapshotsData'?: Array<GenericSnapshotData>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "volumeId",
      "baseName": "volumeId",
      "type": "string",
      "format": ""
    },
    {
      "name": "snapshotsData",
      "baseName": "snapshotsData",
      "type": "Array<GenericSnapshotData>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return GenericSnapshotsPerVolume.attributeTypeMap;
  }

  public constructor() {
  }
}

