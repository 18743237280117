/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class ResourceProperties {
  /**
  * The source region of the inventory item
  */
  'sourceRegion'?: string;
  /**
  * The VPC of the inventory item
  */
  'vpc'?: string;
  /**
  * The subnets of the inventory item
  */
  'subnets'?: Array<string>;
  /**
  * The availability zones of the inventory item
  */
  'availabilityZones'?: Array<string>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "sourceRegion",
      "baseName": "sourceRegion",
      "type": "string",
      "format": ""
    },
    {
      "name": "vpc",
      "baseName": "vpc",
      "type": "string",
      "format": ""
    },
    {
      "name": "subnets",
      "baseName": "subnets",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "availabilityZones",
      "baseName": "availabilityZones",
      "type": "Array<string>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ResourceProperties.attributeTypeMap;
  }

  public constructor() {
  }
}

