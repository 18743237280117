/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class SearchInput {
  /**
  * The cloud provider
  */
  'cloudProvider': SearchInputCloudProviderEnum;
  /**
  * The start date for the search
  */
  'startDate'?: Date;
  /**
  * The end date for the search
  */
  'endDate'?: Date;
  /**
  * The maximum number of records to return
  */
  'limit'?: number;
  /**
  * The query execution ID
  */
  'queryExecutionId'?: string;
  /**
  * The page token for pagination
  */
  'pageToken'?: string;
  /**
  * The search query
  */
  'query': string;
  /**
  * The search filters
  */
  'filters'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "cloudProvider",
      "baseName": "cloudProvider",
      "type": "SearchInputCloudProviderEnum",
      "format": ""
    },
    {
      "name": "startDate",
      "baseName": "startDate",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "endDate",
      "baseName": "endDate",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "limit",
      "baseName": "limit",
      "type": "number",
      "format": ""
    },
    {
      "name": "queryExecutionId",
      "baseName": "queryExecutionId",
      "type": "string",
      "format": ""
    },
    {
      "name": "pageToken",
      "baseName": "pageToken",
      "type": "string",
      "format": ""
    },
    {
      "name": "query",
      "baseName": "query",
      "type": "string",
      "format": ""
    },
    {
      "name": "filters",
      "baseName": "filters",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return SearchInput.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum SearchInputCloudProviderEnum {
  Aws = 'AWS',
  Azure = 'Azure'
}

