/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* The type of the notification (e.g. BACKUP_JOB_FAILED, RESTORE_JOB_SUCCEEDED, etc.)
*/
export enum NotificationType {
  NotificationTypeUnknown = 'NOTIFICATION_TYPE_UNKNOWN',
  BackupJobFailed = 'BACKUP_JOB_FAILED',
  RestoreJobSucceeded = 'RESTORE_JOB_SUCCEEDED',
  RestoreJobFailed = 'RESTORE_JOB_FAILED'
}
