/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { Project } from '../models/Project';

export class ListProjectsResponse {
  /**
  * List of projects.
  */
  'projects': Array<Project>;
  /**
  * Total number of projects.
  */
  'totalCount'?: number;
  'nextToken'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "projects",
      "baseName": "projects",
      "type": "Array<Project>",
      "format": ""
    },
    {
      "name": "totalCount",
      "baseName": "totalCount",
      "type": "number",
      "format": ""
    },
    {
      "name": "nextToken",
      "baseName": "nextToken",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListProjectsResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

