/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { Snapshot } from '../models/Snapshot';

export class GetSnapshotResponse {
  'snapshot': Snapshot;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "snapshot",
      "baseName": "snapshot",
      "type": "Snapshot",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return GetSnapshotResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

