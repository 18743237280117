/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class DBResourceMetadata {
  /**
  * The ID of the resource
  */
  'resourceId': string;
  /**
  * The name of the resource
  */
  'resourceName': string;
  /**
  * The type of the resource
  */
  'resourceType': string;
  /**
  * The group of the resource
  */
  'resourceGroup': string;
  /**
  * The cloud provider of the resource
  */
  'cloudProvider': string;
  /**
  * The environment of the resource
  */
  'resourceEnvironment': string;
  /**
  * The tags associated with the resource
  */
  'tags'?: { [key: string]: string; };
  /**
  * The apps associated with the resource
  */
  'apps'?: Array<string>;
  /**
  * The data classification associated with the resource
  */
  'dataClassification'?: Array<string>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "resourceId",
      "baseName": "resourceId",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceName",
      "baseName": "resourceName",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceType",
      "baseName": "resourceType",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceGroup",
      "baseName": "resourceGroup",
      "type": "string",
      "format": ""
    },
    {
      "name": "cloudProvider",
      "baseName": "cloudProvider",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceEnvironment",
      "baseName": "resourceEnvironment",
      "type": "string",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "apps",
      "baseName": "apps",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "dataClassification",
      "baseName": "dataClassification",
      "type": "Array<string>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return DBResourceMetadata.attributeTypeMap;
  }

  public constructor() {
  }
}

