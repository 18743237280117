import { useContext } from 'react';

import { UserContext } from './userContext';

export const useUser = (getDefaultsOutsideProvider = false) => {
  const context = useContext(UserContext);
  if (getDefaultsOutsideProvider && !context) {
    return {
      isApplicationReady: false,
      sourceAccounts: [],
      user: null,
      currentProjectId: null,
      changeRole: async () => {},
      changeProject: async () => {},
      recalculateAccounts: () => {},
    };
  }

  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
};
