/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class TokenResponse {
  /**
  * Access token, which you pass in the `Authorization` header when calling API methods.
  */
  'accessToken'?: string;
  /**
  * Length of time until the token expires, in seconds.  Default: `43200` (12 hours). 
  */
  'expirationSeconds'?: number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "accessToken",
      "baseName": "accessToken",
      "type": "string",
      "format": ""
    },
    {
      "name": "expirationSeconds",
      "baseName": "expirationSeconds",
      "type": "number",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return TokenResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

