export const setStartOfMonth = (date: Date): Date => {
  date.setDate(1);
  return setStartOfDay(date);
};

export const isCurrentMonth = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const setEndOfMonth = (date: Date): Date => {
  date.setMonth(date.getMonth() + 1, 0);
  return setEndOfDay(date);
};

export const setStartOfDay = (date: Date): Date => {
  date.setHours(0, 0, 0, 0);
  return date;
};

export const setEndOfDay = (date: Date): Date => {
  date.setHours(23, 59, 59, 999);
  return date;
};

export const isDateValid = (date?: unknown): date is Date =>
  date instanceof Date && date.getFullYear() > 2020;
