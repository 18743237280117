/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { InventoryResource } from '../models/InventoryResource';

export class GetResourceResponse {
  'resource': InventoryResource;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "resource",
      "baseName": "resource",
      "type": "InventoryResource",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return GetResourceResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

