/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { InventoryFilterConditions } from '../models/InventoryFilterConditions';
import { SortResourceBy } from '../models/SortResourceBy';

export class ListInventoryRequest {
  /**
  * List of sorting options. Sorting is applied in the order passed in the list. 
  */
  'sorts'?: Array<SortResourceBy>;
  'filters'?: InventoryFilterConditions;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "sorts",
      "baseName": "sorts",
      "type": "Array<SortResourceBy>",
      "format": ""
    },
    {
      "name": "filters",
      "baseName": "filters",
      "type": "InventoryFilterConditions",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListInventoryRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

