/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class Account {
  /**
  * The ID of the account
  */
  'id': string;
  /**
  * The cloud provider of the account
  */
  'cloud': AccountCloudEnum;
  /**
  * The account ID
  */
  'providerAccountId': string;
  /**
  * The role to access the account
  */
  'role': string;
  /**
  * The relevant regions for the account
  */
  'regions'?: Array<string>;
  'tags'?: { [key: string]: string; };
  /**
  * Friendly name for the account
  */
  'name': string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "cloud",
      "baseName": "cloud",
      "type": "AccountCloudEnum",
      "format": ""
    },
    {
      "name": "providerAccountId",
      "baseName": "providerAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "role",
      "baseName": "role",
      "type": "string",
      "format": ""
    },
    {
      "name": "regions",
      "baseName": "regions",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return Account.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum AccountCloudEnum {
  Aws = 'AWS',
  Azure = 'Azure',
  MongoAtlas = 'MONGO_ATLAS'
}

