/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { EncryptionKey } from '../models/EncryptionKey';

export class EncryptionKeys {
  'keys': Array<EncryptionKey>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "keys",
      "baseName": "keys",
      "type": "Array<EncryptionKey>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return EncryptionKeys.attributeTypeMap;
  }

  public constructor() {
  }
}

