/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class Job {
  /**
  * The ID of the restore job
  */
  'id': string;
  /**
  * The ID of the resource
  */
  'resourceId': string;
  /**
  * The IDs of the linked resources
  */
  'linkedResourceIds'?: Array<string>;
  /**
  * The type of the restore job (i.e ec2 snapshot, s3 file, etc.)
  */
  'type': string;
  /**
  * The ID of the snapshot
  */
  'snapshotId': string;
  /**
  * The ID of the vault
  */
  'vaultId': string;
  /**
  * The status of the restore job
  */
  'status': string;
  /**
  * The progress of the restore job (0 ... 1)
  */
  'progress': number;
  /**
  * The date the restore job started
  */
  'startedAt': Date;
  /**
  * The date the restore job was last updated
  */
  'updatedAt': Date;
  /**
  * Whether the backup job is on demand (by user or by BP)
  */
  'onDemand'?: boolean;
  /**
  * The id of the BP that initiated the backup job or the user ID who manually triggered the backup job
  */
  'initiatorId'?: string;
  /**
  * The ID of the user who initiated the restore job
  */
  'userId': string;
  /**
  * Whether the restore job is partial
  */
  'partial': boolean;
  /**
  * The account ID of the target resource
  */
  'targetAccountId': string;
  /**
  * The region of the target resource
  */
  'targetRegion': string;
  /**
  * The output of the restore job
  */
  'output'?: { [key: string]: string; };
  /**
  * The error message of the restore job
  */
  'error'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceId",
      "baseName": "resourceId",
      "type": "string",
      "format": ""
    },
    {
      "name": "linkedResourceIds",
      "baseName": "linkedResourceIds",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "type",
      "baseName": "type",
      "type": "string",
      "format": ""
    },
    {
      "name": "snapshotId",
      "baseName": "snapshotId",
      "type": "string",
      "format": ""
    },
    {
      "name": "vaultId",
      "baseName": "vaultId",
      "type": "string",
      "format": ""
    },
    {
      "name": "status",
      "baseName": "status",
      "type": "string",
      "format": ""
    },
    {
      "name": "progress",
      "baseName": "progress",
      "type": "number",
      "format": "float"
    },
    {
      "name": "startedAt",
      "baseName": "startedAt",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "updatedAt",
      "baseName": "updatedAt",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "onDemand",
      "baseName": "onDemand",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "initiatorId",
      "baseName": "initiatorId",
      "type": "string",
      "format": ""
    },
    {
      "name": "userId",
      "baseName": "userId",
      "type": "string",
      "format": ""
    },
    {
      "name": "partial",
      "baseName": "partial",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "targetAccountId",
      "baseName": "targetAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "targetRegion",
      "baseName": "targetRegion",
      "type": "string",
      "format": ""
    },
    {
      "name": "output",
      "baseName": "output",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "error",
      "baseName": "error",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return Job.attributeTypeMap;
  }

  public constructor() {
  }
}

