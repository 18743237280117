/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { DataClass } from '../models/DataClass';

/**
* Details about the resource\'s data classes.
*/
export class DataClassesDetails {
  /**
  * Data classes.
  */
  'dataClasses'?: Array<DataClass>;
  /**
  * Data classes.
  */
  'eonDataClassifications'?: Array<DataClass>;
  /**
  * Whether the data classes are manually overridden. If `true`, the data classes are set by the user and remain static. If `false`, the data classes are automatically set by Eon. 
  */
  'isOverridden'?: boolean;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "dataClasses",
      "baseName": "dataClasses",
      "type": "Array<DataClass>",
      "format": ""
    },
    {
      "name": "eonDataClassifications",
      "baseName": "eonDataClassifications",
      "type": "Array<DataClass>",
      "format": ""
    },
    {
      "name": "isOverridden",
      "baseName": "isOverridden",
      "type": "boolean",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return DataClassesDetails.attributeTypeMap;
  }

  public constructor() {
  }
}

