/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class StatusCodeFilters {
  /**
  * Matches if `statusCode` starts with this string. For example, setting to `\"4\"` matches all 4xx codes, whereas setting to `\"404\"` matches only 404 codes. 
  */
  'startsWith'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "startsWith",
      "baseName": "StartsWith",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return StatusCodeFilters.attributeTypeMap;
  }

  public constructor() {
  }
}

