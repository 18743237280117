/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class BatchOperationResponse {
  'successCount'?: number;
  'errorCount'?: number;
  'message'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "successCount",
      "baseName": "successCount",
      "type": "number",
      "format": ""
    },
    {
      "name": "errorCount",
      "baseName": "errorCount",
      "type": "number",
      "format": ""
    },
    {
      "name": "message",
      "baseName": "message",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return BatchOperationResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

