/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class RestoreBucketRequest {
  /**
  * Eon-assigned ID of the restore account.
  */
  'restoreAccountId': string;
  /**
  * Name of an existing bucket to restore the files to.
  */
  'restoreBucketName': string;
  /**
  * Prefix to add to the restore path. If you don\'t specify a prefix, the bucket is restored to the bucket root. 
  */
  'restoreBucketPrefix'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "restoreAccountId",
      "baseName": "restoreAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "restoreBucketName",
      "baseName": "restoreBucketName",
      "type": "string",
      "format": ""
    },
    {
      "name": "restoreBucketPrefix",
      "baseName": "restoreBucketPrefix",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreBucketRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

