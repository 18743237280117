/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class RestoreVolumeToEbsSnapshotRequest {
  /**
  * Cloud-provider-assigned ID of the volume to restore.
  */
  'providerVolumeId': string;
  /**
  * Eon-assigned ID of the restore account.
  */
  'restoreAccountId': string;
  /**
  * Region to restore the EBS snapshot to.
  */
  'regionName': string;
  /**
  * Description to apply to the EBS snapshot.
  */
  'description'?: string;
  /**
  * Tags to apply to the EBS snapshot as key-value pairs, where key and value are both strings.
  */
  'tags': { [key: string]: string; };
  /**
  * ID of the key you want Eon to use for encrypting the EBS snapshot.
  */
  'snapshotEncryptionKeyId'?: string;
  /**
  * ID of the key you want Eon to use for encrypting temporary restore artifacts. This can be the same key as `snapshotEncryptionKeyId`. 
  */
  'encryptionKeyId'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "providerVolumeId",
      "baseName": "providerVolumeId",
      "type": "string",
      "format": ""
    },
    {
      "name": "restoreAccountId",
      "baseName": "restoreAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "regionName",
      "baseName": "regionName",
      "type": "string",
      "format": ""
    },
    {
      "name": "description",
      "baseName": "description",
      "type": "string",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "snapshotEncryptionKeyId",
      "baseName": "snapshotEncryptionKeyId",
      "type": "string",
      "format": ""
    },
    {
      "name": "encryptionKeyId",
      "baseName": "encryptionKeyId",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreVolumeToEbsSnapshotRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

