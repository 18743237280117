/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { ControlViolation } from '../models/ControlViolation';
import { Pagination } from '../models/Pagination';

export class ListControlViolationsResponse {
  'controlViolations': Array<ControlViolation>;
  'pagination': Pagination;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "controlViolations",
      "baseName": "controlViolations",
      "type": "Array<ControlViolation>",
      "format": ""
    },
    {
      "name": "pagination",
      "baseName": "pagination",
      "type": "Pagination",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListControlViolationsResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

