import type { NextAuthOptions } from 'next-auth';
import GoogleProvider from 'next-auth/providers/google';

export const authOptions: NextAuthOptions = {
  providers: [
    ...(process.env.GOOGLE_CLIENT_ID && process.env.GOOGLE_CLIENT_SECRET
      ? [
          GoogleProvider({
            clientId: process.env.GOOGLE_CLIENT_ID,
            clientSecret: process.env.GOOGLE_CLIENT_SECRET,
          }),
        ]
      : []),
  ],
  session: {
    strategy: 'jwt',
    maxAge: 30 * 24 * 60 * 60, // ** 30 days
  },
  pages: {
    signIn: '/login',
  },
  callbacks: {
    async signIn(params) {
      if (!params.user.email?.includes('eon.io')) return false;
      return true;
    },
  },
};

export function getParsedJwt(token: string | undefined = '') {
  if (typeof token === 'string') {
    const parts = token.split('.');
    if (parts.length > 1) {
      const payload = parts[1];
      try {
        return JSON.parse(atob(payload));
      } catch {
        return undefined;
      }
    }
  }

  return undefined;
}
