/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class NotificationPolicyConfigSlack {
  /**
  * The channel to send the notification to
  */
  'channel'?: string;
  /**
  * The token to use to send the notification
  */
  'token'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "channel",
      "baseName": "channel",
      "type": "string",
      "format": ""
    },
    {
      "name": "token",
      "baseName": "token",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return NotificationPolicyConfigSlack.attributeTypeMap;
  }

  public constructor() {
  }
}

