import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import type { Configuration } from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpInfo } from '../http/http';
import { ObjectSerializer } from '../models/ObjectSerializer';
import { ApiException } from './exception';
import { isCodeInRange } from '../util';
import type {SecurityAuthentication} from '../auth/auth';


import { ServiceProviderDetails } from '../models/ServiceProviderDetails';

/**
 * no description
 */
export class GetServiceProviderDetailsApiRequestFactory extends BaseAPIRequestFactory {

  /**
   * Get service provider details
   * @param providerName The name of the identity provider
   */
  public async getServiceProviderDetails(providerName?: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    



    // Path Params
    const localVarPath = '/oauth/service-provider-details';

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.GET, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (providerName !== undefined) {
      requestContext.setQueryParam("provider-name", ObjectSerializer.serialize(providerName, "string", ""));
    }


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

}

export class GetServiceProviderDetailsApiResponseProcessor {

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to getServiceProviderDetails
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async getServiceProviderDetailsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ServiceProviderDetails >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ServiceProviderDetails = ObjectSerializer.deserialize(
        await response.body.json(),
        "ServiceProviderDetails", ""
      ) as ServiceProviderDetails;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Saml identity provider not found", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ServiceProviderDetails = await response.body.json() as ServiceProviderDetails;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

}
