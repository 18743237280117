/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SortOrder } from '../models/SortOrder';

/**
* A sorting criterion.
*/
export class SortJobBy {
  /**
  * The field name to sort by.
  */
  'field': SortJobByFieldEnum;
  'order': SortOrder;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "field",
      "baseName": "field",
      "type": "SortJobByFieldEnum",
      "format": ""
    },
    {
      "name": "order",
      "baseName": "order",
      "type": "SortOrder",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return SortJobBy.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum SortJobByFieldEnum {
  Id = 'id',
  Status = 'status',
  StartedAt = 'startedAt',
  UserId = 'userId',
  UpdatedAt = 'updatedAt',
  OnDemand = 'onDemand',
  ResourceId = 'resourceId'
}

