import useSWR, { type SWRConfiguration, type SWRHook } from 'swr';

const fetchFactory =
  (authToken: string) => (url: string, options?: RequestInit) =>
    fetch(url, {
      ...options,
      headers: {
        Authorization: authToken,
        ...options?.headers,
      },
    }).then((res) => {
      if (res.status === 204) {
        // No content
        return;
      }
      return res.json();
    });

export type HttpClient = ReturnType<typeof createHttpClient>;

const swrDefaultConfig = {
  keepPreviousData: true,
  revalidateOnFocus: false,
};

export const createHttpClient = (baseUrl: string, authToken: string) => {
  const fetcher = fetchFactory(authToken);

  return {
    get: (url: string, swrOptions?: SWRConfiguration) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { data, ...rest } = useSWR(baseUrl + url, fetcher, {
        ...swrDefaultConfig,
        ...swrOptions,
      });
      return {
        ...rest,
        body: data,
      };
    },
    fetch: (url: string) => {
      return fetcher(baseUrl + url, {
        method: 'GET',
      });
    },
    post: (url: string, body: unknown) => {
      return fetcher(baseUrl + url, {
        method: 'POST',
        body: JSON.stringify(body),
      });
    },
    put: (url: string, body: unknown) => {
      return fetcher(baseUrl + url, {
        method: 'PUT',
        body: JSON.stringify(body),
      });
    },
    patch: (url: string, body: unknown) => {
      return fetcher(baseUrl + url, {
        method: 'PATCH',
        body: JSON.stringify(body),
      });
    },
    delete: (url: string) => {
      return fetcher(baseUrl + url, {
        method: 'DELETE',
      });
    },
    execute: <T>(
      key: Parameters<SWRHook>[0],
      executer: () => Promise<T>,
      swrOptions?: SWRConfiguration
    ) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { data, ...rest } = useSWR(key, executer, {
        ...swrDefaultConfig,
        ...swrOptions,
      });
      return {
        ...rest,
        body: data,
      };
    },
  };
};
