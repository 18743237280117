/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { Control } from '../models/Control';

export class ControlViolation {
  /**
  * The id of the control violation
  */
  'id': string;
  /**
  * The id of the impacted resource
  */
  'resourceId': string;
  /**
  * Whether the violation is muted
  */
  'muted': boolean;
  'control': Control;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceId",
      "baseName": "resourceId",
      "type": "string",
      "format": ""
    },
    {
      "name": "muted",
      "baseName": "muted",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "control",
      "baseName": "control",
      "type": "Control",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ControlViolation.attributeTypeMap;
  }

  public constructor() {
  }
}

