/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class RegisterInputBillingToken {
  /**
  * The Marketplace token
  */
  'marketplaceToken'?: string;
  /**
  * The Marketplace provider name
  */
  'marketplaceProvider'?: RegisterInputBillingTokenMarketplaceProviderEnum;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "marketplaceToken",
      "baseName": "MarketplaceToken",
      "type": "string",
      "format": ""
    },
    {
      "name": "marketplaceProvider",
      "baseName": "MarketplaceProvider",
      "type": "RegisterInputBillingTokenMarketplaceProviderEnum",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RegisterInputBillingToken.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum RegisterInputBillingTokenMarketplaceProviderEnum {
  Azure = 'Azure',
  Aws = 'AWS'
}

