/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { EonAccount } from '../models/EonAccount';
import { IDs } from '../models/IDs';
import { Role } from '../models/Role';
import { SamlIdentityProvider } from '../models/SamlIdentityProvider';

export class Viewer {
  /**
  * The ID of the viewer generated by cognito
  */
  'id': string;
  /**
  * The email of the volume
  */
  'email'?: string;
  /**
  * The name of the volume
  */
  'givenName': string;
  'eonAccount': EonAccount;
  'selectedRole': Role;
  /**
  * The role to projects mappings of the viewer
  */
  'roleToProjectsMappings': { [key: string]: IDs; };
  'samlIdentityProvider'?: SamlIdentityProvider;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "email",
      "baseName": "email",
      "type": "string",
      "format": ""
    },
    {
      "name": "givenName",
      "baseName": "givenName",
      "type": "string",
      "format": ""
    },
    {
      "name": "eonAccount",
      "baseName": "eonAccount",
      "type": "EonAccount",
      "format": ""
    },
    {
      "name": "selectedRole",
      "baseName": "selectedRole",
      "type": "Role",
      "format": ""
    },
    {
      "name": "roleToProjectsMappings",
      "baseName": "roleToProjectsMappings",
      "type": "{ [key: string]: IDs; }",
      "format": ""
    },
    {
      "name": "samlIdentityProvider",
      "baseName": "samlIdentityProvider",
      "type": "SamlIdentityProvider",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return Viewer.attributeTypeMap;
  }

  public constructor() {
  }
}

