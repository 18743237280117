import type {
  Configuration,
  CreateRoleInput,
  UpdateRoleInput,
} from '@repo/api-gw-sdk';
import {
  ListRolesApi,
  CreateRoleApi,
  DeleteRoleApi,
  UpdateRoleApi,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/roles';

export const roles = (httpClient: HttpClient, config: Configuration) => {
  const listRolesApi = new ListRolesApi(config);
  const createRoleApi = new CreateRoleApi(config);
  const updateRoleApi = new UpdateRoleApi(config);
  const deleteRoleApi = new DeleteRoleApi(config);

  return {
    list: () => httpClient.execute([baseUrl], () => listRolesApi.listRoles()),
    create: (createRoleInput: CreateRoleInput) =>
      createRoleApi.createRole(createRoleInput),
    update: (id: string, updateRoleInput: UpdateRoleInput) =>
      updateRoleApi.updateRole(id, updateRoleInput),
    delete: (id: string) => deleteRoleApi.deleteRole(id),
  };
};
