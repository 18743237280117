/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class RestoreDbToRdsSnapshotRequest {
  /**
  * The ID of the restore account (target)
  */
  'restoreAccountId': string;
  /**
  * The region of a new restore database
  */
  'restoreRegion'?: string;
  /**
  * Optional encryption key ID for a new restore database
  */
  'encryptionKeyId'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "restoreAccountId",
      "baseName": "restoreAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "restoreRegion",
      "baseName": "restoreRegion",
      "type": "string",
      "format": ""
    },
    {
      "name": "encryptionKeyId",
      "baseName": "encryptionKeyId",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreDbToRdsSnapshotRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

