/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* The status of the notification
*/
export enum NotificationStatus {
  Statuspending = 'NOTIFICATION_STATUSPENDING',
  Statussent = 'NOTIFICATION_STATUSSENT',
  Statusfailed = 'NOTIFICATION_STATUSFAILED'
}
