/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { ControlViolationsPerSeverityViolatedControlsInner } from '../models/ControlViolationsPerSeverityViolatedControlsInner';

/**
* The control violations per severity level
*/
export class ControlViolationsPerSeverity {
  /**
  * The number of resources violated a control with this severity level
  */
  'allAffectedResources': number;
  /**
  * The controls with this severity level
  */
  'violatedControls'?: Array<ControlViolationsPerSeverityViolatedControlsInner>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "allAffectedResources",
      "baseName": "allAffectedResources",
      "type": "number",
      "format": ""
    },
    {
      "name": "violatedControls",
      "baseName": "violatedControls",
      "type": "Array<ControlViolationsPerSeverityViolatedControlsInner>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ControlViolationsPerSeverity.attributeTypeMap;
  }

  public constructor() {
  }
}

