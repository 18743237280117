/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class DBSnapshotMetadata {
  /**
  * The ID of the snapshot
  */
  'snapshotId': string;
  /**
  * The date the snapshot was generated
  */
  'generatedOn': Date;
  /**
  * The id of the vault the snapshot saved in
  */
  'vaultId': string;
  /**
  * The type of the snapshot
  */
  'type': number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "snapshotId",
      "baseName": "snapshotId",
      "type": "string",
      "format": ""
    },
    {
      "name": "generatedOn",
      "baseName": "generatedOn",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "vaultId",
      "baseName": "vaultId",
      "type": "string",
      "format": ""
    },
    {
      "name": "type",
      "baseName": "type",
      "type": "number",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return DBSnapshotMetadata.attributeTypeMap;
  }

  public constructor() {
  }
}

