/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { Pagination } from '../models/Pagination';
import { Role } from '../models/Role';

export class RolesResponse {
  'roles': Array<Role>;
  'pagination': Pagination;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "roles",
      "baseName": "roles",
      "type": "Array<Role>",
      "format": ""
    },
    {
      "name": "pagination",
      "baseName": "pagination",
      "type": "Pagination",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RolesResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

