/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { VisualizationHistogramSeries } from '../models/VisualizationHistogramSeries';

/**
* The source storage category
*/
export class BackedUpStorageDateHistogram {
  'data'?: Array<VisualizationHistogramSeries>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "data",
      "baseName": "data",
      "type": "Array<VisualizationHistogramSeries>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return BackedUpStorageDateHistogram.attributeTypeMap;
  }

  public constructor() {
  }
}

