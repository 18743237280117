/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class DailyStorageSummary {
  /**
  * Date the storage is calculated for.
  */
  'summaryDay': Date;
  /**
  * Total snapshot storage for the day, in bytes.
  */
  'dailyStorageBytes': number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "summaryDay",
      "baseName": "summaryDay",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "dailyStorageBytes",
      "baseName": "dailyStorageBytes",
      "type": "number",
      "format": "int64"
    }  ];

  static getAttributeTypeMap() {
    return DailyStorageSummary.attributeTypeMap;
  }

  public constructor() {
  }
}

