/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class ExcludeFromBackupResponse {
  /**
  * Whether the resource is excluded from backup. Expected to be `true`. 
  */
  'isExcluded': boolean;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "isExcluded",
      "baseName": "isExcluded",
      "type": "boolean",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ExcludeFromBackupResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

