/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class DriftProtectionPolicy {
  /**
  * The ID of the drift protection policy
  */
  'id': string;
  /**
  * The name of the drift protection policy
  */
  'name': string;
  /**
  * The number of resources attached to the drift protection policy
  */
  'numberOfAttachedResources': number;
  /**
  * The number of resources detached from the drift protection policy
  */
  'numberOfDetachedResources': number;
  /**
  * The number of resources excluded from the drift protection policy
  */
  'numberOfExcludedResources': number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "numberOfAttachedResources",
      "baseName": "numberOfAttachedResources",
      "type": "number",
      "format": ""
    },
    {
      "name": "numberOfDetachedResources",
      "baseName": "numberOfDetachedResources",
      "type": "number",
      "format": ""
    },
    {
      "name": "numberOfExcludedResources",
      "baseName": "numberOfExcludedResources",
      "type": "number",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return DriftProtectionPolicy.attributeTypeMap;
  }

  public constructor() {
  }
}

