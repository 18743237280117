/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { DBResourceMetadata } from '../models/DBResourceMetadata';
import { DatabaseRecord } from '../models/DatabaseRecord';

export class DBServerRecord {
  /**
  * The ID of the server
  */
  'serverId': string;
  /**
  * The name of the server
  */
  'serverName': string;
  /**
  * The type of the engine (i.e MySQL, Postgres, etc)
  */
  'engineType': string;
  /**
  * dbs under this server (optional)
  */
  'databases'?: Array<DatabaseRecord>;
  'resource'?: DBResourceMetadata;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "serverId",
      "baseName": "serverId",
      "type": "string",
      "format": ""
    },
    {
      "name": "serverName",
      "baseName": "serverName",
      "type": "string",
      "format": ""
    },
    {
      "name": "engineType",
      "baseName": "engineType",
      "type": "string",
      "format": ""
    },
    {
      "name": "databases",
      "baseName": "databases",
      "type": "Array<DatabaseRecord>",
      "format": ""
    },
    {
      "name": "resource",
      "baseName": "resource",
      "type": "DBResourceMetadata",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return DBServerRecord.attributeTypeMap;
  }

  public constructor() {
  }
}

