/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class Condition {
  /**
  * The condition property
  */
  'property'?: string;
  /**
  * The condition operator
  */
  'operator': string;
  /**
  * The condition value
  */
  'value'?: Array<string>;
  /**
  * Nested conditions
  */
  'conditions'?: Array<Condition>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "property",
      "baseName": "property",
      "type": "string",
      "format": ""
    },
    {
      "name": "operator",
      "baseName": "operator",
      "type": "string",
      "format": ""
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "conditions",
      "baseName": "conditions",
      "type": "Array<Condition>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return Condition.attributeTypeMap;
  }

  public constructor() {
  }
}

