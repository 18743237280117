/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* The source storage category
*/
export class VisualizationHistogramTimePoint {
  /**
  * The date of the category
  */
  'date': Date;
  /**
  * The value of the category
  */
  'value': number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "date",
      "baseName": "date",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "number",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return VisualizationHistogramTimePoint.attributeTypeMap;
  }

  public constructor() {
  }
}

