import type {
  UpdateIntegrationRequest,
  Configuration,
  CreateIntegrationRequest,
} from '@repo/api-gw-sdk';
import {
  DeleteIntegrationApi,
  ListIntegrationsApi,
  UpdateIntegrationApi,
  CreateIntegrationApi,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/integrations';

export const integrations = (httpClient: HttpClient, config: Configuration) => {
  const listIntegrationsApi = new ListIntegrationsApi(config);
  const createIntegrationApi = new CreateIntegrationApi(config);
  const deleteIntegrationApi = new DeleteIntegrationApi(config);
  const updateIntegrationApi = new UpdateIntegrationApi(config);

  return {
    list: () =>
      httpClient.execute([baseUrl], () =>
        listIntegrationsApi.listIntegrations()
      ),
    create: (Integration: CreateIntegrationRequest) =>
      createIntegrationApi.createIntegration(Integration),
    update: (id: string, Integration: UpdateIntegrationRequest) =>
      updateIntegrationApi.updateIntegration(id, Integration),
    delete: (id: string) => deleteIntegrationApi.deleteIntegration(id),
  };
};
