/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class InitAuthInput {
  /**
  * The email of the user
  */
  'email'?: string;
  /**
  * The password of the user
  */
  'password'?: string;
  /**
  * The domain of the account
  */
  'domain'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "email",
      "baseName": "email",
      "type": "string",
      "format": ""
    },
    {
      "name": "password",
      "baseName": "password",
      "type": "string",
      "format": ""
    },
    {
      "name": "domain",
      "baseName": "domain",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return InitAuthInput.attributeTypeMap;
  }

  public constructor() {
  }
}

