/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { DBSnapshotMetadata } from '../models/DBSnapshotMetadata';

export class ListDatabaseSnapshotsResponse {
  'snapshots'?: Array<DBSnapshotMetadata>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "snapshots",
      "baseName": "snapshots",
      "type": "Array<DBSnapshotMetadata>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListDatabaseSnapshotsResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

