/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class GroupToRoleMapping {
  /**
  * The group name of the mapping
  */
  'groupName': string;
  /**
  * The description of the mapping
  */
  'description'?: string;
  /**
  * The role ID that the group maps to
  */
  'roleId': string;
  /**
  * The project IDs of the mapping
  */
  'projectIds'?: Array<string>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "groupName",
      "baseName": "groupName",
      "type": "string",
      "format": ""
    },
    {
      "name": "description",
      "baseName": "description",
      "type": "string",
      "format": ""
    },
    {
      "name": "roleId",
      "baseName": "roleId",
      "type": "string",
      "format": ""
    },
    {
      "name": "projectIds",
      "baseName": "projectIds",
      "type": "Array<string>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return GroupToRoleMapping.attributeTypeMap;
  }

  public constructor() {
  }
}

