/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class DatabaseProperties {
  /**
  * Total database storage size, in bytes.
  */
  'sizeBytes': number;
  /**
  * Database cluster ID.
  */
  'clusterId': string;
  /**
  * Database engine.
  */
  'engine': string;
  /**
  * Database engine version.
  */
  'engineVersion': string;
  /**
  * Database storage type.
  */
  'storageType': string;
  /**
  * Database storage IOPS.
  */
  'storageIops': number;
  /**
  * Database storage throughput.
  */
  'storageThroughput': number;
  /**
  * Database name.
  */
  'dbName': string;
  /**
  * Database instance class.
  */
  'instanceClass': string;
  /**
  * Whether the database is multi-AZ.
  */
  'multiAZ': boolean;
  /**
  * Whether the database is publicly accessible.
  */
  'publiclyAccessible': boolean;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "sizeBytes",
      "baseName": "sizeBytes",
      "type": "number",
      "format": "int64"
    },
    {
      "name": "clusterId",
      "baseName": "clusterId",
      "type": "string",
      "format": ""
    },
    {
      "name": "engine",
      "baseName": "engine",
      "type": "string",
      "format": ""
    },
    {
      "name": "engineVersion",
      "baseName": "engineVersion",
      "type": "string",
      "format": ""
    },
    {
      "name": "storageType",
      "baseName": "storageType",
      "type": "string",
      "format": ""
    },
    {
      "name": "storageIops",
      "baseName": "storageIops",
      "type": "number",
      "format": ""
    },
    {
      "name": "storageThroughput",
      "baseName": "storageThroughput",
      "type": "number",
      "format": ""
    },
    {
      "name": "dbName",
      "baseName": "dbName",
      "type": "string",
      "format": ""
    },
    {
      "name": "instanceClass",
      "baseName": "instanceClass",
      "type": "string",
      "format": ""
    },
    {
      "name": "multiAZ",
      "baseName": "multiAZ",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "publiclyAccessible",
      "baseName": "publiclyAccessible",
      "type": "boolean",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return DatabaseProperties.attributeTypeMap;
  }

  public constructor() {
  }
}

