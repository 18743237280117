/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class ApplicationOverride {
  /**
  * The resource ID
  */
  'resourceId'?: string;
  /**
  * The name of the application override
  */
  'name'?: string;
  /**
  * The version to override
  */
  'version'?: string;
  /**
  * The criticality of the override
  */
  'criticality'?: number;
  /**
  * Is the application installed on the resource, or to override as not installed.
  */
  'isInstalled'?: boolean;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "resourceId",
      "baseName": "resourceId",
      "type": "string",
      "format": ""
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "version",
      "baseName": "version",
      "type": "string",
      "format": ""
    },
    {
      "name": "criticality",
      "baseName": "criticality",
      "type": "number",
      "format": ""
    },
    {
      "name": "isInstalled",
      "baseName": "isInstalled",
      "type": "boolean",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ApplicationOverride.attributeTypeMap;
  }

  public constructor() {
  }
}

