/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class AuditLogEntitiesInner {
  /**
  * Entity type.
  */
  'entityType'?: string;
  /**
  * Entity ID.
  */
  'entityId'?: string;
  /**
  * Entity display name.
  */
  'displayName'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "entityType",
      "baseName": "entityType",
      "type": "string",
      "format": ""
    },
    {
      "name": "entityId",
      "baseName": "entityId",
      "type": "string",
      "format": ""
    },
    {
      "name": "displayName",
      "baseName": "displayName",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AuditLogEntitiesInner.attributeTypeMap;
  }

  public constructor() {
  }
}

