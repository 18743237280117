import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import type { Configuration } from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpInfo } from '../http/http';
import { ObjectSerializer } from '../models/ObjectSerializer';
import { ApiException } from './exception';
import { isCodeInRange } from '../util';
import type {SecurityAuthentication} from '../auth/auth';


import { GetJobResponse } from '../models/GetJobResponse';
import { ListJobsRequest } from '../models/ListJobsRequest';
import { ListJobsResponse } from '../models/ListJobsResponse';

/**
 * no description
 */
export class JobsApiRequestFactory extends BaseAPIRequestFactory {

  /**
   * Get Job
   * @param projectId The project ID
   * @param id The id of the job
   * @param jobType 
   */
  public async getJob(projectId: string, id: string, jobType?: 'restore' | 'backup' | 'scan', _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("JobsApi", "getJob", "projectId");
    }


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("JobsApi", "getJob", "id");
    }



    // Path Params
    const localVarPath = '/sivan/projects/{projectId}/jobs/{id}'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.GET, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (jobType !== undefined) {
      requestContext.setQueryParam("jobType", ObjectSerializer.serialize(jobType, "'restore' | 'backup' | 'scan'", ""));
    }


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * List Jobs of Type
   * @param projectId The project ID
   * @param pageToken The token for the next page
   * @param pageSize The numbers of items per page
   * @param listJobsRequest The request body for filtering jobs
   */
  public async listJobsOfType(projectId: string, pageToken?: string, pageSize?: number, listJobsRequest?: ListJobsRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("JobsApi", "listJobsOfType", "projectId");
    }





    // Path Params
    const localVarPath = '/sivan/projects/{projectId}/jobs'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (pageToken !== undefined) {
      requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
    }

    // Query Params
    if (pageSize !== undefined) {
      requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", ""));
    }


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(listJobsRequest, "ListJobsRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

}

export class JobsApiResponseProcessor {

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to getJob
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async getJobWithHttpInfo(response: ResponseContext): Promise<HttpInfo<GetJobResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: GetJobResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "GetJobResponse", ""
      ) as GetJobResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: GetJobResponse = await response.body.json() as GetJobResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to listJobsOfType
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async listJobsOfTypeWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ListJobsResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ListJobsResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "ListJobsResponse", ""
      ) as ListJobsResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ListJobsResponse = await response.body.json() as ListJobsResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

}
