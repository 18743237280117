/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AzureSubscription } from '../models/AzureSubscription';

export class AzureSubscriptions {
  'subscriptions': Array<AzureSubscription>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "subscriptions",
      "baseName": "subscriptions",
      "type": "Array<AzureSubscription>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AzureSubscriptions.attributeTypeMap;
  }

  public constructor() {
  }
}

