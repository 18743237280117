/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AzureMarketplaceWebhookInputSubscriptionBeneficiary } from '../models/AzureMarketplaceWebhookInputSubscriptionBeneficiary';
import { AzureMarketplaceWebhookInputSubscriptionPurchaser } from '../models/AzureMarketplaceWebhookInputSubscriptionPurchaser';
import { AzureMarketplaceWebhookInputSubscriptionTerm } from '../models/AzureMarketplaceWebhookInputSubscriptionTerm';

/**
* Details of the subscription.
*/
export class AzureMarketplaceWebhookInputSubscription {
  /**
  * Subscription ID in Azure Marketplace.
  */
  'id'?: string;
  /**
  * Name of the subscription.
  */
  'name'?: string;
  /**
  * Publisher ID for the subscription.
  */
  'publisherId'?: string;
  /**
  * Offer ID for the subscription.
  */
  'offerId'?: string;
  /**
  * Current plan ID for the subscription.
  */
  'planId'?: string;
  /**
  * Current quantity of the subscription.
  */
  'quantity'?: number;
  'beneficiary'?: AzureMarketplaceWebhookInputSubscriptionBeneficiary;
  'purchaser'?: AzureMarketplaceWebhookInputSubscriptionPurchaser;
  /**
  * List of operations allowed for the customer.
  */
  'allowedCustomerOperations'?: Array<AzureMarketplaceWebhookInputSubscriptionAllowedCustomerOperationsEnum>;
  /**
  * Session mode for the subscription.
  */
  'sessionMode'?: AzureMarketplaceWebhookInputSubscriptionSessionModeEnum;
  /**
  * Indicates if the subscription is a free trial.
  */
  'isFreeTrial'?: boolean;
  /**
  * Indicates if the subscription is a test instance.
  */
  'isTest'?: boolean;
  /**
  * Sandbox type of the subscription.
  */
  'sandboxType'?: AzureMarketplaceWebhookInputSubscriptionSandboxTypeEnum;
  /**
  * SaaS subscription status.
  */
  'saasSubscriptionStatus'?: AzureMarketplaceWebhookInputSubscriptionSaasSubscriptionStatusEnum;
  'term'?: AzureMarketplaceWebhookInputSubscriptionTerm;
  /**
  * Indicates if the subscription auto-renews.
  */
  'autoRenew'?: boolean;
  /**
  * Creation timestamp of the subscription.
  */
  'created'?: Date;
  /**
  * Last modification timestamp of the subscription.
  */
  'lastModified'?: Date;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": "uuid"
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "publisherId",
      "baseName": "publisherId",
      "type": "string",
      "format": ""
    },
    {
      "name": "offerId",
      "baseName": "offerId",
      "type": "string",
      "format": ""
    },
    {
      "name": "planId",
      "baseName": "planId",
      "type": "string",
      "format": ""
    },
    {
      "name": "quantity",
      "baseName": "quantity",
      "type": "number",
      "format": ""
    },
    {
      "name": "beneficiary",
      "baseName": "beneficiary",
      "type": "AzureMarketplaceWebhookInputSubscriptionBeneficiary",
      "format": ""
    },
    {
      "name": "purchaser",
      "baseName": "purchaser",
      "type": "AzureMarketplaceWebhookInputSubscriptionPurchaser",
      "format": ""
    },
    {
      "name": "allowedCustomerOperations",
      "baseName": "allowedCustomerOperations",
      "type": "Array<AzureMarketplaceWebhookInputSubscriptionAllowedCustomerOperationsEnum>",
      "format": ""
    },
    {
      "name": "sessionMode",
      "baseName": "sessionMode",
      "type": "AzureMarketplaceWebhookInputSubscriptionSessionModeEnum",
      "format": ""
    },
    {
      "name": "isFreeTrial",
      "baseName": "isFreeTrial",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "isTest",
      "baseName": "isTest",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "sandboxType",
      "baseName": "sandboxType",
      "type": "AzureMarketplaceWebhookInputSubscriptionSandboxTypeEnum",
      "format": ""
    },
    {
      "name": "saasSubscriptionStatus",
      "baseName": "saasSubscriptionStatus",
      "type": "AzureMarketplaceWebhookInputSubscriptionSaasSubscriptionStatusEnum",
      "format": ""
    },
    {
      "name": "term",
      "baseName": "term",
      "type": "AzureMarketplaceWebhookInputSubscriptionTerm",
      "format": ""
    },
    {
      "name": "autoRenew",
      "baseName": "autoRenew",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "created",
      "baseName": "created",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "lastModified",
      "baseName": "lastModified",
      "type": "Date",
      "format": "date-time"
    }  ];

  static getAttributeTypeMap() {
    return AzureMarketplaceWebhookInputSubscription.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum AzureMarketplaceWebhookInputSubscriptionAllowedCustomerOperationsEnum {
  Delete = 'Delete',
  Update = 'Update',
  Read = 'Read'
}
export enum AzureMarketplaceWebhookInputSubscriptionSessionModeEnum {
  None = 'None'
}
export enum AzureMarketplaceWebhookInputSubscriptionSandboxTypeEnum {
  None = 'None'
}
export enum AzureMarketplaceWebhookInputSubscriptionSaasSubscriptionStatusEnum {
  Subscribed = 'Subscribed',
  Suspended = 'Suspended',
  Unsubscribed = 'Unsubscribed'
}

