/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { IdFilters } from '../models/IdFilters';
import { NotificationPolicyIdFilters } from '../models/NotificationPolicyIdFilters';
import { NotificationStatusFilters } from '../models/NotificationStatusFilters';
import { NotificationTypeFilters } from '../models/NotificationTypeFilters';

export class NotificationFilterConditions {
  'id'?: IdFilters;
  'status'?: NotificationStatusFilters;
  'type'?: NotificationTypeFilters;
  'policyId'?: NotificationPolicyIdFilters;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "IdFilters",
      "format": ""
    },
    {
      "name": "status",
      "baseName": "status",
      "type": "NotificationStatusFilters",
      "format": ""
    },
    {
      "name": "type",
      "baseName": "type",
      "type": "NotificationTypeFilters",
      "format": ""
    },
    {
      "name": "policyId",
      "baseName": "policyId",
      "type": "NotificationPolicyIdFilters",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return NotificationFilterConditions.attributeTypeMap;
  }

  public constructor() {
  }
}

