import {
  GetAccountUIPreferencesApi,
  GetViewerUIPreferencesApi,
  UpdateAccountUIPreferencesApi,
  UpdateViewerUIPreferencesApi,
  type Configuration,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

export const preferences = (httpClient: HttpClient, config: Configuration) => {
  let version = 0;
  const getAccountUIPreferencesApi = new GetAccountUIPreferencesApi(config);
  const updateAccountUIPreferencesApi = new UpdateAccountUIPreferencesApi(
    config
  );
  const getViewerUIPreferencesApi = new GetViewerUIPreferencesApi(config);
  const updateViewerUIPreferencesApi = new UpdateViewerUIPreferencesApi(config);

  return {
    getUserPref: (key: PreferencesKey) =>
      httpClient.execute(['get-user-pref', key, version], () =>
        getViewerUIPreferencesApi
          .getViewerUIPreferences(key)
          .catch(() => undefined)
      ),
    getAccountPref: (key: PreferencesKey) =>
      httpClient.execute(['get-account-pref', key, version], () =>
        getAccountUIPreferencesApi
          .getAccountUIPreferences(key)
          .catch(() => undefined)
      ),
    updateAccountPref: (key: PreferencesKey, value: object) => {
      version++;
      return updateAccountUIPreferencesApi.updateAccountUIPreferences(
        key,
        value
      );
    },
    updateUserPref: (key: PreferencesKey, value: object) => {
      version++;
      return updateViewerUIPreferencesApi.updateViewerUIPreferences(key, value);
    },
  };
};

export enum PreferencesKey {
  BackupVault = 'backup-vaults',
  CurrentProject = 'current-project',
  RestoreBucket = 'restore-bucket',
  Preferences = 'ui-preferences',
}
