import type { Configuration } from '@repo/api-gw-sdk';

import { auditLogs } from './auditLog';
import { azure } from './azure';
import { backupPolicy } from './backupPolicy';
import { cloudAccounts } from './cloudAccounts';
import { controls } from './controls';
import { convert } from './convert';
import { dashboards } from './dashboards';
import { db } from './db';
import { discovery } from './discovery';
import { explorer } from './explorer';
import type { HttpClient } from './httpClient';
import { integrations } from './integrations';
import { inventory } from './inventory';
import { jobs } from './jobs';
import { notifications } from './notifications';
import { preferences } from './preferences';
import { projects } from './projects';
import { restore } from './restore';
import { roles } from './roles';
import { saml } from './saml';
import { search } from './search';
import { users } from './users';
import { vaults } from './vaults';

export const dalFactory = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => ({
  inventory: inventory(httpClient, config, currentProjectId),
  discovery: discovery(httpClient, config, currentProjectId),
  explorer: explorer(httpClient, config, currentProjectId),
  search: search(config, currentProjectId),
  backupPolicy: backupPolicy(httpClient, config, currentProjectId),
  jobs: jobs(httpClient, config, currentProjectId),
  restore: restore(httpClient, config, currentProjectId),
  vaults: vaults(httpClient, config, currentProjectId),
  convert: convert(httpClient, config, currentProjectId),
  projects: projects(httpClient, config),
  users: users(httpClient, config),
  db: db(httpClient, config, currentProjectId),
  preferences: preferences(httpClient, config),
  saml: saml(httpClient, config),
  dashboards: dashboards(httpClient, config, currentProjectId),
  roles: roles(httpClient, config),
  auditLogs: auditLogs(httpClient, config),
  controls: controls(httpClient, config, currentProjectId),
  cloudAccounts: cloudAccounts(httpClient, config, currentProjectId),
  azure: azure(httpClient, config, currentProjectId),
  integrations: integrations(httpClient, config),
  notifications: notifications(httpClient, config, currentProjectId),
});
