/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class QueryDBStatusResponse {
  /**
  * time taken to execute the query in milliseconds
  */
  'executionTime'?: number;
  /**
  * Query execution status
  */
  'status': QueryDBStatusResponseStatusEnum;
  /**
  * Error message if the query failed
  */
  'errorMsg'?: string | null;
  /**
  * Location of the query output
  */
  'outputLocation'?: string | null;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "executionTime",
      "baseName": "executionTime",
      "type": "number",
      "format": ""
    },
    {
      "name": "status",
      "baseName": "status",
      "type": "QueryDBStatusResponseStatusEnum",
      "format": ""
    },
    {
      "name": "errorMsg",
      "baseName": "errorMsg",
      "type": "string",
      "format": ""
    },
    {
      "name": "outputLocation",
      "baseName": "outputLocation",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return QueryDBStatusResponse.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum QueryDBStatusResponseStatusEnum {
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED'
}

