import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import type { Configuration } from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpInfo } from '../http/http';
import { ObjectSerializer } from '../models/ObjectSerializer';
import { ApiException } from './exception';
import { isCodeInRange } from '../util';
import type {SecurityAuthentication} from '../auth/auth';



/**
 * no description
 */
export class AzureOAuthCallbackApiRequestFactory extends BaseAPIRequestFactory {

  /**
   * Callback endpoint for Azure OAuth
   * @param code Azure OAuth code
   * @param state Request id state
   */
  public async azureOAuthCallback(code: string, state: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'code' is not null or undefined
    if (code === null || code === undefined) {
      throw new RequiredError("AzureOAuthCallbackApi", "azureOAuthCallback", "code");
    }


    // verify required parameter 'state' is not null or undefined
    if (state === null || state === undefined) {
      throw new RequiredError("AzureOAuthCallbackApi", "azureOAuthCallback", "state");
    }


    // Path Params
    const localVarPath = '/oauth/azure';

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.GET, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (code !== undefined) {
      requestContext.setQueryParam("code", ObjectSerializer.serialize(code, "string", ""));
    }

    // Query Params
    if (state !== undefined) {
      requestContext.setQueryParam("state", ObjectSerializer.serialize(state, "string", ""));
    }



    return requestContext;
  }

}

export class AzureOAuthCallbackApiResponseProcessor {

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to azureOAuthCallback
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async azureOAuthCallbackWithHttpInfo(response: ResponseContext): Promise<HttpInfo<string >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: string = ObjectSerializer.deserialize(
        await response.body.json(),
        "string", ""
      ) as string;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("302", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect to registration page", undefined, response.headers, response);
    }
    if (isCodeInRange("500", response.httpStatusCode)) {
      const body: string = ObjectSerializer.deserialize(
        await response.body.json(),
        "string", ""
      ) as string;
      throw new ApiException<string>(response.httpStatusCode, "Internal server error", body, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: string = await response.body.json() as string;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

}
