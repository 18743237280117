/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class VaultFilters {
  'containsAnyOf'?: Array<string>;
  'containsNoneOf'?: Array<string>;
  'containsAllOf'?: Array<string>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "containsAnyOf",
      "baseName": "ContainsAnyOf",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "containsNoneOf",
      "baseName": "ContainsNoneOf",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "containsAllOf",
      "baseName": "ContainsAllOf",
      "type": "Array<string>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return VaultFilters.attributeTypeMap;
  }

  public constructor() {
  }
}

