/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { NotificationFilterConditions } from '../models/NotificationFilterConditions';
import { SortNotificationBy } from '../models/SortNotificationBy';

export class ListNotificationsRequest {
  /**
  * List of sorting options. Sorting is applied in the order passed in the list. 
  */
  'sorts'?: Array<SortNotificationBy>;
  'filters'?: NotificationFilterConditions;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "sorts",
      "baseName": "sorts",
      "type": "Array<SortNotificationBy>",
      "format": ""
    },
    {
      "name": "filters",
      "baseName": "filters",
      "type": "NotificationFilterConditions",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListNotificationsRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

