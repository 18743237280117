/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { DBResourceMetadata } from '../models/DBResourceMetadata';
import { DBSnapshotMetadata } from '../models/DBSnapshotMetadata';
import { UnifiedTableRecord } from '../models/UnifiedTableRecord';

export class UnifiedSchemaRecord {
  /**
  * name of the schema
  */
  'name': string;
  /**
  * name of the db of the schema
  */
  'dbName': string;
  /**
  * number of tables under this schema
  */
  'tableCount': number;
  'tables'?: Array<UnifiedTableRecord>;
  'resource'?: DBResourceMetadata;
  'snapshot'?: DBSnapshotMetadata;
  /**
  * Id of the server of the schema
  */
  'serverId': string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "dbName",
      "baseName": "dbName",
      "type": "string",
      "format": ""
    },
    {
      "name": "tableCount",
      "baseName": "tableCount",
      "type": "number",
      "format": ""
    },
    {
      "name": "tables",
      "baseName": "tables",
      "type": "Array<UnifiedTableRecord>",
      "format": ""
    },
    {
      "name": "resource",
      "baseName": "resource",
      "type": "DBResourceMetadata",
      "format": ""
    },
    {
      "name": "snapshot",
      "baseName": "snapshot",
      "type": "DBSnapshotMetadata",
      "format": ""
    },
    {
      "name": "serverId",
      "baseName": "serverId",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return UnifiedSchemaRecord.attributeTypeMap;
  }

  public constructor() {
  }
}

