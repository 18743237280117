/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class ListInventoryBackupRegionsResponse {
  'regions': Array<string>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "regions",
      "baseName": "regions",
      "type": "Array<string>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListInventoryBackupRegionsResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

