/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { DataClassificationEntity } from '../models/DataClassificationEntity';
import { Pagination } from '../models/Pagination';

export class ListDataClassificationEntitiesResponse {
  'dataClassificationEntities': Array<DataClassificationEntity>;
  'pagination': Pagination;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "dataClassificationEntities",
      "baseName": "dataClassificationEntities",
      "type": "Array<DataClassificationEntity>",
      "format": ""
    },
    {
      "name": "pagination",
      "baseName": "pagination",
      "type": "Pagination",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListDataClassificationEntitiesResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

