/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AuditLog } from '../models/AuditLog';
import { Pagination } from '../models/Pagination';

export class ListAuditLogsResponse {
  'data'?: Array<AuditLog>;
  'pagination': Pagination;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "data",
      "baseName": "data",
      "type": "Array<AuditLog>",
      "format": ""
    },
    {
      "name": "pagination",
      "baseName": "pagination",
      "type": "Pagination",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListAuditLogsResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

