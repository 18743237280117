/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { MaxRetentionRule } from '../models/MaxRetentionRule';
import { MinRetentionRule } from '../models/MinRetentionRule';
import { NumberOfCopiesRule } from '../models/NumberOfCopiesRule';

export class ControlRules {
  'minimumRetention'?: Array<MinRetentionRule>;
  'maximumRetention'?: MaxRetentionRule;
  'numberOfCopies'?: NumberOfCopiesRule;
  'crossRegion'?: boolean;
  'crossAccount'?: boolean;
  'crossCloudProvider'?: boolean;
  'objectLock'?: boolean;
  'backup321'?: boolean;
  'eonBackupEnabled'?: boolean;
  'generalBackupEnabled'?: boolean;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "minimumRetention",
      "baseName": "minimumRetention",
      "type": "Array<MinRetentionRule>",
      "format": ""
    },
    {
      "name": "maximumRetention",
      "baseName": "maximumRetention",
      "type": "MaxRetentionRule",
      "format": ""
    },
    {
      "name": "numberOfCopies",
      "baseName": "numberOfCopies",
      "type": "NumberOfCopiesRule",
      "format": ""
    },
    {
      "name": "crossRegion",
      "baseName": "crossRegion",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "crossAccount",
      "baseName": "crossAccount",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "crossCloudProvider",
      "baseName": "crossCloudProvider",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "objectLock",
      "baseName": "objectLock",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "backup321",
      "baseName": "backup321",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "eonBackupEnabled",
      "baseName": "eonBackupEnabled",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "generalBackupEnabled",
      "baseName": "generalBackupEnabled",
      "type": "boolean",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ControlRules.attributeTypeMap;
  }

  public constructor() {
  }
}

