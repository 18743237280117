'use client';

import type { Account, EonAccount, Viewer } from '@repo/api-gw-sdk';
import * as Sentry from '@sentry/nextjs';
import {
  createContext,
  useEffect,
  useState,
  type PropsWithChildren,
} from 'react';

import { useDAL } from '@/data/dal';
import { PreferencesKey } from '@/data/dal/preferences';

export const UserContext = createContext<{
  user: Viewer | undefined;
  sourceAccounts: Account[] | undefined;
  eonAccount: EonAccount | undefined;
  isApplicationReady: boolean | undefined;
  recalculateAccounts: () => void;
  currentProjectId: string;
  changeRole: (roleId: string) => Promise<unknown>;
  changeProject: (projectId: string) => Promise<void>;
} | null>(null);

export const UserProvider = ({ children }: PropsWithChildren) => {
  const [currentProjectId, setCurrentProjectId] = useState('');
  const dal = useDAL(currentProjectId);

  const { body: user } = dal.users.getViewer();
  const { body: projects } = dal.projects.list();
  const { body: accounts, mutate: mutateAccounts } =
    dal.cloudAccounts.source.list();
  const { body: pref } = dal.preferences.getUserPref(
    PreferencesKey.CurrentProject
  );

  useEffect(() => {
    let initialProjectId = '';
    if (user?.selectedRole.isProjectRole) {
      if (user.roleToProjectsMappings[user.selectedRole.id].ids?.length) {
        initialProjectId =
          user.roleToProjectsMappings[user.selectedRole.id].ids![0];
      }
    } else {
      if (projects?.projects?.length) {
        initialProjectId = projects.projects[0].id;
      }
    }

    if (pref?.value) {
      if (
        !user?.selectedRole.isProjectRole ||
        user.roleToProjectsMappings[user.selectedRole.id]?.ids?.includes(
          pref.value.projectId
        )
      ) {
        initialProjectId = pref.value.projectId;
      }
    }

    if (initialProjectId) {
      setCurrentProjectId(initialProjectId);
    }
  }, [pref, projects, accounts, user]);

  useEffect(() => {
    // Enrich sentry errors
    Sentry.setTag('project', currentProjectId);
    Sentry.setTag('user', user?.id || '');
    Sentry.setTag('role', user?.selectedRole.id || '');
  }, [currentProjectId, user?.id, user?.selectedRole.id]);

  return (
    <UserContext.Provider
      value={{
        isApplicationReady: !!user && !!currentProjectId && !!accounts,
        user,
        sourceAccounts: accounts?.accounts,
        eonAccount: user?.eonAccount,
        recalculateAccounts: () => void mutateAccounts(),
        currentProjectId,
        changeProject: (newProjectId) =>
          dal.preferences
            .updateUserPref(PreferencesKey.CurrentProject, {
              projectId: newProjectId,
            })
            .then(() => setCurrentProjectId(newProjectId)),
        changeRole: (roleId) =>
          dal.users
            .changeRole(roleId)
            .then(() => dal.users.refreshToken(window.location.href)),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
