/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { DBResourceMetadata } from '../models/DBResourceMetadata';
import { DBSnapshotMetadata } from '../models/DBSnapshotMetadata';
import { UnifiedSchemaRecord } from '../models/UnifiedSchemaRecord';

export class UnifiedDatabaseRecord {
  /**
  * name of the db
  */
  'name': string;
  /**
  * Id of the server of the db
  */
  'serverId': string;
  /**
  * number of schemas under this db
  */
  'schemaCount': number;
  /**
  * schemas under this db
  */
  'schemas'?: Array<UnifiedSchemaRecord>;
  /**
  * reserved for elastic use
  */
  'recordType'?: string;
  'snapshot'?: DBSnapshotMetadata;
  'resource'?: DBResourceMetadata;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "serverId",
      "baseName": "serverId",
      "type": "string",
      "format": ""
    },
    {
      "name": "schemaCount",
      "baseName": "schemaCount",
      "type": "number",
      "format": ""
    },
    {
      "name": "schemas",
      "baseName": "schemas",
      "type": "Array<UnifiedSchemaRecord>",
      "format": ""
    },
    {
      "name": "recordType",
      "baseName": "record_type",
      "type": "string",
      "format": ""
    },
    {
      "name": "snapshot",
      "baseName": "snapshot",
      "type": "DBSnapshotMetadata",
      "format": ""
    },
    {
      "name": "resource",
      "baseName": "resource",
      "type": "DBResourceMetadata",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return UnifiedDatabaseRecord.attributeTypeMap;
  }

  public constructor() {
  }
}

