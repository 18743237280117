/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SnapshotProperties } from '../models/SnapshotProperties';
import { SnapshotStatus } from '../models/SnapshotStatus';

/**
* Eon snapshot.
*/
export class Snapshot {
  /**
  * Eon snapshot ID.
  */
  'id': string;
  /**
  * ID of the snapshot\'s parent project.
  */
  'projectId'?: string;
  /**
  * Date and time the snapshot creation was started. This doesn\'t represent the point in time the resource is backed up from, which is instead represented by the `pointInTime` property. 
  */
  'createTime': Date;
  /**
  * Date and time of the resource that\'s preserved by the snapshot.
  */
  'pointInTime'?: Date;
  /**
  * ID of the vault the snapshot is stored in.
  */
  'vaultId': string;
  /**
  * Eon-assigned ID of the resource the snapshot is backing up.
  */
  'resourceId': string;
  /**
  * Date and time the snapshot\'s retention is expected to expire, after which it\'s marked for deletion.
  */
  'expirationTime'?: Date;
  /**
  * The backup retention in days
  */
  'backupRetention'?: number;
  /**
  * Whether the snapshot is locked
  */
  'lock': boolean;
  'status': SnapshotStatus;
  'resourceProperties'?: SnapshotProperties;
  /**
  * Size of the full snapshot in bytes.
  */
  'fullSizeBytes'?: number;
  /**
  * Size of the incremental snapshot in bytes
  */
  'incrementalSizeBytes'?: number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "projectId",
      "baseName": "projectId",
      "type": "string",
      "format": ""
    },
    {
      "name": "createTime",
      "baseName": "createTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "pointInTime",
      "baseName": "pointInTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "vaultId",
      "baseName": "vaultId",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceId",
      "baseName": "resourceId",
      "type": "string",
      "format": ""
    },
    {
      "name": "expirationTime",
      "baseName": "expirationTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "backupRetention",
      "baseName": "backupRetention",
      "type": "number",
      "format": ""
    },
    {
      "name": "lock",
      "baseName": "lock",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "status",
      "baseName": "status",
      "type": "SnapshotStatus",
      "format": ""
    },
    {
      "name": "resourceProperties",
      "baseName": "resourceProperties",
      "type": "SnapshotProperties",
      "format": ""
    },
    {
      "name": "fullSizeBytes",
      "baseName": "fullSizeBytes",
      "type": "number",
      "format": "int64"
    },
    {
      "name": "incrementalSizeBytes",
      "baseName": "incrementalSizeBytes",
      "type": "number",
      "format": "int64"
    }  ];

  static getAttributeTypeMap() {
    return Snapshot.attributeTypeMap;
  }

  public constructor() {
  }
}



