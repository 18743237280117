/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class RestoreAccount {
  /**
  * The ID of the account
  */
  'id': string;
  /**
  * The cloud provider of the account
  */
  'cloudProvider': RestoreAccountCloudProviderEnum;
  /**
  * The account ID
  */
  'providerAccountId': string;
  /**
  * The role to access the account
  */
  'roleArn': string;
  /**
  * The relevant regions for the account
  */
  'regions'?: Array<string>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "cloudProvider",
      "baseName": "cloudProvider",
      "type": "RestoreAccountCloudProviderEnum",
      "format": ""
    },
    {
      "name": "providerAccountId",
      "baseName": "providerAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "roleArn",
      "baseName": "roleArn",
      "type": "string",
      "format": ""
    },
    {
      "name": "regions",
      "baseName": "regions",
      "type": "Array<string>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreAccount.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum RestoreAccountCloudProviderEnum {
  Aws = 'AWS',
  Azure = 'Azure'
}

