import {
  CreateBackupVaultApi,
  DeleteBackupVaultApi,
  ListBackupVaultsApi,
  ListVaultAccountsApi,
  UpdateBackupVaultNameApi,
  type Configuration,
} from '@repo/api-gw-sdk';

import type { BackupVault } from '@/data/vaults/backupVault';

import type { HttpClient } from './httpClient';

const baseUrl = '/vaults';
export const vaults = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const getVaultsClient = new ListBackupVaultsApi(config);
  const createVaultClient = new CreateBackupVaultApi(config);
  const updateVaultClient = new UpdateBackupVaultNameApi(config);
  const deleteVaultClient = new DeleteBackupVaultApi(config);
  const vaultAccountsClient = new ListVaultAccountsApi(config);
  return {
    list: () =>
      httpClient.execute([`${baseUrl}/vaults/`, currentProjectId], () =>
        getVaultsClient.listVaults(currentProjectId)
      ),
    create: (vault: BackupVault) =>
      createVaultClient.createVault(currentProjectId, {
        name: vault.name,
        cloudProvider: vault.cloudProvider,
        region: vault.region,
        cloudAccountId: vault.cloudAccountId,
        objectLock: vault.objectLock,
      }),
    update: (vault: BackupVault) =>
      updateVaultClient.updateVaultName(vault.id, currentProjectId, {
        name: vault.name,
      }),
    delete: (vaultId: string) =>
      deleteVaultClient.deleteVault(vaultId, currentProjectId),
    getAccounts: () =>
      httpClient.execute([`${baseUrl}/vaultaccounts/`], () =>
        vaultAccountsClient.listVaultAccounts()
      ),
  };
};
