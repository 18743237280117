/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class DataClassificationEntity {
  /**
  * The private ID of the data classification item
  */
  'id': string;
  /**
  * The resource ID of the inventory item in the cloud provider
  */
  'resourceId': string;
  /**
  * The type of the data classification entity item
  */
  'entityType': string;
  /**
  * The data location - for files, it\'s the file path. For DB - it\'s the database metadata
  */
  'dataLocation'?: { [key: string]: any; };

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceId",
      "baseName": "resourceId",
      "type": "string",
      "format": ""
    },
    {
      "name": "entityType",
      "baseName": "entityType",
      "type": "string",
      "format": ""
    },
    {
      "name": "dataLocation",
      "baseName": "dataLocation",
      "type": "{ [key: string]: any; }",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return DataClassificationEntity.attributeTypeMap;
  }

  public constructor() {
  }
}

