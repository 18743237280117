/**
 * Returns if a specific http code is in a given code range
 * where the code range is defined as a combination of digits
 * and "X" (the letter X) with a length of 3
 *
 * @param codeRange string with length 3 consisting of digits and "X" (the letter X)
 * @param code the http status code to be checked against the code range
 */
export function isCodeInRange(codeRange: string, code: number): boolean {
  // This is how the default value is encoded in OAG
  if (codeRange === "0") {
    return true;
  }
  if (codeRange === code.toString()) {
    return true;
  } else {
    const codeString = code.toString();
    if (codeString.length != codeRange.length) {
      return false;
    }
    for (let i = 0; i < codeString.length; i++) {
      if (codeRange.charAt(i) != "X" && codeRange.charAt(i) != codeString.charAt(i)) {
        return false;
      }
    }
    return true;
  }
}

/**
 * Retries a given function n times until it returns a value and does not throw.
 * Uses an exponential backoff strategy with a base delay of 100ms.
 * @param fn the function to be retried
 * @param n the number of times to retry
 */
export async function retry<T>(fn: () => Promise<T>, n: number = 1, codesToIgnore: number[] = []): Promise<T> {
  const baseDelay = 100;
  for (let i = 0; i < n; i++) {
    try {
      return await fn();
    } catch (error: any) {
      if (error.code && codesToIgnore.includes(error.code)) {
        throw error;
      }
      if (i == n - 1) {
        throw error;
      }
      await new Promise((resolve) => setTimeout(resolve, baseDelay * 2 ** i));
    }
  }
  throw new RangeError("Reached unreachable retry code");
}
