/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SsoIntegrationSettings } from '../models/SsoIntegrationSettings';

export class ServiceProviderDetails {
  'ssoIntegrationSettings'?: SsoIntegrationSettings;
  /**
  * The public certificate of the service provider
  */
  'pubCertificate'?: string;
  /**
  * The IDP initiated login URL of the service provider
  */
  'idpInitiatedLoginUrl'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "ssoIntegrationSettings",
      "baseName": "ssoIntegrationSettings",
      "type": "SsoIntegrationSettings",
      "format": ""
    },
    {
      "name": "pubCertificate",
      "baseName": "pubCertificate",
      "type": "string",
      "format": ""
    },
    {
      "name": "idpInitiatedLoginUrl",
      "baseName": "idpInitiatedLoginUrl",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ServiceProviderDetails.attributeTypeMap;
  }

  public constructor() {
  }
}

