/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { VolumeSettings } from '../models/VolumeSettings';

export class InventoryVolume {
  /**
  * The volume\'s Eon-assigned resource ID.
  */
  'id': string;
  /**
  * The volume\'s cloud-provider-assigned resource ID.
  */
  'providerResourceId': string;
  /**
  * Volume path on the machine it\'s attached to.
  */
  'path': string;
  /**
  * Volume region.
  */
  'regionName': string;
  /**
  * ID of the encryption key used to encrypt the volume.
  */
  'encryptionKeyId'?: string;
  /**
  * Volume tags.
  */
  'tags': { [key: string]: string; };
  /**
  * Volume availabilityzone.
  */
  'availabilityZone': string;
  'volumeSettings': VolumeSettings;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "providerResourceId",
      "baseName": "providerResourceId",
      "type": "string",
      "format": ""
    },
    {
      "name": "path",
      "baseName": "path",
      "type": "string",
      "format": ""
    },
    {
      "name": "regionName",
      "baseName": "regionName",
      "type": "string",
      "format": ""
    },
    {
      "name": "encryptionKeyId",
      "baseName": "encryptionKeyId",
      "type": "string",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "availabilityZone",
      "baseName": "availabilityZone",
      "type": "string",
      "format": ""
    },
    {
      "name": "volumeSettings",
      "baseName": "volumeSettings",
      "type": "VolumeSettings",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return InventoryVolume.attributeTypeMap;
  }

  public constructor() {
  }
}

