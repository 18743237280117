/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { ColumnMetadata } from '../models/ColumnMetadata';

export class TableRecord {
  /**
  * The name of the table
  */
  'name': string;
  /**
  * Number of rows in the table
  */
  'rowCount': number;
  /**
  * The columns of the table
  */
  'columns'?: Array<ColumnMetadata>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "rowCount",
      "baseName": "rowCount",
      "type": "number",
      "format": "int64"
    },
    {
      "name": "columns",
      "baseName": "columns",
      "type": "Array<ColumnMetadata>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return TableRecord.attributeTypeMap;
  }

  public constructor() {
  }
}

