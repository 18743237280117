import {
  FileExplorerListPathApi,
  ListPathSnapshotsApi,
  type Configuration,
} from '@repo/api-gw-sdk';

import { setEndOfDay, setStartOfDay } from '@/utils/dateTime';

import type { HttpClient } from './httpClient';

export const explorer = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const snapshotsClient = new ListPathSnapshotsApi(config);
  const listPathClient = new FileExplorerListPathApi(config);

  return {
    listPath: (
      resourceId: string,
      prefix: string,
      pageSize: number,
      pageToken?: string
    ) => {
      return httpClient.execute(
        ['listPath', resourceId, prefix, pageSize, pageToken, currentProjectId],
        () =>
          listPathClient.fileExplorerListPath(
            currentProjectId,
            resourceId,
            prefix,
            pageSize,
            pageToken
          )
      );
    },
    listPathSnapshots: (
      resourceId: string,
      path: string,
      start: Date,
      end: Date,
      pageIndex: number,
      pageSize: number
    ) => {
      const startWithoutTime = setStartOfDay(start);
      const endWithoutTime = setEndOfDay(end);
      return httpClient.execute(
        [
          'listPathSnapshots',
          resourceId,
          startWithoutTime.toISOString(),
          endWithoutTime.toISOString(),
          path,
          pageIndex,
          pageSize,
          currentProjectId,
        ],
        () =>
          snapshotsClient.listPathSnapshots(
            resourceId,
            startWithoutTime,
            endWithoutTime,
            pageIndex,
            pageSize,
            currentProjectId,
            {
              path,
            }
          )
      );
    },
  };
};
