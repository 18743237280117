/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* Purchaser details.
*/
export class AzureMarketplaceWebhookInputSubscriptionPurchaser {
  /**
  * Email ID of the purchaser.
  */
  'emailId'?: string;
  /**
  * Object ID of the purchaser.
  */
  'objectId'?: string;
  /**
  * Tenant ID of the purchaser.
  */
  'tenantId'?: string;
  /**
  * Purchaser\'s PUID.
  */
  'puid'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "emailId",
      "baseName": "emailId",
      "type": "string",
      "format": "email"
    },
    {
      "name": "objectId",
      "baseName": "objectId",
      "type": "string",
      "format": "uuid"
    },
    {
      "name": "tenantId",
      "baseName": "tenantId",
      "type": "string",
      "format": "uuid"
    },
    {
      "name": "puid",
      "baseName": "puid",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AzureMarketplaceWebhookInputSubscriptionPurchaser.attributeTypeMap;
  }

  public constructor() {
  }
}

