/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class InventoryResourceMetadata {
  /**
  * The allowed actions to run on the inventory item, based on data access control
  */
  'disallowedScopes'?: Array<string>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "disallowedScopes",
      "baseName": "disallowedScopes",
      "type": "Array<string>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return InventoryResourceMetadata.attributeTypeMap;
  }

  public constructor() {
  }
}

