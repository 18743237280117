import type { Middleware } from "./middleware";
import { ServerConfiguration } from "./servers";
import { server1 } from "./servers";
import { configureAuthMethods } from "./auth/auth";
import type {  AuthMethods, AuthMethodsConfiguration } from "./auth/auth";

export interface Configuration {
  readonly baseServer?: ServerConfiguration<any>;
  readonly middleware?: Middleware[];
  readonly authMethods?: AuthMethods;
  readonly timeout?: number;
  readonly retries?: number;
  readonly signal?: AbortSignal;
}


/**
 * Interface with which a configuration object can be configured.
 */
export interface ConfigurationParameters {
  /**
   * The middlewares which will be applied to requests and responses. You can 
   * add any number of middleware components to modify requests before they 
   * are sent or before they are deserialized by implementing the `Middleware`
   * interface defined in `./middleware`
   */
  middleware?: Middleware[];
  /**
   * Configuration for the available authentication methods (e.g., api keys) 
   * according to the OpenAPI yaml definition. For the definition, please refer to 
   * `./auth/auth`
   */
  authMethods?: AuthMethodsConfiguration

  /**
  * An optional request timeout for requests
  *
  */
  timeout?: number
  /**
  * A retry parameter to idempotent (GET/HEAD) requests, passing this will make
  * requests automatically retried
  */
  retries?: number

  /** 
  * Base API server URL requests are relative to
  *
  */
  baseUrl?: string
}

/**
 * Provide your `ConfigurationParameters` to this function to get a `Configuration`
 * object that can be used to configure your APIs (in the constructor or 
 * for each request individually).
 *
 * If a property is not included in conf, a default is used:
 *  - middleware: []
 *  - authMethods: {}
 *
 * @param conf partial configuration
 */
export function createConfiguration(conf: ConfigurationParameters): Configuration {
  const configuration: Configuration = {
    baseServer: new ServerConfiguration(conf.baseUrl || "", {}),
    middleware: conf.middleware || [],
    authMethods: configureAuthMethods(conf.authMethods),
    timeout: conf.timeout,
    retries: conf.retries ?? 1,
  };
  return configuration;
}