/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { VolumeSettings } from '../models/VolumeSettings';

export class RestoreVolumeToEbsRequest {
  /**
  * Cloud-provider-assigned ID of the volume to restore.
  */
  'providerVolumeId': string;
  /**
  * Eon-assigned ID of the restore account.
  */
  'restoreAccountId': string;
  /**
  * Description to apply to the restored volume.  Default: No description. 
  */
  'description'?: string;
  /**
  * Tags to apply to the restored volume as key-value pairs, where key and value are both strings.
  */
  'tags': { [key: string]: string; };
  /**
  * ID of the key you want Eon to use for encrypting the restored volume.
  */
  'volumeEncryptionKeyId'?: string;
  /**
  * ID of the key you want Eon to use for encrypting temporary restore artifacts. This can be the same key as `volumeEncryptionKeyId`. 
  */
  'encryptionKeyId'?: string;
  /**
  * Availability zone to restore the volume to.
  */
  'availabilityZone': string;
  'volumeSettings': VolumeSettings;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "providerVolumeId",
      "baseName": "providerVolumeId",
      "type": "string",
      "format": ""
    },
    {
      "name": "restoreAccountId",
      "baseName": "restoreAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "description",
      "baseName": "description",
      "type": "string",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "volumeEncryptionKeyId",
      "baseName": "volumeEncryptionKeyId",
      "type": "string",
      "format": ""
    },
    {
      "name": "encryptionKeyId",
      "baseName": "encryptionKeyId",
      "type": "string",
      "format": ""
    },
    {
      "name": "availabilityZone",
      "baseName": "availabilityZone",
      "type": "string",
      "format": ""
    },
    {
      "name": "volumeSettings",
      "baseName": "volumeSettings",
      "type": "VolumeSettings",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreVolumeToEbsRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

