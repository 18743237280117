/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { IdFilters } from '../models/IdFilters';
import { JobsFilterConditionsUserId } from '../models/JobsFilterConditionsUserId';
import { ResourceIdFilters } from '../models/ResourceIdFilters';
import { StatusJobFilters } from '../models/StatusJobFilters';

export class JobsFilterConditions {
  'id'?: IdFilters;
  'userId'?: JobsFilterConditionsUserId;
  'resourceId'?: ResourceIdFilters;
  'status'?: StatusJobFilters;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "IdFilters",
      "format": ""
    },
    {
      "name": "userId",
      "baseName": "userId",
      "type": "JobsFilterConditionsUserId",
      "format": ""
    },
    {
      "name": "resourceId",
      "baseName": "resourceId",
      "type": "ResourceIdFilters",
      "format": ""
    },
    {
      "name": "status",
      "baseName": "status",
      "type": "StatusJobFilters",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return JobsFilterConditions.attributeTypeMap;
  }

  public constructor() {
  }
}

