/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* Term details of the subscription.
*/
export class AzureMarketplaceWebhookInputSubscriptionTerm {
  /**
  * Start date of the subscription term.
  */
  'startDate'?: Date;
  /**
  * End date of the subscription term.
  */
  'endDate'?: Date;
  /**
  * Unit of the term.
  */
  'termUnit'?: AzureMarketplaceWebhookInputSubscriptionTermTermUnitEnum;
  /**
  * Charge duration for the subscription.
  */
  'chargeDuration'?: string | null;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "startDate",
      "baseName": "startDate",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "endDate",
      "baseName": "endDate",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "termUnit",
      "baseName": "termUnit",
      "type": "AzureMarketplaceWebhookInputSubscriptionTermTermUnitEnum",
      "format": ""
    },
    {
      "name": "chargeDuration",
      "baseName": "chargeDuration",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AzureMarketplaceWebhookInputSubscriptionTerm.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum AzureMarketplaceWebhookInputSubscriptionTermTermUnitEnum {
  P1M = 'P1M',
  P1Y = 'P1Y'
}

