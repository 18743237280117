/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { Condition } from '../models/Condition';

export class DataAccessRule {
  /**
  * The name of the data access rule
  */
  'name'?: string;
  /**
  * The scopes of the data access rule
  */
  'scopes': Array<string>;
  'condition': Condition;
  /**
  * Whether the rule is inclusive or exclusive
  */
  'isInclusive': boolean;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "scopes",
      "baseName": "scopes",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "condition",
      "baseName": "condition",
      "type": "Condition",
      "format": ""
    },
    {
      "name": "isInclusive",
      "baseName": "isInclusive",
      "type": "boolean",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return DataAccessRule.attributeTypeMap;
  }

  public constructor() {
  }
}

