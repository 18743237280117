import {
  CombineOperator,
  ListOperator,
  NumberOperator,
  PropertyType,
  StringOperator,
  type Condition,
  type FilterProperty,
} from '@/types/advanceFilter';

const toOpText = function (
  operator: NumberOperator | StringOperator | ListOperator | CombineOperator
): string {
  switch (operator) {
    case StringOperator.Equals:
    case NumberOperator.Equals:
    case ListOperator.Equals:
      return '=';
    case StringOperator.NotEquals:
    case NumberOperator.NotEquals:
    case ListOperator.NotEquals:
      return '<>';
    case StringOperator.Contains:
    case ListOperator.Contains:
      return 'contains';
    case StringOperator.NotContains:
    case ListOperator.NotContains:
      return 'not contains';
    case StringOperator.StartsWith:
      return 'starts with';
    case StringOperator.EndsWith:
      return 'ends with';
    case StringOperator.Empty:
    case ListOperator.Empty:
      return 'empty';
    case StringOperator.NotEmpty:
    case ListOperator.NotEmpty:
      return 'not empty';
    case NumberOperator.GreaterThan:
      return '>';
    case NumberOperator.GreaterThanOrEqual:
      return '>=';
    case NumberOperator.LessThan:
      return '<';
    case NumberOperator.LessThanOrEqual:
      return '<=';
    case ListOperator.In:
      return 'in';
    case ListOperator.NotIn:
      return 'not in';
    case CombineOperator.And:
      return 'AND';
    case CombineOperator.Or:
      return 'OR';
    default:
      return 'unknown';
  }
};

const toValueText = function (
  value: string | number | string[] | null,
  property: FilterProperty | undefined
): string {
  if (!value) return 'null';

  switch (property?.type) {
    case PropertyType.String:
      return `"${value.toString()}"`;
    case PropertyType.Number:
      return value.toString();
    case PropertyType.Date:
      return value.toString();
    case PropertyType.SingleValue:
      return `"${value.toString()}"`;
    case PropertyType.MultipleValues:
      if (!Array.isArray(value)) return `"${value.toString()}"`;
      return `[${value.map((val) => `"${val.toString()}"`).join(', ')}]`;
    default:
      return `"${value.toString()}"`;
  }
};

export const toText = function (
  query: Condition,
  properties: FilterProperty[]
): string {
  if (!query) {
    return '';
  }

  if (query.type === 'Combine') {
    return `[${query.conditions
      .map((condition) => toText(condition, properties))
      .join(` ${toOpText(query.operator)} `)}]`;
  }

  return `${query.property} ${toOpText(query.operator)} ${toValueText(
    query.value,
    properties.find((p) => p.name === query.property)
  )}`;
};

export const toJsonText = function (query: Condition): string {
  if (!query) {
    return '';
  }

  return JSON.stringify(query, (key, value) => {
    if (value.type === 'Combine' && !value.conditions?.length) {
      return undefined;
    }
    return value;
  });
};
