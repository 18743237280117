/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { GroupToRoleMapping } from '../models/GroupToRoleMapping';

export class CreateSamlIdentityProviderConfigInput {
  /**
  * The name of the saml identity provider
  */
  'providerName': string;
  /**
  * The SSO URL of the saml identity provider
  */
  'ssoUrl': string;
  /**
  * The logout URL of the saml identity provider
  */
  'logoutUrl'?: string;
  /**
  * The issuer URL of the saml identity provider
  */
  'issuerUrl': string;
  /**
  * The public cert of the saml identity provider
  */
  'certificate': string;
  'groupToRoleMappingList': Array<GroupToRoleMapping>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "providerName",
      "baseName": "providerName",
      "type": "string",
      "format": ""
    },
    {
      "name": "ssoUrl",
      "baseName": "ssoUrl",
      "type": "string",
      "format": ""
    },
    {
      "name": "logoutUrl",
      "baseName": "logoutUrl",
      "type": "string",
      "format": ""
    },
    {
      "name": "issuerUrl",
      "baseName": "issuerUrl",
      "type": "string",
      "format": ""
    },
    {
      "name": "certificate",
      "baseName": "certificate",
      "type": "string",
      "format": ""
    },
    {
      "name": "groupToRoleMappingList",
      "baseName": "groupToRoleMappingList",
      "type": "Array<GroupToRoleMapping>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return CreateSamlIdentityProviderConfigInput.attributeTypeMap;
  }

  public constructor() {
  }
}

