/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class GenericSnapshotData {
  /**
  * The snapshot ID of the snapshot.
  */
  'snapshotId'?: string;
  /**
  * The date the snapshot creation was initiated
  */
  'startedAt'?: Date;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "snapshotId",
      "baseName": "snapshotId",
      "type": "string",
      "format": ""
    },
    {
      "name": "startedAt",
      "baseName": "startedAt",
      "type": "Date",
      "format": "date-time"
    }  ];

  static getAttributeTypeMap() {
    return GenericSnapshotData.attributeTypeMap;
  }

  public constructor() {
  }
}

