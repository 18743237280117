import {
  DiscoveryStatusApi,
  DiscoveryApi,
  type Configuration,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

export const discovery = (
  httpClient: HttpClient,
  config: Configuration,
  projectId: string
) => {
  const discoveryApi = new DiscoveryApi(config);
  const discoveryStatusApi = new DiscoveryStatusApi(config);

  return {
    invoke: (cloudAccountId: string) =>
      discoveryApi.invokeDiscover(projectId, cloudAccountId, {}),
    status: (cloudAccountId: string) =>
      discoveryStatusApi.discoveryStatus(projectId, cloudAccountId),
  };
};
