/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { Environment } from '../models/Environment';

export class EnvironmentFilters {
  /**
  * Matches if any value in this list equals `environment`.
  */
  '_in'?: Array<Environment>;
  /**
  * Matches if no value in this list equals `environment`.
  */
  'notIn'?: Array<Environment>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "_in",
      "baseName": "In",
      "type": "Array<Environment>",
      "format": ""
    },
    {
      "name": "notIn",
      "baseName": "NotIn",
      "type": "Array<Environment>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return EnvironmentFilters.attributeTypeMap;
  }

  public constructor() {
  }
}

