/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { Snapshot } from '../models/Snapshot';

export class ListInventorySnapshotsResponse {
  /**
  * List of Eon snapshots.
  */
  'snapshots': Array<Snapshot>;
  /**
  * Cursor that points to the first record of the next page of results. Pass this value in the next request. 
  */
  'nextToken'?: string;
  /**
  * The total number of Eon snapshots.
  */
  'totalCount'?: number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "snapshots",
      "baseName": "snapshots",
      "type": "Array<Snapshot>",
      "format": ""
    },
    {
      "name": "nextToken",
      "baseName": "nextToken",
      "type": "string",
      "format": ""
    },
    {
      "name": "totalCount",
      "baseName": "totalCount",
      "type": "number",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListInventorySnapshotsResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

