import type {
  AzureOnboardingRequest,
  AzureSubscription,
  Configuration,
} from '@repo/api-gw-sdk';
import {
  AzureOnboardingRequestAccountTypeEnum,
  GetAzureConsentUrlApi,
  GetAzureOnboardingStatusApi,
  GetAzureSubscriptionsApi,
  OnboardAzureSubscriptionsApi,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

export const azure = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const consentUrlApi = new GetAzureConsentUrlApi(config);
  const listSubscriptionsApi = new GetAzureSubscriptionsApi(config);
  const onboardSubscriptionsApi = new OnboardAzureSubscriptionsApi(config);
  const onboardingStatusApi = new GetAzureOnboardingStatusApi(config);

  return {
    getConsentUrl: (requestId: string) =>
      consentUrlApi.getAzureConsentUrl(currentProjectId, requestId),
    listSubscriptions: (requestId: string) =>
      listSubscriptionsApi.getAzureSubscriptions(currentProjectId, requestId),
    onboardSubscriptions: (
      requestId: string,
      accountType: string,
      subscriptions: AzureSubscription[]
    ) => {
      let onboardingAccountType: AzureOnboardingRequestAccountTypeEnum;
      if (accountType === 'source') {
        onboardingAccountType = AzureOnboardingRequestAccountTypeEnum.Source;
      } else if (accountType === 'restore') {
        onboardingAccountType = AzureOnboardingRequestAccountTypeEnum.Restore;
      } else {
        throw new Error(`Invalid account type: ${accountType}`);
      }

      return onboardSubscriptionsApi.onboardAzureSubscriptions(
        currentProjectId,
        requestId,
        {
          accountType: onboardingAccountType,
          subscriptions,
        } as AzureOnboardingRequest
      );
    },
    getOnboardingStatus: (requestId: string) =>
      onboardingStatusApi.getAzureOnboardingStatus(currentProjectId, requestId),
  };
};
