/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class NotificationArgumentsBackupJobFailed {
  /**
  * The id of the backup job
  */
  'id'?: string;
  /**
  * The start time of the backup job
  */
  'startTime'?: Date;
  /**
  * The end time of the backup job
  */
  'endTime'?: Date;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "startTime",
      "baseName": "startTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "endTime",
      "baseName": "endTime",
      "type": "Date",
      "format": "date-time"
    }  ];

  static getAttributeTypeMap() {
    return NotificationArgumentsBackupJobFailed.attributeTypeMap;
  }

  public constructor() {
  }
}

