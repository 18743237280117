/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { RegisterInputBillingToken } from '../models/RegisterInputBillingToken';

export class RegisterInput {
  'billingToken'?: RegisterInputBillingToken;
  /**
  * The email of the user
  */
  'email'?: string;
  /**
  * The name of the user
  */
  'name'?: string;
  /**
  * The domain of the account
  */
  'domain'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "billingToken",
      "baseName": "billingToken",
      "type": "RegisterInputBillingToken",
      "format": ""
    },
    {
      "name": "email",
      "baseName": "email",
      "type": "string",
      "format": ""
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "domain",
      "baseName": "domain",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RegisterInput.attributeTypeMap;
  }

  public constructor() {
  }
}

