/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* The resource\'s environment: production, internal production, or staging. 
*/
export enum Environment {
  Prod = 'PROD',
  ProdInternal = 'PROD_INTERNAL',
  Stage = 'STAGE',
  UnspecifiedEnvironment = 'UNSPECIFIED_ENVIRONMENT'
}
