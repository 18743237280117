/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SortOrder } from '../models/SortOrder';

/**
* A sorting criterion.
*/
export class SortLogsBy {
  /**
  * Field to sort by.
  */
  'field': SortLogsByFieldEnum;
  'order': SortOrder;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "field",
      "baseName": "field",
      "type": "SortLogsByFieldEnum",
      "format": ""
    },
    {
      "name": "order",
      "baseName": "order",
      "type": "SortOrder",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return SortLogsBy.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum SortLogsByFieldEnum {
  Action = 'action',
  OperationName = 'operationName',
  RequestTime = 'requestTime',
  StatusCode = 'statusCode',
  InitiatorId = 'initiatorId'
}

