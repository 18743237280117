import type {
  Configuration,
  CreateProjectInput,
  Project,
} from '@repo/api-gw-sdk';
import {
  ProjectsApi,
  UpdateProjectsApi,
  CreateProjectApi,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/projects';

export const projects = (httpClient: HttpClient, config: Configuration) => {
  const projectsApi = new ProjectsApi(config);
  const createProjectApi = new CreateProjectApi(config);
  const updateProjectsApi = new UpdateProjectsApi(config);

  return {
    list: () => httpClient.execute([baseUrl], () => projectsApi.listProjects()),
    create: (project: CreateProjectInput) =>
      createProjectApi.createProject(project),
    update: (project: Project) =>
      updateProjectsApi.updateProject(project.id, project),
  };
};
