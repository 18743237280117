/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { BackupPolicy } from '../models/BackupPolicy';
import { Pagination } from '../models/Pagination';

export class ListBackupPolicyResponse {
  'backupPolicies': Array<BackupPolicy>;
  'pagination': Pagination;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "backupPolicies",
      "baseName": "backupPolicies",
      "type": "Array<BackupPolicy>",
      "format": ""
    },
    {
      "name": "pagination",
      "baseName": "pagination",
      "type": "Pagination",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListBackupPolicyResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

