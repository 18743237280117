/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export enum JobType {
  Restore = 'restore',
  Backup = 'backup',
  Scan = 'scan'
}
