/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AzureMarketplaceWebhookInputSubscription } from '../models/AzureMarketplaceWebhookInputSubscription';

export class AzureMarketplaceWebhookInput {
  /**
  * Unique identifier for the operation.
  */
  'id'?: string;
  /**
  * Identifier for the activity triggered in Azure Marketplace.
  */
  'activityId'?: string;
  /**
  * Publisher ID for the SaaS offer.
  */
  'publisherId'?: string;
  /**
  * Offer ID for the SaaS subscription.
  */
  'offerId'?: string;
  /**
  * Plan ID requested for the subscription change.
  */
  'planId'?: string;
  /**
  * Quantity requested for the subscription.
  */
  'quantity'?: number;
  /**
  * Unique identifier of the subscription.
  */
  'subscriptionId'?: string;
  /**
  * Timestamp of when the event occurred.
  */
  'timeStamp'?: Date;
  /**
  * Action associated with the event. For this case, \"ChangePlan\".
  */
  'action'?: AzureMarketplaceWebhookInputActionEnum;
  /**
  * Status of the operation.
  */
  'status'?: AzureMarketplaceWebhookInputStatusEnum;
  /**
  * Source of the operation request.
  */
  'operationRequestSource'?: AzureMarketplaceWebhookInputOperationRequestSourceEnum;
  'subscription'?: AzureMarketplaceWebhookInputSubscription;
  /**
  * Purchase token for the subscription.
  */
  'purchaseToken'?: string | null;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": "uuid"
    },
    {
      "name": "activityId",
      "baseName": "activityId",
      "type": "string",
      "format": "uuid"
    },
    {
      "name": "publisherId",
      "baseName": "publisherId",
      "type": "string",
      "format": ""
    },
    {
      "name": "offerId",
      "baseName": "offerId",
      "type": "string",
      "format": ""
    },
    {
      "name": "planId",
      "baseName": "planId",
      "type": "string",
      "format": ""
    },
    {
      "name": "quantity",
      "baseName": "quantity",
      "type": "number",
      "format": ""
    },
    {
      "name": "subscriptionId",
      "baseName": "subscriptionId",
      "type": "string",
      "format": "uuid"
    },
    {
      "name": "timeStamp",
      "baseName": "timeStamp",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "action",
      "baseName": "action",
      "type": "AzureMarketplaceWebhookInputActionEnum",
      "format": ""
    },
    {
      "name": "status",
      "baseName": "status",
      "type": "AzureMarketplaceWebhookInputStatusEnum",
      "format": ""
    },
    {
      "name": "operationRequestSource",
      "baseName": "operationRequestSource",
      "type": "AzureMarketplaceWebhookInputOperationRequestSourceEnum",
      "format": ""
    },
    {
      "name": "subscription",
      "baseName": "subscription",
      "type": "AzureMarketplaceWebhookInputSubscription",
      "format": ""
    },
    {
      "name": "purchaseToken",
      "baseName": "purchaseToken",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AzureMarketplaceWebhookInput.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum AzureMarketplaceWebhookInputActionEnum {
  ChangePlan = 'ChangePlan',
  ChangeQuantity = 'ChangeQuantity',
  Renew = 'Renew',
  Suspend = 'Suspend',
  Unsubscribe = 'Unsubscribe',
  Reinstate = 'Reinstate'
}
export enum AzureMarketplaceWebhookInputStatusEnum {
  InProgress = 'InProgress',
  Succeeded = 'Succeeded',
  Failed = 'Failed'
}
export enum AzureMarketplaceWebhookInputOperationRequestSourceEnum {
  Azure = 'Azure',
  Publisher = 'Publisher'
}

