/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class BackedUpByResourceTypeDataInner {
  /**
  * The type of the resource
  */
  'name'?: string;
  /**
  * counter for resources backed up with EON
  */
  'eon'?: number;
  /**
  * counter for resources backed up with other cloud providers
  */
  'generalPurpose'?: number;
  /**
  * counter for resources that are not backed up
  */
  'notProtected'?: number;
  /**
  * counter for resources that are excluded from backup
  */
  'excluded'?: number;
  /**
  * counter for resources that are still in the initial classification phase
  */
  'initialClassification'?: number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "eon",
      "baseName": "eon",
      "type": "number",
      "format": ""
    },
    {
      "name": "generalPurpose",
      "baseName": "generalPurpose",
      "type": "number",
      "format": ""
    },
    {
      "name": "notProtected",
      "baseName": "notProtected",
      "type": "number",
      "format": ""
    },
    {
      "name": "excluded",
      "baseName": "excluded",
      "type": "number",
      "format": ""
    },
    {
      "name": "initialClassification",
      "baseName": "initialClassification",
      "type": "number",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return BackedUpByResourceTypeDataInner.attributeTypeMap;
  }

  public constructor() {
  }
}

