import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import type { Configuration } from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpInfo } from '../http/http';
import { ObjectSerializer } from '../models/ObjectSerializer';
import { ApiException } from './exception';
import { isCodeInRange } from '../util';
import type {SecurityAuthentication} from '../auth/auth';


import { ListDatabaseSnapshotsResponse } from '../models/ListDatabaseSnapshotsResponse';

/**
 * no description
 */
export class GetSnapshotsByServerApiRequestFactory extends BaseAPIRequestFactory {

  /**
   * Get all snapshots for a database server
   * @param serverId ID of the database server
   * @param start The start date for the query
   * @param end The end date for the query
   * @param projectId The project ID
   */
  public async getSnapshotsByServer(serverId: string, start: Date, end: Date, projectId: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'serverId' is not null or undefined
    if (serverId === null || serverId === undefined) {
      throw new RequiredError("GetSnapshotsByServerApi", "getSnapshotsByServer", "serverId");
    }


    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new RequiredError("GetSnapshotsByServerApi", "getSnapshotsByServer", "start");
    }


    // verify required parameter 'end' is not null or undefined
    if (end === null || end === undefined) {
      throw new RequiredError("GetSnapshotsByServerApi", "getSnapshotsByServer", "end");
    }


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("GetSnapshotsByServerApi", "getSnapshotsByServer", "projectId");
    }


    // Path Params
    const localVarPath = '/projects/{projectId}/db/{serverId}/snapshots'
      .replace('{' + 'serverId' + '}', encodeURIComponent(String(serverId)))
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.GET, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (start !== undefined) {
      requestContext.setQueryParam("start", ObjectSerializer.serialize(start, "Date", "date-time"));
    }

    // Query Params
    if (end !== undefined) {
      requestContext.setQueryParam("end", ObjectSerializer.serialize(end, "Date", "date-time"));
    }


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

}

export class GetSnapshotsByServerApiResponseProcessor {

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to getSnapshotsByServer
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async getSnapshotsByServerWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ListDatabaseSnapshotsResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ListDatabaseSnapshotsResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "ListDatabaseSnapshotsResponse", ""
      ) as ListDatabaseSnapshotsResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "No snapshots found", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ListDatabaseSnapshotsResponse = await response.body.json() as ListDatabaseSnapshotsResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

}
