/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class ListInstanceProfilesResponse {
  /**
  * The instance profiles list
  */
  'instanceProfileNames'?: Array<string>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "instanceProfileNames",
      "baseName": "instanceProfileNames",
      "type": "Array<string>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListInstanceProfilesResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

