/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class RestoreAvailabilityZones {
  /**
  * The region to restore
  */
  'region': string;
  /**
  * The available zones for the region
  */
  'availabilityZones': Array<string>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "region",
      "baseName": "region",
      "type": "string",
      "format": ""
    },
    {
      "name": "availabilityZones",
      "baseName": "availabilityZones",
      "type": "Array<string>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreAvailabilityZones.attributeTypeMap;
  }

  public constructor() {
  }
}

