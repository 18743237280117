/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class SsoIntegrationSettings {
  /**
  * The service provider entity ID
  */
  'spEntityId'?: string;
  /**
  * The SSO URL of the service provider
  */
  'ssoUrl'?: string;
  /**
  * The SLO URL of the service provider
  */
  'sloUrl'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "spEntityId",
      "baseName": "spEntityId",
      "type": "string",
      "format": ""
    },
    {
      "name": "ssoUrl",
      "baseName": "ssoUrl",
      "type": "string",
      "format": ""
    },
    {
      "name": "sloUrl",
      "baseName": "sloUrl",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return SsoIntegrationSettings.attributeTypeMap;
  }

  public constructor() {
  }
}

