import { createConfiguration } from '@repo/api-gw-sdk';
export const createConfig = (
  baseUrl: string,
  authToken: string | undefined,
  middlewares: NonNullable<
    // TODO why is this double pluratlized?
    NonNullable<Parameters<typeof createConfiguration>[0]>['middleware']
  > = []
) =>
  createConfiguration({
    baseUrl,
    ...(authToken
      ? {
          authMethods: {
            ApiKeyAuth: authToken,
          },
        }
      : {}),
    middleware: middlewares,
    timeout: 2 * 60_000, // 2 minutes default timeout, can be overridden per request
    retries: 5, // Retry idempotent request 5 times
  });
