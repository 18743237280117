import {
  GetDatabasesByServerApi,
  GetSnapshotsByServerApi,
  QueryDBResultsApi,
  QueryDBSnapshotApi,
  QueryDBStatusApi,
  type Configuration,
  retry,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/db';

export const db = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const snapshotsApi = new GetSnapshotsByServerApi(config);
  const getDbApi = new GetDatabasesByServerApi(config);
  const queryDBSnapshotApi = new QueryDBSnapshotApi(config);
  const queryDBStatusApi = new QueryDBStatusApi(config);
  const queryDBResultsApi = new QueryDBResultsApi(config);

  return {
    snapshots: (serverId: string, startDate: Date, endDate: Date) =>
      httpClient.execute(
        [baseUrl, serverId, startDate, endDate, currentProjectId],
        () =>
          snapshotsApi.getSnapshotsByServer(
            serverId,
            startDate,
            endDate,
            currentProjectId
          )
      ),
    objectTree: (serverId: string, snapshotId: string) => {
      return httpClient.execute(
        [baseUrl, serverId, snapshotId, currentProjectId],
        () => {
          if (!snapshotId) {
            return Promise.resolve(undefined);
          }
          return getDbApi.getDatabasesByServer(
            serverId,
            snapshotId,
            currentProjectId
          );
        }
      );
    },
    querySnapshot: (
      snapshotId: string,
      databaseName: string,
      query: string,
      targetAccountId?: string,
      bucketName?: string,
      bucketPrefix?: string,
      signal?: AbortSignal
    ) =>
      retry(
        () =>
          queryDBSnapshotApi.queryDBSnapshot(
            snapshotId,
            databaseName,
            query,
            currentProjectId,
            targetAccountId,
            bucketName,
            bucketPrefix,
            { signal }
          ),
        3 // Picked by Peleg as his lucky number
      ),
    queryStatus: (queryId: string) =>
      queryDBStatusApi.queryDBStatus(queryId, currentProjectId),
    queryResults: (queryId: string) =>
      queryDBResultsApi.queryDBResults(queryId, currentProjectId),
  };
};
