/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class App {
  /**
  * App display name. For a list of supported apps, see [Apps](/docs/user-guide/backing-up/resource-classifications#apps). 
  */
  'name': string;
  /**
  * List of paths where the app was found.
  */
  'paths'?: Array<string>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "paths",
      "baseName": "paths",
      "type": "Array<string>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return App.attributeTypeMap;
  }

  public constructor() {
  }
}

