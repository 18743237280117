/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SortOrder } from '../models/SortOrder';

/**
* A sorting criterion.
*/
export class SortNotificationBy {
  'field': SortNotificationByFieldEnum;
  'order': SortOrder;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "field",
      "baseName": "field",
      "type": "SortNotificationByFieldEnum",
      "format": ""
    },
    {
      "name": "order",
      "baseName": "order",
      "type": "SortOrder",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return SortNotificationBy.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum SortNotificationByFieldEnum {
  Id = 'id',
  Timestamp = 'timestamp',
  PolicyId = 'policyId',
  Type = 'type',
  Status = 'status'
}

