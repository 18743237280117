/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { DriftProtectionTimePoint } from '../models/DriftProtectionTimePoint';

export class DriftProtectionSummary {
  'data'?: Array<DriftProtectionTimePoint>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "data",
      "baseName": "data",
      "type": "Array<DriftProtectionTimePoint>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return DriftProtectionSummary.attributeTypeMap;
  }

  public constructor() {
  }
}

