/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class Preferences {
  /**
  * the preference key
  */
  'key'?: string;
  /**
  * the preference entity
  */
  'value'?: { [key: string]: any; };

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "key",
      "baseName": "key",
      "type": "string",
      "format": ""
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "{ [key: string]: any; }",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return Preferences.attributeTypeMap;
  }

  public constructor() {
  }
}

