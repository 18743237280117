/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { IDs } from '../models/IDs';

export class CreateUserInput {
  /**
  * The name of the user
  */
  'givenName': string;
  /**
  * The email of the user
  */
  'email': string;
  /**
  * The role to projects mappings of the viewer
  */
  'roleToProjectsMappings': { [key: string]: IDs; };
  /**
  * The date the user expires
  */
  'expiresAt'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "givenName",
      "baseName": "givenName",
      "type": "string",
      "format": ""
    },
    {
      "name": "email",
      "baseName": "email",
      "type": "string",
      "format": ""
    },
    {
      "name": "roleToProjectsMappings",
      "baseName": "roleToProjectsMappings",
      "type": "{ [key: string]: IDs; }",
      "format": ""
    },
    {
      "name": "expiresAt",
      "baseName": "expiresAt",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return CreateUserInput.attributeTypeMap;
  }

  public constructor() {
  }
}

