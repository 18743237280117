/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* Sort order. Use `ASC` for ascending order and `DESC` for descending order.
*/
export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}
