/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { DriftProtectionPolicy } from '../models/DriftProtectionPolicy';

export class DriftProtectionTimePoint {
  /**
  * The date of the timepoint
  */
  'date': Date;
  'policies'?: Array<DriftProtectionPolicy>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "date",
      "baseName": "date",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "policies",
      "baseName": "policies",
      "type": "Array<DriftProtectionPolicy>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return DriftProtectionTimePoint.attributeTypeMap;
  }

  public constructor() {
  }
}

