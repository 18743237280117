/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class ControlViolations {
  /**
  * Number of high-severity control violations.
  */
  'high'?: number;
  /**
  * Number of medium-severity control violations.
  */
  'medium'?: number;
  /**
  * Number of low-severity control violations.
  */
  'low'?: number;
  /**
  * Number of muted control violations.
  */
  'muted'?: number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "high",
      "baseName": "high",
      "type": "number",
      "format": ""
    },
    {
      "name": "medium",
      "baseName": "medium",
      "type": "number",
      "format": ""
    },
    {
      "name": "low",
      "baseName": "low",
      "type": "number",
      "format": ""
    },
    {
      "name": "muted",
      "baseName": "muted",
      "type": "number",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ControlViolations.attributeTypeMap;
  }

  public constructor() {
  }
}

