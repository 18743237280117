/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { S3Bucket } from '../models/S3Bucket';

export class S3Buckets {
  'buckets': Array<S3Bucket>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "buckets",
      "baseName": "buckets",
      "type": "Array<S3Bucket>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return S3Buckets.attributeTypeMap;
  }

  public constructor() {
  }
}

