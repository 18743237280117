/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class BackupVault {
  /**
  * The ID of the backup vault
  */
  'id': string;
  /**
  * The name of the backup vault
  */
  'name': string;
  /**
  * The name of the bucket
  */
  'bucketName'?: string;
  /**
  * The prefix of the bucket.
  */
  'bucketPrefix'?: string;
  /**
  * The cloud provider of the backup vault
  */
  'cloudProvider': string;
  /**
  * The account uuid associated with the backup vault
  */
  'cloudAccountId': string;
  /**
  * The region where the backup vault is located
  */
  'region': string;
  /**
  * Whether the backup vault is locked
  */
  'objectLock': boolean;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "bucketName",
      "baseName": "bucketName",
      "type": "string",
      "format": ""
    },
    {
      "name": "bucketPrefix",
      "baseName": "bucketPrefix",
      "type": "string",
      "format": ""
    },
    {
      "name": "cloudProvider",
      "baseName": "cloudProvider",
      "type": "string",
      "format": ""
    },
    {
      "name": "cloudAccountId",
      "baseName": "cloudAccountId",
      "type": "string",
      "format": "uuid"
    },
    {
      "name": "region",
      "baseName": "region",
      "type": "string",
      "format": ""
    },
    {
      "name": "objectLock",
      "baseName": "objectLock",
      "type": "boolean",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return BackupVault.attributeTypeMap;
  }

  public constructor() {
  }
}

