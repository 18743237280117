/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class BackupSchedule {
  /**
  * The ID of the schedule
  */
  'id': string;
  /**
  * The ID of the policy
  */
  'policyId': string;
  /**
  * The ID of the backup vault
  */
  'vaultId': string;
  /**
  * The backup windows cron expression
  */
  'windows': string;
  /**
  * The backup windows duration in minutes
  */
  'duration': number;
  /**
  * The backup retention in days
  */
  'backupRetention': number;
  /**
  * The archive retention in days
  */
  'archiveRetention'?: number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "policyId",
      "baseName": "policyId",
      "type": "string",
      "format": ""
    },
    {
      "name": "vaultId",
      "baseName": "vaultId",
      "type": "string",
      "format": ""
    },
    {
      "name": "windows",
      "baseName": "windows",
      "type": "string",
      "format": ""
    },
    {
      "name": "duration",
      "baseName": "duration",
      "type": "number",
      "format": ""
    },
    {
      "name": "backupRetention",
      "baseName": "backupRetention",
      "type": "number",
      "format": ""
    },
    {
      "name": "archiveRetention",
      "baseName": "archiveRetention",
      "type": "number",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return BackupSchedule.attributeTypeMap;
  }

  public constructor() {
  }
}

