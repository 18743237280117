/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class BackupJobResponse {
  /**
  * The ID of the snapshot to be created
  */
  'snapshotId': string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "snapshotId",
      "baseName": "snapshotId",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return BackupJobResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

