/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { NotificationPolicyConfigEmail } from '../models/NotificationPolicyConfigEmail';
import { NotificationPolicyConfigSlack } from '../models/NotificationPolicyConfigSlack';
import { NotificationPolicyConfigSns } from '../models/NotificationPolicyConfigSns';

export class NotificationPolicyConfig {
  'slack'?: NotificationPolicyConfigSlack;
  'email'?: NotificationPolicyConfigEmail;
  'sns'?: NotificationPolicyConfigSns;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "slack",
      "baseName": "slack",
      "type": "NotificationPolicyConfigSlack",
      "format": ""
    },
    {
      "name": "email",
      "baseName": "email",
      "type": "NotificationPolicyConfigEmail",
      "format": ""
    },
    {
      "name": "sns",
      "baseName": "sns",
      "type": "NotificationPolicyConfigSns",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return NotificationPolicyConfig.attributeTypeMap;
  }

  public constructor() {
  }
}

