/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* Action category of the request.
*/
export enum AuditLogAction {
  Trigger = 'TRIGGER',
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE'
}
