/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { ControlViolationsPerSeverity } from '../models/ControlViolationsPerSeverity';

/**
* The backup control violations
*/
export class BackupControlViolations {
  'high': ControlViolationsPerSeverity;
  'medium': ControlViolationsPerSeverity;
  'low': ControlViolationsPerSeverity;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "high",
      "baseName": "high",
      "type": "ControlViolationsPerSeverity",
      "format": ""
    },
    {
      "name": "medium",
      "baseName": "medium",
      "type": "ControlViolationsPerSeverity",
      "format": ""
    },
    {
      "name": "low",
      "baseName": "low",
      "type": "ControlViolationsPerSeverity",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return BackupControlViolations.attributeTypeMap;
  }

  public constructor() {
  }
}

