/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { ColumnMetadata } from '../models/ColumnMetadata';
import { DBResourceMetadata } from '../models/DBResourceMetadata';
import { DBSnapshotMetadata } from '../models/DBSnapshotMetadata';

export class UnifiedTableRecord {
  /**
  * The name of the table
  */
  'name'?: string;
  /**
  * The name of the schema
  */
  'schemaName'?: string;
  /**
  * The name of the db
  */
  'dbName'?: string;
  /**
  * Number of rows in the table
  */
  'rowCount'?: number;
  /**
  * The columns of the table
  */
  'columns'?: Array<ColumnMetadata>;
  /**
  * Id of the server of the table
  */
  'serverId'?: string;
  /**
  * reserved for elastic use
  */
  'recordType'?: string;
  'resource'?: DBResourceMetadata;
  'snapshot'?: DBSnapshotMetadata;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "schemaName",
      "baseName": "schemaName",
      "type": "string",
      "format": ""
    },
    {
      "name": "dbName",
      "baseName": "dbName",
      "type": "string",
      "format": ""
    },
    {
      "name": "rowCount",
      "baseName": "rowCount",
      "type": "number",
      "format": "int64"
    },
    {
      "name": "columns",
      "baseName": "columns",
      "type": "Array<ColumnMetadata>",
      "format": ""
    },
    {
      "name": "serverId",
      "baseName": "serverId",
      "type": "string",
      "format": ""
    },
    {
      "name": "recordType",
      "baseName": "record_type",
      "type": "string",
      "format": ""
    },
    {
      "name": "resource",
      "baseName": "resource",
      "type": "DBResourceMetadata",
      "format": ""
    },
    {
      "name": "snapshot",
      "baseName": "snapshot",
      "type": "DBSnapshotMetadata",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return UnifiedTableRecord.attributeTypeMap;
  }

  public constructor() {
  }
}

